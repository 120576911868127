import { RenderGif } from './Renderer.js'
export function moveLayer(layer){
    layer.setTruePosition([layer.truePos[0] + layer.velocity[0], layer.truePos[1] + layer.velocity[1]])
}

export function bounceMoveLayer(layer, margins, callback){
    var rows = layer.rows
    var cols = layer.cols
    if(layer instanceof RenderGif){
        rows = layer.layers[0].rows
        cols = layer.layers[0].cols
    }
    moveLayer(layer)
    var bounced = false
    if(layer.truePos[0] <= margins[0][0] || layer.truePos[0] + rows >= margins[0][1]){
        layer.setVelocity([layer.velocity[0] * -1, layer.velocity[1]])
        bounced = true
    }
    if(layer.truePos[1] <= margins[1][0] || layer.truePos[1] + cols >= margins[1][1]){
        layer.setVelocity([layer.velocity[0], layer.velocity[1] * -1])
        bounced = true
    }
    if(bounced && callback !== undefined){
        callback()
    }
    return bounced
}

export function circularMotion(layer, radius, period, theta){
    theta = (theta +  Math.PI / (360 * radius)) % (Math.PI * 2)
    var newX = Math.cos(theta) / period
    var newY = Math.sin(theta) / period
    layer.setVelocity([newX, newY])
    moveLayer(layer)
    return theta
}