import { PNG } from 'pngjs/browser';

import figlet from 'figlet';
import base64fonts from '../fonts/b64.json'

// fonts
import bloody from "figlet/importable-fonts/Bloody.js";
import georgia11 from "figlet/importable-fonts/Georgia11.js";
import mini from "figlet/importable-fonts/Mini.js";
import roman from "figlet/importable-fonts/Roman.js";
import whimsy from "figlet/importable-fonts/Whimsy.js";
import fourmax from "figlet/importable-fonts/4Max.js";
import ansiregular from "figlet/importable-fonts/ANSI Regular.js";
import small from "figlet/importable-fonts/Small.js"
import crazy from "figlet/importable-fonts/Crazy.js"
import dancing from "figlet/importable-fonts/Dancing Font.js"
import doh from "figlet/importable-fonts/Doh.js"
import nipples from "figlet/importable-fonts/Nipples.js"

figlet.parseFont("Bloody", bloody);
figlet.parseFont("Georgia11", georgia11);
figlet.parseFont("Mini", mini);
figlet.parseFont("Roman", roman);
figlet.parseFont("Whimsy", whimsy);
figlet.parseFont("4Max", fourmax);
figlet.parseFont("ANSI Regular", ansiregular);
figlet.parseFont("Small", small)
figlet.parseFont("Crazy", crazy) 
figlet.parseFont("Dancing", dancing)
figlet.parseFont("Doh", doh)
figlet.parseFont("Nipples", nipples)


const cursorsJson = require('../cursors.json')
const canvasStack = []

const TRANSPARENT_COLOR = 'rgb(0, 0, 0, 0)'

export function resolveClick(x, y){
  for(var i = canvasStack.length - 1; i >= 0; i--){
    if(canvasStack[i].resolveClick(x, y)){
      break;
    }
  }
}

export function resolveHover(x, y){
  for(var i = canvasStack.length - 1; i >= 0; i--){
    if(canvasStack[i].resolveHover(x, y)){
      break;
    }
  }
}

export function shift(x, y){
  for(var i = canvasStack.length - 1; i >= 0; i--){
    if(canvasStack[i].noShift){
      continue
    }
    //Shift causes canvas to exceed shift bounds
    if(canvasStack[i].shiftOffset[0] + y < canvasStack[i].shiftBounds[0][0] || canvasStack[i].shiftOffset[0] + y > canvasStack[i].shiftBounds[0][1]){
      continue
    }
    if(canvasStack[i].shiftOffset[1] + x < canvasStack[i].shiftBounds[1][0] || canvasStack[i].shiftOffset[1] + x > canvasStack[i].shiftBounds[1][1]){
      continue
    }
    canvasStack[i].shiftOffset[0] += y
    canvasStack[i].shiftOffset[1] += x
    for(var j = 0; j < canvasStack[i].layers.length; j++){
      const layer = canvasStack[i].layers[j]
      if(layer.noShift){
        continue
      }
      layer.shift(x * layer.shiftFactor, y * layer.shiftFactor)
    }
  }
}

export class RenderCanvas{
  constructor(rows, cols, fontSize, refreshDelay, bgColor){
    //Font size in pixels
    this.fontSize = fontSize
    this.bgColor = bgColor === undefined ? 'rgb(0, 0, 0)' : bgColor
    this.lineWidth = 3
    this.resolutionFactor = [1.51, 1.72]
    switch(fontSize){
      case 5:
        this.lineWidth = 1.6
        break
      case 8:
        this.lineWidth = 3
        this.resolutionFactor = [1.59, 1.8]
        break
      case 9:
        this.lineWidth = 2
        this.resolutionFactor = [1.52, 1.8]
        break
    }
    
    this.rows = rows
    this.cols = cols
    this.layers = []
    this.offset = [0,0]
    this.hidden = false
    this.noShift = false
    this.refreshDelay = refreshDelay
  
    this.shiftBounds = [[-400, 400], [0, 0]]
    this.shiftOffset = [0, 0]

    this.running = false
    this.prerender = () => {}
    this.renderInterval = undefined
    this.baseLayer = undefined
    this.lastRender = {
      colors: [],
      text: []
    }
    this.chromaticAberration = {
      enabled: false,
      phase: 1,
      intensity: 5
    }
    this.offscreenCanvas = new OffscreenCanvas(window.innerWidth, window.innerHeight)
    this.gfxCanvas = this.initGfxCanvas()
    this.ready = false
    this.minimumRenderLayer = 0
    canvasStack.push(this)
  }
  getLayersByName(name){
    const arr = []
    for(var i = this.layers.length - 1; i >= 0; i--){
      if(this.layers[i].name === name){
        arr.push(this.layers[i])
      }
    }
    return arr
  }
  triggerClickByName(name){
    for(var i = this.layers.length - 1; i > 0; i--){
      if(this.layers[i].isOffScreen([0, this.rows], [0, this.cols]) || this.layers[i].hidden || this.layers[i].name !== name)
        continue
      this.layers[i].triggerClickEvent()
    }
  }
  initGfxCanvas(){
    const docCanvas = document.getElementById("canvas")
    docCanvas.width = window.innerWidth
    docCanvas.height = window.innerHeight
    const octx = this.offscreenCanvas.getContext("2d")
    const ctx = docCanvas.getContext("2d", {alpha: false})
    ctx.imageSmoothingEnabled = false
    // ctx.font = "" + this.fontSize + "px Fixedsys"
    // docCanvas.getContext("2d").fillText()
    return docCanvas
  }
  renderBaseLayer(){
    const ctx = this.getOffscreenCtx()
    this.configContextForDrawing(ctx)
    ctx.fillStyle = this.bgColor
    const fontStr = "" + this.fontSize + "px Fixedsys"
    var font = new FontFace("Fixedsys", base64ToArrayBuffer(base64fonts.fixedsys))
    let onReady = () => {
      this.ready = true
        ctx.fillRect(0, 0, this.gfxCanvas.width, this.gfxCanvas.height)
        ctx.font = fontStr
        for(var i = 0; i < this.rows; i++){
          for(var j = 0; j < this.cols; j++){
            this.drawText(ctx, this.baseLayer.text[i][j], this.baseLayer.colors[i][j], i, j)
          }
        }
    }
    if(document.fonts.has(font) && document.fonts.check(fontStr)){
      onReady()
    }
    else{
      font.load().then(() => {
        document.fonts.add(font)
        document.fonts.load(fontStr).then(() => {
          onReady()
        })
      })
    }
  }
  getCanvasContext(){
    return this.gfxCanvas.getContext("2d", {alpha: false})
  }
  getOffscreenCtx(){
    return this.offscreenCanvas.getContext("2d")
  }
  nightMode(){
    this.colorBackground('rgb(0, 0, 0)')
  }
  dayMode(){
    this.colorBackground('rgb(255, 255, 255)')
    this.baseLayer.recolor(['rgb(0, 0, 0)', 'rgb(255, 255, 255)'])
    this.renderBaseLayer()
  }
  push(layer){
    if(this.baseLayer === undefined){
      this.baseLayer = layer
      // this.getCanvasContext().font = "" + this.fontSize + "px Fixedsys"
      // Causes interesting blackout effect 
      // setTimeout(() => {
        this.renderBaseLayer()
      // }, 1000)
      this.lastRender.colors = clone2dArray(layer.colors)
      this.lastRender.text = clone2dArray(layer.text)
    }
    else{
      this.ready = true
    }
    this.layers.push(layer)
  }
  insert(index, layer){
    this.layers.splice(index, 0, layer)
  }
  hide(){
    this.hidden = true
  }
  show(){
    this.hidden = false
  }
  resolveClick(x, y){
    const row = Math.round(y / (this.fontSize / this.resolutionFactor[0]))
    const col = Math.round(x / (this.fontSize / this.resolutionFactor[1]))
    if(row >= this.offset[0] && row < this.rows + this.offset[0] && col >= this.offset[1] && col < this.cols + this.offset[1]){
      for(var i = this.layers.length - 1; i >= 0; i--){
        this.layers[i].focused = false
      }
      for(var i = this.layers.length - 1; i >= 0; i--){
        var layer = this.layers[i]
        if(layer.hidden){
          continue
        }
        const layerRelativeRow = row - this.offset[0] - layer.offset[0]
        const layerRelativeCol = col - this.offset[1] - layer.offset[1]
        if(layer instanceof RenderGif){
          layer = layer.layers[layer.currentFrame]
        }
        if(layerRelativeRow < 0 || layerRelativeRow >= layer.rows || layerRelativeCol < 0 || layerRelativeCol >= layer.cols){
          continue;
        }
        if(!layer.fullyClickable && isBlankText(layer.text[layerRelativeRow][layerRelativeCol]) && isTransparent(layer.colors[layerRelativeRow][layerRelativeCol])){
          continue;
        }
        layer.clickEvent(row - this.offset[0] - layer.offset[0], col - this.offset[1] - layer.offset[1])
        layer.focused = true
        break;
      }
      return true
    }
    return false
  }
  resolveHover(x, y){
    const row = Math.round(y / (this.fontSize / this.resolutionFactor[0]))
    const col = Math.round(x / (this.fontSize / this.resolutionFactor[1]))
    if(row >= this.offset[0] && row < this.rows + this.offset[0] && col >= this.offset[1] && col < this.cols + this.offset[1]){
      for(var i = this.layers.length - 1; i >= 0; i--){
        var layer = this.layers[i]
        if(layer.hidden){
          continue
        }
        const layerRelativeRow = row - this.offset[0] - layer.offset[0]
        const layerRelativeCol = col - this.offset[1] - layer.offset[1]
        if(layer instanceof RenderGif){
          layer = layer.layers[layer.currentFrame]
        }
        if(layerRelativeRow < 0 || layerRelativeRow >= layer.rows || layerRelativeCol < 0 || layerRelativeCol >= layer.cols){
          if(layer.hovering){
            layer.unhoverEvent()
            layer.hovering = false
          }
          continue;
        }
        if(!layer.fullyClickable && isBlankText(layer.text[layerRelativeRow][layerRelativeCol]) && isTransparent(layer.colors[layerRelativeRow][layerRelativeCol])){
          continue;
        }
        layer.hoverEvent(row - this.offset[0] - layer.offset[0], col - this.offset[1] - layer.offset[1])
        layer.hovering = true
        break;
      }
      return true
    }
    return false
  }
  colorBackground(color){
    setBackgroundColor(color);
  }
  clearText(ctx, text, row, col){
    ctx.fillText(text, col * Math.round(this.fontSize/18 * 10) + 0.5, row * Math.round(this.fontSize/18 * 12) + 0.5)
    ctx.strokeText(text, col * Math.round(this.fontSize/18 * 10) + 0.5, row * Math.round(this.fontSize/18 * 12) + 0.5)
  }
  drawText(ctx, text, color, row, col){
    //ctx.shadowColor = getLighterColorFromRGB(color)
    ctx.fillStyle = color
    ctx.fillText(text, col * Math.round(this.fontSize/18 * 10) + 0.5, row * Math.round(this.fontSize/18 * 12) + 0.5)
    if(this.lastRender.colors[row])
      this.lastRender.colors[row][col] = color
    if(this.lastRender.text[row])
      this.lastRender.text[row][col] = text
  }
  configContextForClearing(ctx){
    ctx.fillStyle = this.bgColor
    ctx.strokeStyle = this.bgColor
    ctx.lineWidth = this.lineWidth
    // ctx.shadowColor = "rgb(0, 0, 0)"
  }
  configContextForDrawing(ctx){
    ctx.textRendering = "geometricPrecision"
    ctx.imageSmoothingEnabled = false
    // ctx.shadowOffsetX = 2
    // ctx.shadowOffsetY = 2
    // ctx.shadowBlur = 2
    ctx.fontKerning = "none"
  }
  setRefreshDelay(delay){
    if(this.refreshDelay === delay){
      return
    }
    this.stop()
    this.refreshDelay = delay
    this.start()
  }
  start(){
    if(this.running){
      this.stop()
      return
    }
    this.running = true
    // Set up a worker thread to render Canvas B
    // const worker = new Worker("../scripts/Worker.js");

    // Use the OffscreenCanvas API and send to the worker thread
    // const canvasWorker = this.gfxCanvas.transferControlToOffscreen();
    // worker.postMessage([canvasWorker, this]);
    // this.renderInterval = setInterval(() => {
    //   try{
    //     this.prerender()
    //     this.render()
    //   }
    //   catch(e){
    //     console.log(e)
    //   }
    // }, this.refreshDelay);

    let frame = () => {
      this.prerender()
      this.render()
      if(this.running)
        window.requestAnimationFrame(frame)
    }
    window.requestAnimationFrame(frame)
  }
  stop(){
    this.running = false
    // if(this.renderInterval){
    //   clearInterval(this.renderInterval)
    // }
  }
  enableChromaticAberration(){
    this.chromaticAberration.enabled = true
  }
  setChromaticAberrationPhase(phase){
    this.chromaticAberration.phase = phase
  }
  setChromaticAberrationIntensity(intensity){
    this.chromaticAberration.intensity = intensity
  }
  render(){
    if(!this.ready){
      return
    }
    var diff = this.computeRenderDiff()
    if(this.minimumRenderLayer === -1){
      setTimeout(() => {
        this.minimumRenderLayer = 0
        //this.renderBaseLayer()
      }, 200)
    }
    //console.log(diff.size)
    // if(diff.size > 4000){
    //   this.setRefreshDelay(24)
    // }
    // else{
    //   this.setRefreshDelay(32)
    // }
    //const offscreen = new OffscreenCanvas(this.gfxCanvas.width, this.gfxCanvas.height) //this.gfxCanvas.transferControlToOffscreen()
    // const ctx = this.getCanvasContext()
    const ctx = this.getOffscreenCtx()
    //ctx.font = "18px Fixedsys"
    this.configContextForClearing(ctx)
    diff.forEach((value, key) => {
      const row = parseInt(key.split(",")[0])
      if(row < this.offset[0] || row >= this.offset[0] + this.rows){
        return
      }
      const col = parseInt(key.split(",")[1])
      if(col < this.offset[1] || col >= this.offset[1] + this.cols){
        return
      }
      var text = value.split('`')[0]
      if(text !== this.lastRender.text[row][col]){
        this.clearText(ctx, this.lastRender.text[row][col], row, col)
      }
    })
    //this.configContextForDrawing(ctx)
    diff.forEach((value, key) => {
      const row = parseInt(key.split(",")[0])
      if(row < this.offset[0] || row >= this.offset[0] + this.rows){
        return
      }
      const col = parseInt(key.split(",")[1])
      if(col < this.offset[1] || col >= this.offset[1] + this.cols){
        return
      }
      var text = value.split('`')[0]
      var color = value.split('`')[1]
      if(text === this.lastRender.text[row][col] && color === this.lastRender.colors[row][col]){
        // console.log("same as last render at: " + key)
      }
      if(color === '' || color === undefined){
        color = this.lastRender.colors[row][col]
      }
      else if(isTransparent(color)){
        color = this.baseLayer.colors[row][col]
      }
      if(text === undefined){
        text = this.lastRender.text[row][col]
      }
      else if(isBlankText(text)){
        text = this.baseLayer.text[row][col]
      }
      this.drawText(ctx, text, color, row, col)
    })
    if(this.chromaticAberration.enabled){
      var imageData = ctx.getImageData(0, 0, this.gfxCanvas.width, this.gfxCanvas.height)
      var data = imageData.data;
      for (var i = this.chromaticAberration.phase % 4; i < data.length; i += 4) {
        data[i] = data[i + 4 * this.chromaticAberration.intensity];
      }
      ctx.putImageData(imageData, 0, 0);
    }
    const bitmap = this.offscreenCanvas.transferToImageBitmap()
    this.getCanvasContext().drawImage(bitmap, 0, 0)
    bitmap.close()
  }
  computeRenderDiff(){
    const diffMap = new Map()
    const foundColorMap = new Map()
    const foundTextMap = new Map()
    for(var k = this.layers.length - 1; k > this.minimumRenderLayer; k--){
      var layer = this.layers[k]
      if(layer === undefined){
        continue
      }
      if(layer instanceof ImageLayer){
        continue
      }
      if(layer.hidden && layer.hiding){
        if(layer.destroyed){
          const idx = this.layers.indexOf(layer)
          this.layers.splice(idx, 1)
          layer = null
        }
        continue
      }
      layer.prerender()
      layer.diff(diffMap, foundColorMap, foundTextMap, [[this.offset[0], this.offset[0] + this.rows], [this.offset[1], this.offset[1] + this.cols]])
    }
    return diffMap
  }
}
export class RenderGif{
  constructor(framesPerUpdate){
    this.framesPerUpdate = framesPerUpdate
    this.framesUntilUpdate = framesPerUpdate
    
    this.name = ""
    this.layers = []
    this.currentFrame = 0
    this.lastFrame = -1
    this.offset = [0,0]
    this.lastOffset = [0,0]
    this.truePos = []
    this.velocity = []
    this.paused = false;
    this.hidden = false
    this.hiding = false
    this.focused = false
    this.frameBreakpoints = []
    this.breakpointCallbacks = []
    this.shiftFactor = 1
    this.reversed = false
    this.computedDiff = undefined
    this.computeDiffInPost = false
    this.fullyClickable = false
    this.absoluteRenderBounds = []

    this.noShift = false
    this.shift = (x, y) => {
      this.setTruePosition([this.truePos[0] + y, this.truePos[1] + x])
    }
    this.clickEvent = (row, col) => {return false}
    this.hoverEvent = (row, col) => {return false}
    this.unhoverEvent = () => {return false}
    this.hovering = false
    this.prerender = () => {}
    this.destroyed = false
  }
  destroy(){
    this.destroyed = true
    this.hide()
    this.pause()
  }
  setPrerender(event){
    this.prerender = event
  }
  triggerClickEvent(){
    this.clickEvent(0,0)
  }
  triggerHoverEvent(){
    this.hoverEvent(0,0)
  }
  setComputedDiff(compute){
    this.computedDiff = compute
  }
  diff(diffMap, foundColorMap, foundTextMap, bounds){
    if(this.computedDiff !== undefined && !this.computeDiffInPost){
      this.computedDiff(diffMap)
      return
    }
    if(this.lastFrame === -1){
      this.layers[this.currentFrame].diff(diffMap, foundColorMap, foundTextMap, bounds)
      this.lastFrame = this.currentFrame
      this.lastOffset[0] = this.offset[0]
      this.lastOffset[1] = this.offset[1]
      return
    }
    const toVisit = new Set()
    const current = this.layers[this.currentFrame]
    const offsetDiff = [this.offset[0] - this.lastOffset[0], this.offset[1] - this.lastOffset[1]]
    const last = this.layers[this.lastFrame]
    const rows = Math.max(current.rows, last.rows)
    const cols = Math.max(current.cols, last.cols)

    for(var i = this.lastOffset[0]; i < this.lastOffset[0] + rows; i++){
      if(this.absoluteRenderBounds.length !== 0){
        if(i < this.absoluteRenderBounds[0][0] && i + offsetDiff[0] < this.absoluteRenderBounds[0][0]){
          continue
        }
        if(i >= this.absoluteRenderBounds[0][1] && i + offsetDiff[0] >= this.absoluteRenderBounds[0][1]){
          break
        }
      }
      if(i < bounds[0][0] && i + offsetDiff[0] < bounds[0][0]){
        continue
      }
      if(i >= bounds[0][1] && i + offsetDiff[0] >= bounds[0][1]){
        break
      }
      for(var j = this.lastOffset[1]; j < this.lastOffset[1] +cols; j++){
        if(this.absoluteRenderBounds.length !== 0){
          if(j < this.absoluteRenderBounds[1][0] && j + offsetDiff[1] < this.absoluteRenderBounds[1][0]){
            continue
          }
          if(j >= this.absoluteRenderBounds[1][1] && j + offsetDiff[1] >= this.absoluteRenderBounds[1][1]){
            break
          }
        }
        if(j < bounds[1][0] && j + offsetDiff[1] < bounds[1][0]){
          continue
        }
        if(j >= bounds[1][1] && j + offsetDiff[1] >= bounds[1][1]){
          break
        }
        if(!(i < bounds[0][0] || i >= bounds[0][1] || j < bounds[1][0] || j >= bounds[1][1])){
          if(this.absoluteRenderBounds.length !== 0){
            if(!(i < this.absoluteRenderBounds[0][0] || i >= this.absoluteRenderBounds[0][1] 
                || j < this.absoluteRenderBounds[1][0] || j >= this.absoluteRenderBounds[1][1])){
                  toVisit.add(i + ',' + j)
            }
          }
          else{
            toVisit.add(i + ',' + j)
          }
        }
        if(!(i + offsetDiff[0] < bounds[0][0] || i >= bounds[0][1] || j + offsetDiff[1] < bounds[1][0] || j >= bounds[1][1])){
          if(this.absoluteRenderBounds.length !== 0){
            if(!(i + offsetDiff[0] < this.absoluteRenderBounds[0][0] || i >= this.absoluteRenderBounds[0][1] 
                || j + offsetDiff[1] < this.absoluteRenderBounds[1][0] || j >= this.absoluteRenderBounds[1][1])){
                  toVisit.add((i + offsetDiff[0]) + ',' + (j + offsetDiff[1]))
            }
          }
          else{
            toVisit.add((i + offsetDiff[0]) + ',' + (j + offsetDiff[1]))
          }
        }
      }
    }
    toVisit.forEach((key)=> {
      const row = parseInt(key.split(',')[0])
      const col = parseInt(key.split(',')[1])
      const lastCoord = last.toRelativeCoordinate(this.lastOffset, row, col)
      const currCoord = current.toRelativeCoordinate(this.offset, row, col)
      var diffTextCurr = diffMap.has(key) ? diffMap.get(key).split("`")[0] : undefined
      var diffColorCurr = diffMap.has(key) ? diffMap.get(key).split("`")[1] : ''
      const diffColorNew = this.diffColor(diffColorCurr, last, current, foundColorMap, key, row, col, currCoord, lastCoord)
      const diffTextNew = this.diffText(diffTextCurr, last, current, foundTextMap, key, row, col, currCoord, lastCoord)
      diffColorCurr = diffColorNew ? diffColorNew : diffColorCurr
      diffTextCurr = diffTextNew ? diffTextNew : diffTextCurr
      if(!diffColorCurr && !diffTextCurr){
        return
      }
      diffMap.set(key, diffTextCurr + '`' + diffColorCurr)
    })
    if(this.computedDiff !== undefined && this.computeDiffInPost){
      this.computedDiff(diffMap)
    }
    this.hidden = this.hiding
    this.lastFrame = this.currentFrame
    this.lastOffset[0] = this.offset[0]
    this.lastOffset[1] = this.offset[1]
  }
  diffColor(diff, last, current, foundMap, key, row, col, currCoord, lastCoord){
    if(foundMap.has(key)){
      return
    }
    if(diff !== '' && !isTransparent(diff)){
      return
    }
    //(row, col) not in either layer
    if(!last.includesCoordinate(this.lastOffset, row, col) && !current.includesCoordinate(this.offset, row, col)){
      //do nothing
      return
    }
    //(row, col) in last layer but not in current
    if(last.includesCoordinate(this.lastOffset, row, col) && !current.includesCoordinate(this.offset, row, col)){
      //Color is removed between layers and therefore add transparent to diffMap
      if(last.colors[lastCoord[0]] && isTransparent(last.colors[lastCoord[0]][lastCoord[1]])){
        return
      }
      return TRANSPARENT_COLOR
    }
    if(!current.colors[currCoord[0]]){
      return
    }
    var color = current.colors[currCoord[0]][currCoord[1]]
    if(!this.hidden && this.hiding){
      color = TRANSPARENT_COLOR
    }
    //(row, col) in current layer but not in last
    if(current.includesCoordinate(this.offset, row, col) && !last.includesCoordinate(this.lastOffset, row, col)){
      //If current color is transparent, it need not be rendered and therefore 
      //this situation is equivalent to the color not being there due to the last layer not including it, so no diff

      //otherwise add this color to diff
      if(!isTransparent(color)){
        //console.log(key)
        return color
      }
      return
    }
    //(row, col) in both layers
    //Color is same between layers, return
    if(!isTransparent(color)){
      foundMap.set(key, color)
    }
    if(!(this.hidden && !this.hiding) && last.colors[lastCoord[0]] && last.colors[lastCoord[0]][lastCoord[1]] === color){
      if(diff !== '' && isTransparent(diff)){
        return color
      }
      return
    }
    //Otherwise add current color to diffMap
    return color
  }
  diffText(diff, last, current, foundMap, key, row, col, currCoord, lastCoord){
    if(foundMap.has(key)){
      return
    }
    if(diff !== '' && !isBlankText(diff)){
      return
    }
    //(row, col) in last layer but not in current
    if(last.includesCoordinate(this.lastOffset, row, col) && !current.includesCoordinate(this.offset, row, col)){
      //Color is removed between layers and therefore add transparent to diffMap
      if(last.text[lastCoord[0]] && isBlankText(last.text[lastCoord[0]][lastCoord[1]])){
        return
      }
      return ''
    }
    if(!current.text[currCoord[0]]){
      return
    }
    var text = current.text[currCoord[0]][currCoord[1]]
    if(!this.hidden && this.hiding){
      text = ''
    }
    //(row, col) in current layer but not in last
    if(current.includesCoordinate(this.offset, row, col) && !last.includesCoordinate(this.lastOffset, row, col)){
      //If current color is transparent, it need not be rendered and therefore 
      //this situation is equivalent to the color not being there due to the last layer not including it, so no diff

      //otherwise add this color to diff
      if(!isBlankText(text)){
        //console.log(key)
        return text
      }
      return
    }
    //(row, col) in both layers
    //Color is same between layers, return
    if(!isBlankText(text)){
      foundMap.set(key, text)
    }
    if(!(this.hidden && !this.hiding) && last.text[lastCoord[0]] && last.text[lastCoord[0]][lastCoord[1]] === text){
      if(diff !== '' && isBlankText(diff)){
        return text
      }
      return
    }
    //Otherwise add current color to diffMap
    return text
  }
  setNoShift(noShift){
    this.noShift = noShift
    this.layers.forEach((layer) => {
      layer.setNoShift(noShift)
    })
  }
  setTruePosition(pos){
    this.truePos = pos
    this.offset[0] = Math.round(this.truePos[0])
    this.offset[1] = Math.round(this.truePos[1])
    this.layers.forEach((layer) => {
      layer.setTruePosition(pos)
    })
  }
  centerX(cols){
    this.setTruePosition([this.truePos[0], ((cols - this.layers[this.currentFrame].cols) / 2)])
    return this
  }
  centerY(rows){
    this.setTruePosition([Math.floor((rows - this.layers[this.currentFrame].rows) / 2), this.truePos[1]])
    return this
  }
  setClickEvent(event){
    this.clickEvent = event
    this.layers.forEach((layer)=>{
      layer.setClickEvent(event)
    })
  }
  setHoverEvent(event){
    this.hoverEvent = event
    this.layers.forEach((layer) => {
      layer.setHoverEvent(event)
    })
  }
  setUnhoverEvent(event){
    this.unhoverEvent = event
    this.layers.forEach((layer) => {
      layer.setUnhoverEvent(event)
    })
  }
  setVelocity(vel){
    this.velocity = vel
  }
  pushSheet(sheet, esc, color, pos){
    this.setTruePosition(pos)
    sheet.forEach((frame) => {
      var formatted = frame.split("\n")
      var layer = new RenderLayer(formatted.length, RenderLayer.getMaxLength(formatted), this.framesPerUpdate)
      layer.insertAscii(0,0, formatted, esc, color)
      layer.setTruePosition(pos)
      this.push(layer)
    })
  }
  push(layer){
    this.layers.push(layer)
  }
  updateNext(){
    if(this.framesUntilUpdate <= 0){
      this.framesUntilUpdate = this.framesPerUpdate
      return true
    }
    this.framesUntilUpdate--;
    return false
  }
  pause(){
    this.paused = true
  }
  play(){
    this.paused = false
  }
  reverse(){
    this.reversed = !this.reversed
  }
  setFrameBreakpoint(frame, callback){
    this.frameBreakpoints.push(frame)
    this.breakpointCallbacks.push(callback)
  }
  goTo(frame){
    this.currentFrame = frame
  }
  next(){
    if(this.paused){
      return
    }
    if(this.frameBreakpoints.includes(this.currentFrame)){
      for(var i = 0; i < this.frameBreakpoints.length; i++){
        if(this.currentFrame === this.frameBreakpoints[i]){
          this.breakpointCallbacks[i]()
          break
        }
      }
    }
    if(this.paused){
      return
    }
    if(this.reversed){
      if(this.currentFrame == 0){
        this.currentFrame = this.layers.length - 1
        return
      }
      this.currentFrame--
      return
    }
    if(this.currentFrame == this.layers.length - 1){
      this.currentFrame = 0
      return
    }
    this.currentFrame++
  }
  last(){
    if(this.paused){
      return
    }
    if(this.frameBreakpoints.includes(this.currentFrame)){
      for(var i = 0; i < this.frameBreakpoints.length; i++){
        if(this.currentFrame === this.frameBreakpoints[i]){
          this.breakpointCallbacks[i]()
          break
        }
      }
    }
    if(this.paused){
      return
    }
    if(this.reversed){
      if(this.currentFrame == this.layers.length - 1){
        this.currentFrame = 0
        return
      }
      this.currentFrame++
      return
    }
    if(this.currentFrame == 0){
      this.currentFrame = this.layers.length - 1
      return
    }
    this.currentFrame--
  }
  shuffle() {
    for (var i = this.layers.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = this.layers[i];
        this.layers[i] = this.layers[j];
        this.layers[j] = temp;
    }
  }
  hide(){
    this.hiding = true
    this.layers.forEach((layer) => {
      layer.hide()
    })
  }
  show(){
    this.hiding = false
    this.layers.forEach((layer) => {
      layer.show()
    })
  }
  isOffScreen(absoluteRowRange, absoluteColRange){
    if(this.offset[0] + this.rows <= absoluteRowRange[0] || this.offset[0] >= absoluteRowRange[1]){
      return true
    }
    if(this.offset[1] + this.cols <= absoluteColRange[0] || this.offset[1] >= absoluteColRange[1]){
      return true
    }
    return false
  }
  //@param colorMap: an array of pairs of oldColor and replacementColor respectively
  //ex. [['black', 'red'], ['yellow', 'blue']]
  recolor(colorMap){
    this.layers.forEach((layer) => {
      layer.recolor(colorMap)
    })
  }
  recolorNext(colorMap){
    this.layers.forEach((layer) => {
      if(layer !== this.layers[this.currentFrame]){
        layer.recolor(colorMap)
      }
    })
  }
}
export class RenderLayer{
  constructor(rows, cols, framesPerUpdate){
    this.name = ""
    this.rows = rows
    this.cols = cols
    this.text = []
    this.colors = []
    this.offset = [0,0]
    this.clickEvent = (row, col) =>{return false}
    this.hoverEvent = (row, col) => {return false}
    this.unhoverEvent = () => {return false}
    this.hovering = false
    this.hidden = false
    this.hiding = false
    this.framesPerUpdate = framesPerUpdate;
    this.framesUntilUpdate = 0;
    this.shiftFactor = 1
    this.computedDiff = undefined
    this.computeDiffInPost = false
    this.focused = false
    this.fullyClickable = false
    this.absoluteRenderBounds = []

    this.noShift = false
    this.shift = (x, y) => {
      this.setTruePosition([this.truePos[0] + y, this.truePos[1] + x])
    }
    this.prerender = () => {}
    this.truePos = [0,0]
    this.velocity = [0,0]

    init2dArray(this.text, rows)
    init2dArray(this.colors, rows)

    this.last = {
      offset: [this.offset[0], this.offset[1]],
      colors: clone2dArray(this.colors),
      text: clone2dArray(this.text)
    }
    this.destroyed = false
    this.fillColor(rows, cols, [0,0], TRANSPARENT_COLOR)
  }
  centerX(cols){
    this.setTruePosition([this.truePos[0], ((cols - this.cols) / 2)])
    return this
  }
  centerY(rows){
    this.setTruePosition([Math.floor((rows - this.rows) / 2), this.truePos[1]])
    return this
  }
  destroy(){
    this.destroyed = true
    this.hide()
  }
  setPrerender(event){
    this.prerender = event
  }
  triggerClickEvent(){
    this.clickEvent(0,0)
  }
  triggerHoverEvent(){
    this.hoverEvent(0,0)
  }
  triggerUnhoverEvent(){
    this.unhoverEvent()
  }
  //compute must be a function that takes in a diffMap as its only parameter
  setComputedDiff(compute){
    this.computedDiff = compute
  }
  diff(diffMap, foundColorMap, foundTextMap, bounds){
    if(this.computedDiff !== undefined && !this.computeDiffInPost){
      this.computedDiff(diffMap)
      return
    }
    //console.log(this.last.offset + " : " + this.offset + " (new)")
    const toVisit = new Set()
    //const diffMap = new Map()
    const offsetDiff = [this.offset[0] - this.last.offset[0], this.offset[1] - this.last.offset[1]]
    //console.log(offsetDiff)
    for(var i = this.last.offset[0]; i < this.last.offset[0] + this.rows; i++){
      if(this.absoluteRenderBounds.length !== 0){
        if(i < this.absoluteRenderBounds[0][0] && i + offsetDiff[0] < this.absoluteRenderBounds[0][0]){
          continue
        }
        if(i >= this.absoluteRenderBounds[0][1] && i + offsetDiff[0] >= this.absoluteRenderBounds[0][1]){
          break
        }
      }
      if(i < bounds[0][0] && i + offsetDiff[0] < bounds[0][0]){
        continue
      }
      if(i >= bounds[0][1] && i + offsetDiff[0] >= bounds[0][1]){
        break
      }
      for(var j = this.last.offset[1]; j < this.last.offset[1] + this.cols; j++){
        if(this.absoluteRenderBounds.length !== 0){
          if(j < this.absoluteRenderBounds[1][0] && j + offsetDiff[1] < this.absoluteRenderBounds[1][0]){
            continue
          }
          if(j >= this.absoluteRenderBounds[1][1] && j + offsetDiff[1] >= this.absoluteRenderBounds[1][1]){
            break
          }
        }
        if(j < bounds[1][0] && j + offsetDiff[1] < bounds[1][0]){
          continue
        }
        if(j >= bounds[1][1] && j + offsetDiff[1] >= bounds[1][1]){
          break
        }
        if(!(i < bounds[0][0] || i >= bounds[0][1] || j < bounds[1][0] || j >= bounds[1][1])){
          if(this.absoluteRenderBounds.length !== 0){
            if(!(i < this.absoluteRenderBounds[0][0] || i >= this.absoluteRenderBounds[0][1] 
                || j < this.absoluteRenderBounds[1][0] || j >= this.absoluteRenderBounds[1][1])){
                  toVisit.add(i + ',' + j)
            }
          }
          else{
            toVisit.add(i + ',' + j)
          }
        }
        if(!(i + offsetDiff[0] < bounds[0][0] || i >= bounds[0][1] || j + offsetDiff[1] < bounds[1][0] || j >= bounds[1][1])){
          if(this.absoluteRenderBounds.length !== 0){
            if(!(i + offsetDiff[0] < this.absoluteRenderBounds[0][0] || i >= this.absoluteRenderBounds[0][1] 
                || j + offsetDiff[1] < this.absoluteRenderBounds[1][0] || j >= this.absoluteRenderBounds[1][1])){
                  toVisit.add((i + offsetDiff[0]) + ',' + (j + offsetDiff[1]))
            }
          }
          else{
            toVisit.add((i + offsetDiff[0]) + ',' + (j + offsetDiff[1]))
          }
        }
      }
    }
    toVisit.forEach((key)=> {
      const row = parseInt(key.split(',')[0])
      const col = parseInt(key.split(',')[1])
      const lastCoord = this.toRelativeCoordinate(this.last.offset, row, col)
      const currCoord = this.toRelativeCoordinate(this.offset, row, col)
      var diffTextCurr = diffMap.has(key) ? diffMap.get(key).split("`")[0] : undefined
      var diffColorCurr = diffMap.has(key) ? diffMap.get(key).split("`")[1] : ''
      const diffColorNew = this.diffColor(diffColorCurr, foundColorMap, key, row, col, currCoord, lastCoord)
      const diffTextNew = this.diffText(diffTextCurr, foundTextMap, key, row, col, currCoord, lastCoord)
      diffColorCurr = diffColorNew ? diffColorNew : diffColorCurr
      diffTextCurr = diffTextNew ? diffTextNew : diffTextCurr
      if(!diffColorCurr && !diffTextCurr){
        return
      }
      diffMap.set(key, diffTextCurr + '`' + diffColorCurr)
    })
    if(this.computedDiff !== undefined && this.computeDiffInPost){
      this.computedDiff(diffMap)
    }
    this.hidden = this.hiding
    this.last.offset[0] = this.offset[0]
    this.last.offset[1] = this.offset[1]
    this.last.colors = clone2dArray(this.colors)
    this.last.text = clone2dArray(this.text)
  }
  diffColor(diff, foundMap, key, row, col, currCoord, lastCoord){
    if(foundMap.has(key)){
      return
    }
    if(diff !== '' && !isTransparent(diff)){
      return
    }
    //(row, col) in last layer but not in current
    if(this.includesCoordinate(this.last.offset, row, col) && !this.includesCoordinate(this.offset, row, col)){
      //Color is removed between layers and therefore add transparent to diffMap
      if(this.last.colors[lastCoord[0]] && isTransparent(this.last.colors[lastCoord[0]][lastCoord[1]])){
        return
      }
      return TRANSPARENT_COLOR
    }
    if(!this.colors[currCoord[0]]){
      return
    }
    var color = this.colors[currCoord[0]][currCoord[1]]
    if(!this.hidden && this.hiding){
      color = TRANSPARENT_COLOR
    }
    //(row, col) in current layer but not in last
    if(this.includesCoordinate(this.offset, row, col) && !this.includesCoordinate(this.last.offset, row, col)){
      //If current color is transparent, it need not be rendered and therefore 
      //this situation is equivalent to the color not being there due to the last layer not including it, so no diff

      //otherwise add this color to diff
      if(!isTransparent(color)){
        //console.log(key)
        return color
      }
      return
    }
    //(row, col) in both layers
    //Color is same between layers, return
    if(!isTransparent(color)){
      foundMap.set(key, color)
    }
    if(!(this.hidden & !this.hiding) && this.last.colors[lastCoord[0]] && this.last.colors[lastCoord[0]][lastCoord[1]] === color){
      if(diff !== '' && isTransparent(diff)){
        return color
      }
      return
    }
    //Otherwise add current color to diffMap
    return color
  }
  diffText(diff, foundMap, key, row, col, currCoord, lastCoord){
    if(foundMap.has(key)){
      return
    }
    if(diff !== '' && !isBlankText(diff)){
      return
    }
    //(row, col) in last layer but not in current
    if(this.includesCoordinate(this.last.offset, row, col) && !this.includesCoordinate(this.offset, row, col)){
      //Color is removed between layers and therefore add transparent to diffMap
      if(this.last.text[lastCoord[0]] && isBlankText(this.last.text[lastCoord[0]][lastCoord[1]])){
        return
      }
      return ''
    }
    if(!this.text[currCoord[0]]){
      return
    }
    var text = this.text[currCoord[0]][currCoord[1]]
    if(!this.hidden && this.hiding){
      text = ''
    }
    //(row, col) in current layer but not in last
    if(this.includesCoordinate(this.offset, row, col) && !this.includesCoordinate(this.last.offset, row, col)){
      //If current color is transparent, it need not be rendered and therefore 
      //this situation is equivalent to the color not being there due to the last layer not including it, so no diff

      //otherwise add this color to diff
      if(!isBlankText(text)){
        //console.log(key)
        return text
      }
      return
    }
    //(row, col) in both layers
    //Color is same between layers, return
    if(!isBlankText(this.text[currCoord[0]][currCoord[1]])){
      foundMap.set(key, this.text[currCoord[0]][currCoord[1]])
    }
    if(!(this.hidden && !this.hiding) && this.last.text[lastCoord[0]] && this.last.text[lastCoord[0]][lastCoord[1]] === text){
      if(diff !== '' && isBlankText(diff)){
        return text
      }
      return
    }
    //Otherwise add current color to diffMap
    return text
  }
  toRelativeCoordinate(offset, row, col){
    return [row - offset[0], col - offset[1]]
  }
  includesCoordinate(offset, rowQuery, colQuery){
    if(rowQuery < offset[0] || rowQuery >= offset[0] + this.rows){
      return false
    }
    if(colQuery < offset[1] || colQuery >= offset[1] + this.cols){
      return false
    }
    return true
  }
  isOffScreen(absoluteRowRange, absoluteColRange){
    if(this.offset[0] + this.rows <= absoluteRowRange[0] || this.offset[0] >= absoluteRowRange[1]){
      return true
    }
    if(this.offset[1] + this.cols <= absoluteColRange[0] || this.offset[1] >= absoluteColRange[1]){
      return true
    }
    return false
  }
  updateNext(){
    if(this.framesUntilUpdate <= 0){
      this.framesUntilUpdate = this.framesPerUpdate
      return true
    }
    this.framesUntilUpdate--;
    return false
  }
  setTruePosition(pos){
    this.truePos = pos
    this.offset[0] = Math.round(pos[0])
    this.offset[1] = Math.round(pos[1])
    if(this.trailProbability > 0){
      this.updateTrail()
    }
  }
  shiftContentOnly(x, y){
    for(var i = 0; i > x; i--){
      this.text.forEach((textArr) => {
        textArr.shift()
        textArr.push('')
      })
      this.colors.forEach((colorArr) => {
        colorArr.shift()
        colorArr.push(TRANSPARENT_COLOR)
      })
    }
    for(var i = 0; i < x; i++){
      this.text.forEach((textArr) => {
        textArr.pop()
        textArr.unshift('')
      })
      this.colors.forEach((colorArr) => {
        colorArr.pop()
        colorArr.unshift(TRANSPARENT_COLOR)
      })
    }
    for(var i = 0; i > y; i--){
      this.text.shift()
      this.text.push([])
      this.colors.shift()
      this.colors.push([])
    }
    for(var i = 0; i < y; i++){
      this.text.pop()
      this.text.unshift([])
      this.colors.pop()
      this.colors.unshift([])
    }
  }
  setNoShift(noShift){
    this.noShift = noShift
    return this
  }
  setVelocity(vel){
    this.velocity = vel
  }
  setFramesPerUpdate(framesPerUpdate){
    this.framesPerUpdate = framesPerUpdate
  }
  onClick(row, col){
    return this.clickEvent(row, col);
  }
  onHover(row, col){
    return this.hoverEvent(row, col);
  }
  setClickEvent(event){
    this.clickEvent = event;
  }
  setHoverEvent(event){
    this.hoverEvent = event
  }
  setUnhoverEvent(event){
    this.unhoverEvent = event
  }
  drawBorder(rows, cols, roundNum, char, alternatingChar, fillChar, color, fillColor){
    var border = ""
    for(var i = 0; i < cols; i++){
      if(i < roundNum || i >= cols - roundNum){
        border += fillChar
      }
      else{
        var add = i % 2 == 0 ? char : alternatingChar
        border += add
      }
    }
    border += "\n"
    for(var i = 0; i < rows; i++){
      var add = i % 2 == 0 ? char : alternatingChar
      border += add
      for(var j = 1; j < cols - 1; j++){
        border += fillChar
      }
      border += add + "\n"
    }
    for(var i = 0; i < cols; i++){
      if(i < roundNum || i >= cols - roundNum){
        border += fillChar
      }
      else{
        var add = i % 2 == 0 ? char : alternatingChar
        border += add
      }
    }
    this.insertAscii(0, 0, border.split("\n"), "`", color)
    if(!isTransparent(fillColor))
      this.fillColor(rows, cols - 2, [1,1], fillColor)
  }
  fillColor(rows, cols, offset, color){
    for(var i = 0; i < rows; i++){
      for(var j = 0; j < cols; j++){
        if(i + offset[0] >= this.rows || j + offset[1] >= this.cols){
          break
        }
        this.colors[i + offset[0]][j + offset[1]] = color;
      }
    }
  }
  fillText(rows, cols, offset, text){
    for(var i = 0; i < rows; i++){
      for(var j = 0; j < cols; j++){
        if(i + offset[0] >= this.rows || j + offset[1] >= this.cols){
          break
        }
        this.text[i + offset[0]][j + offset[1]] = text;
      }
    }
  }
  clearText(){
    for(var i = 0; i < this.rows; i++){
      for(var j = 0; j < this.cols; j++){
        this.text[i][j] = "";
      }
    }
  }
  insertText(row, col, text, color){
    for(var i = 0; i < text.length; i++){
      var char = text.charAt(i)
      // if(isBlankText(char)){
      //   char = ' '
      // }
      this.text[row][col + i] = char
      this.colors[row][col + i] = color
    }
  }
  insertTextNoEscapeCharacter(row, col, text, esc, color){
    if(row < 0 || row >= this.rows)
      return
    for(var i = 0; i < text.length; i++){
      if(col + i < 0)
        continue
      if(col + i >= this.cols)
        return
      if(text.charAt(i) !== esc && row >= 0 && row < this.rows && col + i >= 0 && col + i < this.cols){
        this.text[row][col + i] = text.charAt(i)
        this.colors[row][col + i] = color
      }
    }
  }

  insertColorBlock(rowRange, colRange, color){
    for(var i = rowRange[0]; i <= rowRange[1]; i++){
      for(var j = colRange[0]; j <= colRange[1]; j++){
        this.colors[i][j] = color
      }
    }
  }
  //Removes whitespaces from text
  insertAscii(row, col, ascii, esc, color){
    for(var i = 0; i < ascii.length; i++){
      this.insertTextNoEscapeCharacter(row + i, col, ascii[i], esc, color)
    }
  }
  //Repeat text: repeat cols
  insertTextBlockRepeatTextRepeatCols(rowRange, col, text, color){
    for(var i = 0; i <= Math.abs(rowRange[1] - rowRange[0]); i++){
      this.insertText(rowRange[0] + i, col, text, color)
    }
  }
  //Repeat text: unique cols
  insertTextBlockRepeatTextUniqueCols(rowRange, cols, text, color){
    for(var i = 0; i <= Math.abs(rowRange[1] - rowRange[0]); i++){
      this.insertText(rowRange[0] + i, cols[i], text, color)
    }
  }
  //Unique text: repeat cols
  insertTextBlockUniqueTextRepeatCols(startRow, col, texts, color){
    for(var i = 0; i < texts.length; i++){
      this.insertText(startRow + i, col, texts[i], color)
    }
  }
  insertTextBlockSkipRows(row, col, skip, texts, color){
    for(var i = 0; i < texts.length; i++){
      this.insertText(row + (i * skip), col, texts[i], color)
    }
  }
  //Unique text: unique cols
  insertTextBlockUniqueTextUniqueCols(startRow, cols, texts, color){
    for(var i = 0; i < texts.length; i++){
      this.insertText(startRow + i, cols[i], texts[i], color)
    }
  }
  hide(){
    this.hiding = true;
    return this
  }
  show(){
    this.hiding = false;
    return this
  }
  //@param colorMap: an array of pairs of oldColor and replacementColor respectively
  //ex. [['black', 'red'], ['yellow', 'blue']]
  recolor(colorMap){
    var found = false
    for(var i = 0; i < this.rows; i++){
      for(var j = 0; j < this.cols; j++){
        for(var k = 0; k < colorMap.length; k++){
          if(this.colors[i][j] === colorMap[k][0]){
            this.colors[i][j] = colorMap[k][1]
            found = true
            break
          }
        }
      }
    }
    return found
  }
  recolorByText(chars, color){
    for(var i = 0; i < this.rows; i++){
      for(var j = 0; j < this.cols; j++){
        for(var k = 0; k < chars.length; k++){
          if(this.text[i][j] === chars[k]){
            this.colors[i][j] = color
          }
        }
      }
    }
  }
  
  static getMaxLength(arr){
    var max = 0
    for(var i = 0; i < arr.length; i++){
      max = Math.max(arr[i].length, max)
    }
    return max
  }
}

class ImageLayer{
  constructor(path, width, height, inBackground){
    this.path = path
    this.width = width
    this.height = height
    this.inBackground = inBackground
  }
  getImage(){
    const img = new Image(this.width, this.height)
    img.src = this.path
    return img
  }
}

export function init2dArray(arr, rows){
  for(var i = 0; i < rows; i++){
    arr[i] = []
  }
}
export function clone2dArray(arr){
  var newArray = [];
  for (var i = 0; i < arr.length; i++){
    newArray[i] = []
    for(var j = 0; j < arr[i].length; j++){
      newArray[i][j] = arr[i][j]
    }
  }
  return newArray
}
export function fill2dArray(arr, fill){
  for(var i = 0; i < arr.length; i++){
    for(var j = 0; j < arr[i].length; j++){
      arr[i][j] = fill
    }
  }
}
/***
 * Private rendering utility functions
 * 
 * YOU SHOULD NOT HAVE TO CALL THESE FROM ANYWHERE BUT HERE!!!
 ***/

function setBackgroundColor(color){
  document.getElementById("App").style.backgroundColor = color
}

export function isTransparent(rgbString){
  if(rgbString === undefined)
    return false;
  var rgbArr = rgbString.split(', ')
  if(rgbArr.length < 4)
    return false
  var opacity = rgbArr[rgbArr.length - 1].charAt(0)
  return opacity === '0'
}

export function isBlankText(char){
  return (char === undefined || char === '' || char === 'undefined')
}

const BLACK_FILTERED_SHADOW = 80;

export function getLighterColorFromRGB(rgbString){
  if(rgbString === undefined || rgbString === ''){
      return "rgb(" + BLACK_FILTERED_SHADOW + ", " + BLACK_FILTERED_SHADOW + ", " + BLACK_FILTERED_SHADOW + ")"
  }
  let strs = rgbString.split("(")[1].split(")")[0].split(", ");
  let rgb = [parseInt(strs[0]), parseInt(strs[1]), parseInt(strs[2])]
  return "rgb(" + Math.min(rgb[0] + 100, 255) + ", " + Math.min(rgb[1] + 100, 255) + ", " + Math.min(rgb[2] + 100, 255) + ")"
  var max = 0, min = 255
  for(var i = 0; i < rgb.length; i++){
      max = Math.max(rgb[i], max)
      min = Math.min(rgb[i], min)
  }
  let filtered = Math.round((max + min) / 2)
  if(filtered == 0)
      filtered = BLACK_FILTERED_SHADOW
  return "rgb(" + filtered + ", " + filtered + ", " + filtered + ")"
}

export function getRandomColor(colors, lastColor){
  let index = Math.floor(Math.random() * colors.length);
  if(colors[index] === lastColor){
    index++
    if(index == colors.length)
      index = 0
  }
  return colors[index]
}

export async function changeCursor(document){
  var appBody = document.getElementsByClassName("App")[0]
  var r = Math.floor(Math.random() * 329)
  var dataUrl = cursorsJson[r].base64;
  var newCursor = "url(\"data:image/gif;base64," + dataUrl + "\"), auto"
  appBody.style.cursor = newCursor;
  setTimeout(null, 0)
  window.blur()
  window.focus()
}

export class CombinedLayer{
  constructor(layers){
    this.layers = layers
    this.rows = 0
    this.cols = 0
    this.shiftFactor = 1
    // this.truePos = []
    // this.shift = (x, y) => {
    //   this.setTruePosition([this.truePos[0] + y, this.truePos[1] + x])
    // }
    this.layers.forEach((layer) => {
      this.rows = Math.max(this.rows, layer.rows)
      this.cols = Math.max(this.cols, layer.cols)
    })
  }
  setTruePosition(pos){
    this.layers.forEach((layer) => {
      layer.setTruePosition(pos)
    })
  }
  setClickEvent(func){
    this.layers.forEach((layer) => {
      layer.setClickEvent(func)
    })
  }
  hide(){
    this.layers.forEach((layer) => {
      layer.hide()
    })
  }
  show(){
    this.layers.forEach((layer) => {
      layer.show()
    })
  }
  setNoShift(bool){
    this.layers.forEach((layer) => {
      layer.setNoShift(bool)
    })
    return this
  }
  shift(x, y){
    this.layers.forEach((layer) => {
      layer.shift(x, y)
    })
  }
  shiftContentOnly(x, y){
    this.layers.forEach((layer) => {
      layer.shiftContentOnly(x, y)
    })
  }
  render(){
    this.layers.forEach((layer) => {
      layer.render()
    })
  }
  listOfHeights(){
    var list = [];
    this.layers.forEach((layer) => {
      list.push(layer.rows)
    })
    return list;
  }
  listOfWidths(){
    var list = [];
    this.layers.forEach((layer) => {
      list.push(layer.cols)
    })
    return list;
  }
}

export function textToAscii(text, font){

  var output = ''

  figlet.text(
    text,
    {
      font: font,
    },
    function (err, data) {
      if (err) {
        console.dir(err);
        return;
      }
      // for (var i = 0; i < data.length; i++){
      //   if (data[i] === ' '){
      //     output += '`'
      //   }
      //   else{
      //     output += data[i]
      //   }
      // }

      var string = ""
      // if a whole line in the data splitted by \n is just spaces, dont add it to the string
      data.split("\n").forEach((line) => {
        // if (line.trim() === ""){
        //   return
        // }
        string += line + "\n"
      })
      output = string.replaceAll(' ', '`')
      // output = string


      // console.log(data)
      // output = data.replaceAll(" ", "`")
    }
  );
  return output
}

export function makeBlock(jsonName, color, x, y){
  var block = jsonName.split("\n")
  var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
  layer.insertAscii(0,0, block, '`', color)
  layer.setTruePosition([x, y])
  return layer
}

export function makeColorRectangle(color, rows, cols){
  var layer = new RenderLayer(rows, cols, 0)
  layer.fillColor(rows, cols, [0,0], color)
  return layer
}

export function centerX(layer, cols){
  if(layer instanceof RenderGif){
    layer = layer.layers[layer.currentFrame]
  }
  return (cols - layer.cols) / 2
}

export function centerY(layer, rows){
  if(layer instanceof RenderGif){
    layer = layer.layers[layer.currentFrame]
  }
  return (rows - layer.rows) / 2
}

export async function loadImage(path, callback){
  let response = await fetch(path);
  let data = await response.blob();
  let metadata = {
    type: 'image/png'
  };
  let file = new File([data], "test.png", metadata);
  var filereader = new FileReader();
  var colors = []
  filereader.onloadend = function(event) {
    new PNG({filterType: 4}).parse(event.target.result, function(error, image){
        if(error){return;}
        for (var y = 0; y < image.height; y++) {
          colors[y] = []
          for (var x = 0; x < image.width; x++) {
            var idx = (image.width * y + x) << 2;
            let r = image.data[idx]
            let g = image.data[idx + 1]
            let b = image.data[idx + 2]
            let a = image.data[idx + 3]

            colors[y].push("rgb(" + r + ", " + g + ", " + b + ", " + a + ")");
          }
        }
        callback(colors)
      });
    };
  filereader.readAsArrayBuffer(file);
}

export function loadImageToCanvas(path, canvas, layerCallback, insertionIndex){
  return loadImage(path, (colors) => {
    var layer = new RenderLayer(colors.length, RenderLayer.getMaxLength(colors), 0)
    layer.colors = colors
    if(insertionIndex !== undefined){
      canvas.insert(insertionIndex, layer)
    }
    else{
      canvas.push(layer)
    }
    layerCallback(layer)
  }) 
}

export function createBorderGif(rowsStart, rowsEnd, colsStart, colsEnd, incremementFactor, char, alternatingChar, fillChar, color, fillColor, gifspeed){
  const rowInc = rowsStart < rowsEnd ? 1 * incremementFactor : -1 * incremementFactor
  const colInc = colsStart < colsEnd ? 1 * incremementFactor : -1 * incremementFactor
  var gif = new RenderGif(gifspeed)
  for(var i = 0; i < Math.floor(Math.max(Math.abs(rowsEnd - rowsStart), Math.abs(colsEnd - colsStart)) / incremementFactor); i++){
    var currRows = rowsStart + (rowInc * i)
    var clampedRows = rowsStart < rowsEnd ? Math.min(rowsEnd, currRows) : Math.max(rowsEnd, currRows)
    var currCols = colsStart + (colInc * i)
    var clampedCols = colsStart < colsEnd ? Math.min(colsEnd, currCols) : Math.max(colsEnd, currCols)
     
    var layer = new RenderLayer(clampedRows, clampedCols, 0)
    layer.drawBorder(clampedRows - 2, clampedCols, 0, char, alternatingChar, fillChar, color, fillColor)
    gif.layers.push(layer)
  }
  return gif
}

export function base64ToArrayBuffer(base64) {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
