import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import projectjson from './projects/ascii.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY, loadImageToCanvas, createBorderGif, clone2dArray, shift} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay, antidecay, forceAntidecay, applyPattern } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { format, getSound, stopAlbumPlayback, pauseAlbumPlayback, playSound, startAlbumPlayback, toggleAlbumLooping, getTrackDuration, getTrackCurrentPosition, seekTrack, resumeAlbumPlayback, loadProject, onEnd } from '../scripts/SoundManager.js'
import ReactGA from "react-ga4";

let VERSION_NUM="1.2.1"

function Tracklist({ artist, project }) {
  const navigate = useNavigate();

  var fontsize = 9
  var colFactor = 288/1440
  var rowFactor = 130/779

  var onMobile = false
  if(window.innerWidth < window.innerHeight){
    onMobile = true
    fontsize = 8
    colFactor = 355/1440
    rowFactor = 156/779
  }

  useEffect(() => {
      loadProject(artist, project)
  }, [])
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)
  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 8
            colFactor = 288/1440
            rowFactor = 130/779

            if(window.innerWidth < window.innerHeight){
                onMobile = true
                fontsize = 8
                colFactor = 355/1440
                rowFactor = 156/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(233, 226, 214)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"

  const artistFormatted = format(artist.name)
  const projectFormatted = format(project.name)
  var setBinding = false

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send({hitType: "pageview", page: "/music/" + artistFormatted + "/" + projectFormatted, title: projectFormatted})
  }, [])

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col, delay){
      var tempDelay = delay
      if(delay === undefined)
        tempDelay = 0
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), tempDelay)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    function insertBlock(jsonName, layer, color){
        var block = jsonName.split("\n")
        layer.insertAscii(0,0, block, '`', color)
    }

    function getArtistPath(){
        return 'mus/' + artistFormatted + '/' + projectFormatted
    }
    var notStarted = true
    var playingIndex = 0
    var startedIndex = 0
    var playing = false
    var playingNameScroll = 0
    var trackLayers = []
    function stylePlayingTrack(index){
        if(index >= liveTracks.length){
           trackLayers[trackLayers.length - 1].recolor([[project.pcolor, project.tcolor]])
           currentTrackArrow.hide()
           return
        }
        var layer = trackLayers[index]
        layer.recolor([[project.tcolor, project.pcolor], [project.scolor, project.pcolor]])
        currentTrackArrow.setTruePosition([layer.offset[0], layer.offset[1] + layer.cols])
        currentTrackArrow.show()
    }
    function styleNonplayingTrack(index){
        var layer = trackLayers[index]
        layer.recolor([[project.pcolor, project.tcolor]])
        currentTrackArrow.hide()
    }
    var maxLength = 28
    var textFont = '4Max'
    if(onMobile){
        maxLength = 21
        textFont = "Small"
    }
    var deadTracks = []
    var liveTracks = []
    function populateTracklist(){
        var i = 0
        project.tracks.forEach((track) => {
            var track_name = track
            if(!onMobile){
                if(track.length > 28){
                    track_name = track_name.slice(0, 25) + "..."
                }
            }
            else{
                if(track.length > 21){
                    track_name = track_name.slice(0, 18) + "..."
                }
            }
            var color = project.tcolor
            if(track.includes("{}")){
                deadTracks.push(track)
                color = "rgb(120, 120, 120)"
                track_name = track_name.replace("{}", "")
            }
            else{
                liveTracks.push(track)
            }
            var layer
            if(!onMobile){
                layer = makeBlock(textToAscii(track_name, '4Max'), color, Math.round((rows / 2) - (130 / 2)) + 34 + (i * 10), 8 + Math.round((cols / 2) - (288 / 2)), 20)
            }
            else{
                layer = makeBlock(textToAscii(track_name, 'Small'), color, (rows / 2) + 16 + (i * 8), 5, 20)
            }
            layer.fullyClickable = true
            layer.absoluteRenderBounds = [[30, rows], [0, cols]]
            if(onMobile)
                layer.absoluteRenderBounds = [[90, rows], [0, cols]]
            layer.setClickEvent(() => {
                if(deadTracks.includes(track)){
                    return
                }
                playingNameScroll = 0
                if(playing && playingIndex === liveTracks.indexOf(track)){
                    pause()
                }
                else{
                    stopAlbumPlayback(getArtistPath())
                    clearPlaybar()
                    styleNonplayingTrack(playingIndex)
                    playingIndex = liveTracks.indexOf(track)
                    play()
                    trackLayers.forEach((tlayer) => {
                        tlayer.recolor([[project.scolor, project.tcolor]])
                    })
                    stylePlayingTrack(playingIndex)
                }
            })
            layer.setHoverEvent(() => {
                if(deadTracks.includes(track)){
                    return
                }
                if(!(playing && playingIndex === liveTracks.indexOf(track))){
                    layer.recolor([[project.tcolor, project.scolor]])
                }
                if(!playing){
                    currentTrackArrow.setTruePosition([layer.offset[0], layer.offset[1] + layer.cols])
                    currentTrackArrow.show()
                }
            })
            layer.setUnhoverEvent(() => {
                if(deadTracks.includes(track)){
                    return
                }
                if(!(playing && playingIndex === liveTracks.indexOf(track))){
                    layer.recolor([[project.scolor, project.tcolor]])
                    if(!playing){
                        currentTrackArrow.hide()
                    }
                }
            })
            layer.setPrerender(() => {
                if(playingIndex === liveTracks.indexOf(track) && playing){
                    if(layer.updateNext()){
                        if(track.length > maxLength){
                            track_name = track.slice(playingNameScroll, maxLength - 3 + playingNameScroll)
                            if(playingNameScroll + maxLength - 3 < track.length){
                                track_name += "..."
                                playingNameScroll += 1
                            } 
                            else{
                                playingNameScroll = 0
                            }
                            layer.clearText()
                            layer.fillColor(layer.rows, layer.cols, [0,0], 'rgb(0, 0, 0, 0)')
                            layer.insertAscii(0, 0, textToAscii(track_name, textFont).split('\n'), '`', project.pcolor)
                        }
                    }
                }
                else{
                    if(track_name.length > maxLength && playingNameScroll !== 0){
                        playingNameScroll = 0
                        track_name = track.slice(playingNameScroll, maxLength - 3 + playingNameScroll) + "..."
                        layer.clearText()
                        layer.fillColor(layer.rows, layer.cols, [0,0], 'rgb(0, 0, 0, 0)')
                        layer.insertAscii(0, 0, textToAscii(track_name, textFont).split('\n'), '`', project.tcolor)
                    }
                }
            })
            canvas.push(layer)
            if(!deadTracks.includes(track))
                trackLayers.push(layer)
            i++
        })
    }
    var canvas = new RenderCanvas(rows, cols, fontsize, 32, project.bg3color)

    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], project.bg2color)

    // var bgLayer = new RenderLayer(rows, cols, 0)
    // bgLayer.fillColor(rows, cols, [0,0], project.bgcolor)

    var title

    if(!onMobile){
        title = makeBlock(textToAscii(artist.name, artist.pfont), artist.pcolor, 5, 5).centerX(cols)
    }
    else{
        title = makeBlock(textToAscii(artist.name, "Small"), artist.pcolor, 5, 5).centerX(cols)
    }
    title.setNoShift(true)
    var projectTitle
    console.log(artist)
    if(!onMobile){
        projectTitle = makeBlock(textToAscii(project.name, artist.sfont), project.pcolor, title.rows + 6, 5).centerX(cols)
    }
    else{
        projectTitle = makeBlock(textToAscii(project.name, "Small"), project.pcolor, title.rows + 5, 5).centerX(cols)
    }
    projectTitle.setNoShift(true)

    var currentTrackArrow = makeBlock(textToAscii('<--', '4Max'), project.bgcolor, 0, 0)
    currentTrackArrow.hide()

    var footer = new RenderLayer(16, cols, 0)
    footer.drawBorder(14, cols, 0, '+', '-', '*', project.bgcolor, 'rgb(20, 20, 20)')
    footer.setTruePosition([rows - 16, 0])
    footer.setNoShift(true)

    var minPrefix = ":"
    var timeOffset = [rows - 10, cols - 40]
    if(onMobile){
        minPrefix = " :"
        timeOffset = [rows - 11, cols - 30]
    }
    function updateTimeRemaining(){
        var tempMin = Math.floor((getTrackDuration(getArtistPath(), playingIndex) - getTrackCurrentPosition(getArtistPath(), playingIndex)) / 60)
        var tempSec = Math.floor((getTrackDuration(getArtistPath(), playingIndex) - getTrackCurrentPosition(getArtistPath(), playingIndex)) % 60)
        if(tempMin !== minRemaining){
            minRemaining = tempMin
            var minString = minRemaining < 10 ? '0' + minRemaining + minPrefix : '' + minRemaining + minPrefix
            minLayer.fillColor(minLayer.rows, minLayer.cols, [0,0], 'rgb(20, 20, 20)')
            minLayer.insertAscii(0,0,textToAscii(minString, textFont).split('\n'), '`', project.bgcolor)
        }
        if(tempSec !== secRemaining){
            secRemaining = tempSec
            var secString = secRemaining < 10 ? '0' + secRemaining : '' + secRemaining
            secLayer.fillColor(secLayer.rows, secLayer.cols, [0,0], 'rgb(20, 20, 20)')
            secLayer.insertAscii(0,0, textToAscii(secString, textFont).split('\n'), '`', project.bgcolor)
        }
    }

    const fillColor = project.fillcolor === undefined ? project.pcolor : project.fillcolor
    function updatePlaybar(){
        var barmax = (playBar.cols - 2) / getTrackDuration(getArtistPath(), playingIndex)
        var barfill = Math.round(barmax * getTrackCurrentPosition(getArtistPath(), playingIndex))
        playBar.fillColor(4, barfill, [1,1], fillColor)
    }

    var minLayer = makeBlock(textToAscii('00' + minPrefix, textFont), project.bgcolor, timeOffset[0], timeOffset[1])
    minLayer.setNoShift(true)
    var secLayer = makeBlock(textToAscii('00', textFont), project.bgcolor, timeOffset[0], timeOffset[1] + minLayer.cols)
    secLayer.setNoShift(true)
    var secRemaining = 0
    var minRemaining = 0
    var playBar = new RenderLayer(6, cols - 90, 0)
    playBar.drawBorder(4, cols - 90, 0, '<', '>', '/', project.bgcolor, project.bg2color)
    playBar.setTruePosition([rows - 11, 40])
    if(onMobile)
        playBar.setTruePosition([rows - 11, cols + 1])
    playBar.setNoShift(true)
    playBar.setPrerender(() => {
        if(playing){
            updatePlaybar()
            updateTimeRemaining()
        }
    })
    playBar.setClickEvent((row, col) => {
        clearPlaybar()
        playBar.fillColor(4, col, [1,1], fillColor)
        var barmax = (playBar.cols - 2) / getTrackDuration(getArtistPath(), playingIndex)
        seekTrack(getArtistPath(), col / barmax)
        updateTimeRemaining()
    })

    function clearPlaybar(){
        playBar.fillColor(4, playBar.cols - 2, [1,1], project.bg2color)
    } 

    
    var playButton = new RenderLayer(12, 16, 0)
    playButton.insertAscii(3, 4, ascii.play.split('\n'), '`', project.bgcolor)
    playButton.setTruePosition([playBar.offset[0] - 3, playBar.offset[1] - 34])
    playButton.fullyClickable = true
    playButton.setNoShift(true)
    var pauseButton = makeBlock(ascii.pause, project.bgcolor, playBar.offset[0], playBar.offset[1] - 30)
    pauseButton.fullyClickable = true
    pauseButton.setNoShift(true)
    pauseButton.hide()
    if(onMobile){
        playButton.centerX(cols)
        pauseButton.centerX(cols)
    }
    playButton.setHoverEvent(() => {
        playButton.recolor([[project.bgcolor, project.scolor]])
    })
    playButton.setUnhoverEvent(() => {
        playButton.recolor([[project.scolor, project.bgcolor]])
    })
    playButton.setClickEvent(() => {
        stylePlayingTrack(playingIndex)
        play()
    })
    pauseButton.setHoverEvent(() => {
        pauseButton.recolor([[project.bgcolor, project.scolor]])
    })
    pauseButton.setUnhoverEvent(() => {
        pauseButton.recolor([[project.scolor, project.bgcolor]])
    })
    pauseButton.setClickEvent(() => {
        pause()
    })

    var lastArrow = new RenderLayer(6, 3, 0)
    lastArrow.colors[0][2] = project.bgcolor
    lastArrow.colors[1][1] = project.bgcolor
    lastArrow.colors[1][2] = project.bgcolor
    lastArrow.colors[2][0] = project.bgcolor
    lastArrow.colors[2][1] = project.bgcolor
    lastArrow.colors[2][2] = project.bgcolor
    lastArrow.colors[3][0] = project.bgcolor
    lastArrow.colors[3][1] = project.bgcolor
    lastArrow.colors[3][2] = project.bgcolor
    lastArrow.colors[4][1] = project.bgcolor
    lastArrow.colors[4][2] = project.bgcolor
    lastArrow.colors[5][2] = project.bgcolor

    lastArrow.setTruePosition([playBar.truePos[0], playBar.truePos[1] - 5])
    lastArrow.fullyClickable = true
    lastArrow.setNoShift(true)
    lastArrow.setHoverEvent(() => {
        lastArrow.recolor([[project.bgcolor, project.scolor]])
    })
    lastArrow.setUnhoverEvent(() => {
        lastArrow.recolor([[project.scolor, project.bgcolor]])
    })
    lastArrow.setClickEvent(() => {
        clearPlaybar()
        const progress = getTrackCurrentPosition(getArtistPath())
        stopAlbumPlayback(getArtistPath())
        if(progress < 10){
            styleNonplayingTrack(playingIndex)
            playingIndex = Math.max(0, playingIndex - 1)
            stylePlayingTrack(playingIndex)
        }
        play()
    })

    var nextArrow = new RenderLayer(6, 3, 0)
    nextArrow.colors[0][0] = project.bgcolor
    nextArrow.colors[1][1] = project.bgcolor
    nextArrow.colors[1][0] = project.bgcolor
    nextArrow.colors[2][2] = project.bgcolor
    nextArrow.colors[2][1] = project.bgcolor
    nextArrow.colors[2][0] = project.bgcolor
    nextArrow.colors[3][2] = project.bgcolor
    nextArrow.colors[3][1] = project.bgcolor
    nextArrow.colors[3][0] = project.bgcolor
    nextArrow.colors[4][1] = project.bgcolor
    nextArrow.colors[4][0] = project.bgcolor
    nextArrow.colors[5][0] = project.bgcolor

    nextArrow.setTruePosition([playBar.truePos[0], playBar.truePos[1] + playBar.cols + 2])
    nextArrow.fullyClickable = true
    nextArrow.setNoShift(true)
    nextArrow.setHoverEvent(() => {
        nextArrow.recolor([[project.bgcolor, project.scolor]])
    })
    nextArrow.setUnhoverEvent(() => {
        nextArrow.recolor([[project.scolor, project.bgcolor]])
    })
    nextArrow.setClickEvent(() => {
        clearPlaybar()
        stopAlbumPlayback(getArtistPath())
        styleNonplayingTrack(playingIndex)
        playingIndex++
        stylePlayingTrack(playingIndex)
        play()
    })

    const analyticsCallback = () => {
        ReactGA.event({
            category: "Music",
            action: "Play Song",
            label: artistFormatted + "/" + projectFormatted + "/" + liveTracks[playingIndex]
        })
    }

    function initTrackCallbacks(){
        for(var i = 0; i < liveTracks.length; i++){
            if(i === liveTracks.length - 1){
                onEnd(getArtistPath(), i, () => {
                    if(!playing)
                        return 
                    clearPlaybar()
                    styleNonplayingTrack(playingIndex)
                    playingIndex = 0
                    playing = false
                    pause()
                    stopAlbumPlayback(getArtistPath())
                })  
                return
            }
            onEnd(getArtistPath(), i, () => {
                if(!playing)
                    return 
                clearPlaybar()
                styleNonplayingTrack(playingIndex)
                if(playingIndex < liveTracks.length - 1){
                    playingIndex++
                    stylePlayingTrack(playingIndex)
                    startAlbumPlayback(getArtistPath(), playingIndex, analyticsCallback)
                }
                else{
                    styleNonplayingTrack(playingIndex)
                    playingIndex = 0
                    playing = false
                    pause()
                    stopAlbumPlayback(getArtistPath())
                }
            }) 
        }
    }

    function play(){
        if(onMobile){
            noMobileText.show()
            noMobileBorder.show()
            setTimeout(() => {
                noMobileText.hide()
                noMobileBorder.hide()
            }, 5000)
            return
        }
        pauseButton.show()
        playButton.hide()
        if(startedIndex !== playingIndex || notStarted){
            notStarted = false
            console.log("starting new track: " + startedIndex + " !== " + playingIndex)
            clearPlaybar() 
            startedIndex = playingIndex
            if(playingIndex >= liveTracks.length){
                playingIndex = 0
                styleNonplayingTrack(trackLayers.length - 1)
                playingIndex = 0
                playing = false
                pause()
                stopAlbumPlayback(getArtistPath())
                return
            }
            startAlbumPlayback(getArtistPath(), playingIndex, analyticsCallback)
        }
        else{
            resumeAlbumPlayback(getArtistPath())
            stylePlayingTrack(playingIndex)
        }
        playing = true
    }

    function pause(){
        playButton.show()
        pauseButton.hide()
        pauseAlbumPlayback(getArtistPath())
        playing = false
    }

    var backArrow = makeBlock(textToAscii("<--", textFont), project.pcolor, 8, 12)
    if(onMobile)
        backArrow.setTruePosition([5,5])
    backArrow.setNoShift(true)
    backArrow.fullyClickable = true
    backArrow.setHoverEvent(() => {
        backArrow.recolor([["rgb(255, 125, 125)", "rgb(255, 255, 255)"]])
    })
    backArrow.setUnhoverEvent(() => {
        backArrow.recolor([["rgb(255, 255, 255)", "rgb(255, 125, 125)"]])
    })
    backArrow.setClickEvent(() => {
        navigate('/music/' + artistFormatted)
    })

    var bgBorder
    var projectIdx = projectjson.projectIndices.indexOf(artistFormatted + "/" + projectFormatted)
    if(projectIdx !== -1){
        var projectBg = projectjson.bgs[projectIdx]
        var bgRows = projectBg.split('\n').length
        var bgCols = projectBg.split('\n')[0].length
        bgBorder = new RenderLayer(bgRows + 1, bgCols + 2, 0)
        bgBorder.drawBorder(bgRows - 1, bgCols, 0, '*', '*', '`', project.bgcolor, 'rgb(0, 0, 0, 0)')
        bgBorder.show()
        bgBorder.setNoShift(true)
        bgBorder.setTruePosition([Math.round((rows / 2) - (bgRows / 2)) - 3, Math.round((cols / 2) - (bgCols / 2))])
        var pattern = project.bgpattern === undefined ? undefined : project.bgpattern.split(' ')[0]
        var patternChar1 = undefined, patternChar2 = undefined
        if(pattern !== undefined){
            patternChar1 = project.bgpattern.split(' ')[1]
            patternChar2 = project.bgpattern.split(' ')[2]
        }
        applyPattern(baseLayer, pattern, project.bg2color, patternChar1, patternChar2)
        baseLayer.insertAscii(Math.round((rows / 2) - (bgRows / 2)) - 3, Math.round((cols / 2) - (bgCols / 2)), projectBg.split('\n'), '`', project.bgcolor)
    }

    var noMobileText = makeBlock(textToAscii("     MOBILE \n\n \n\n  PLAYBACK \n\n \n\n  CURRENTLY \n\n \n\n       NOT \n\n \n\n SUPPORTED :(", "4Max"), 'rgb(255, 255, 255)', 0, 0).centerX(cols).centerY(rows)
    noMobileText.hide()
    noMobileText.setClickEvent(() => {
        noMobileBorder.hide()
        noMobileText.hide()
    })
    var noMobileBorder = new RenderLayer(50, cols - 2 , 0)
    noMobileBorder.drawBorder(noMobileBorder.rows - 2, noMobileBorder.cols, 0, 'v', '^', '0', 'rgb(255, 255, 255)', 'rgb(20, 20, 20)')
    noMobileBorder.centerX(cols).centerY(rows)
    noMobileBorder.hide()
    noMobileBorder.setClickEvent(() => {
        noMobileBorder.hide()
        noMobileText.hide()
    })

    canvas.push(baseLayer)
    if(bgBorder !== undefined)
        canvas.push(bgBorder)
    canvas.push(title)
    canvas.push(projectTitle)
    populateTracklist()
    initTrackCallbacks()
    canvas.push(currentTrackArrow)
    canvas.push(footer)
    canvas.push(playBar)
    if(!onMobile){
        canvas.push(lastArrow)
        canvas.push(nextArrow)
    }
    canvas.push(playButton)
    canvas.push(pauseButton)
    canvas.push(backArrow)
    canvas.push(minLayer)
    canvas.push(secLayer)
    if(onMobile){
        canvas.push(noMobileBorder)
        canvas.push(noMobileText)
    }

    var coverLayer
    var coverBorder
    var expandingInfoboxGif
    loadImageToCanvas(process.env.REACT_APP_CDN_URL + '/artists/' + artistFormatted + '/' + projectFormatted + "/cover.png", canvas, (layer)=>{
        coverLayer = layer.centerY(rows)
        layer.setTruePosition([layer.truePos[0], cols - 84 - Math.round((cols / 2) - (288 / 2))])
        if(onMobile){
            layer.setTruePosition([20, 1])
            layer.centerX(cols)
        }
        layer.setNoShift(true)
        if(!onMobile)
            layer.absoluteRenderBounds = [[30, rows], [0, cols]]
        layer.setClickEvent(() => {
            // expandingInfoboxGif.play()
        })
        var layerColors = clone2dArray(layer.colors)
        var layerText = clone2dArray(layer.text)
        var decayIdxs = []
        layer.setPrerender(() => {
            decayIdxs = antidecay(layer, 600, decayIdxs, layerColors, layerText, () => {
                layer.setPrerender(() => {})
            })
        })
        coverBorder = new RenderLayer(coverLayer.rows + 2, coverLayer.cols + 2, 0)
        coverBorder.drawBorder(coverBorder.rows - 2, coverBorder.cols, 0, '-', '=', '*', project.bgcolor, 'rgb(0, 0, 0, 0)')
        coverBorder.setTruePosition([layer.truePos[0] -1, layer.truePos[1]-1])
        coverBorder.setNoShift(true)
        if(!onMobile)
            coverBorder.absoluteRenderBounds = [[30, rows], [0, cols]]
        canvas.insert(2, coverBorder)

        expandingInfoboxGif = createBorderGif(0, coverBorder.rows, 0, coverBorder.cols + 6, 5, '-', '=', '*', project.bgcolor, project.bg2color, 0)
        expandingInfoboxGif.pause()
        expandingInfoboxGif.setVelocity([0, 0])
        expandingInfoboxGif.setTruePosition(coverBorder.truePos)
        expandingInfoboxGif.setNoShift(true)
        expandingInfoboxGif.absoluteRenderBounds = [[30, rows], [0, cols]]
        expandingInfoboxGif.setFrameBreakpoint(expandingInfoboxGif.layers.length - 1, () => {
            if(!expandingInfoboxGif.reversed){
                expandingInfoboxGif.pause()
            }
        })
        expandingInfoboxGif.setFrameBreakpoint(1, () => {
            if(expandingInfoboxGif.reversed){
                expandingInfoboxGif.pause()
                expandingInfoboxGif.reverse()
                expandingInfoboxGif.setTruePosition([20, cols - 85])
                expandingInfoboxGif.setVelocity([0, -5])
            }
        })
        expandingInfoboxGif.setPrerender(() => {
            if(expandingInfoboxGif.updateNext() && !expandingInfoboxGif.paused){
                expandingInfoboxGif.next()
                moveLayer(expandingInfoboxGif)
            }
        })
        canvas.insert(2,expandingInfoboxGif)
    }, 3)

    function applyKeyBindings(event){
        event.stopPropagation()
        event.preventDefault()
        var key = event.code
        if(key === "Space"){
            if(playing){
                pause()
            }
            else {
                play()
                stylePlayingTrack(playingIndex)
            }
        }
        if(key === "ArrowRight"){
            clearPlaybar()
            var goto = Math.min(getTrackCurrentPosition(getArtistPath()) + 5, getTrackDuration(getArtistPath(), playingIndex))
            seekTrack(getArtistPath(), goto)
            updateTimeRemaining()
            updatePlaybar()
        }
        if(key === "ArrowLeft"){
            clearPlaybar()
            var goto = getTrackCurrentPosition(getArtistPath()) - 5
            if(goto < 0){
                lastArrow.triggerClickEvent()
            }
            seekTrack(getArtistPath(), goto)
            updateTimeRemaining()
            updatePlaybar()
        }
    }
    if(!setBinding){
        setBinding = true
        document.removeEventListener("keydown", applyKeyBindings)
        document.addEventListener("keydown", applyKeyBindings)
    }

    canvas.start()

    return () => {
      canvas.stop()
      stopAlbumPlayback(getArtistPath())
      document.removeEventListener("keydown", applyKeyBindings)
    }
  }, [size])

  return (
      <header className="App-header">
        <canvas id="canvas"></canvas>
      </header>
      
  );
}

export default Tracklist;