import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY, loadImageToCanvas, createBorderGif, clone2dArray, shift} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay, antidecay, forceAntidecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11.js";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import ReactGA from "react-ga4";

let VERSION_NUM="1.2.1"

function Music({ artist }) {
useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send({hitType: "pageview", page: "/music", title: "Music"})
    }, [])
  const navigate = useNavigate();

  var onMobile = false
  var fontsize = 12
  var colFactor = 206/1440
  var rowFactor = 99/779

  if(window.innerWidth < window.innerHeight){
    onMobile = true
    fontsize = 8
    colFactor = 355/1440
    rowFactor = 156/779
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)
  
  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            fontsize = 12
            colFactor = 206/1440
            rowFactor = 99/779
          
            if(window.innerWidth < window.innerHeight){
              onMobile = true
              fontsize = 8
              colFactor = 355/1440
              rowFactor = 156/779
            }
            setSize([window.innerWidth, window.innerHeight]);
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(233, 226, 214)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col, gifspeed){
      var block = jsonName.split("\n")
      if(gifspeed === undefined)
        gifspeed = 0
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), gifspeed)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    function insertBlock(jsonName, layer, color){
        var block = jsonName.split("\n")
        layer.insertAscii(0,0, block, '`', color)
    }

    var collapsing = false

    var canvas = new RenderCanvas(rows, cols, fontsize, 32)

    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], "rgb(75, 75, 75)")

    var artistLayers = []
    var iconLayers = []
    var iconTextLayers = []
    var selectedArtist = undefined
    var previewing = false
    var artistIndex = 0
    var queriedArtists = []
    var currentProjectIndex = 0
    var currentProjectScroll = 0

    function displayArtistPreview(artist){
        if(!onMobile){
            if(!skull1.hidden){
                // explodeSkull(skull1, boneColor)
                // explodeSkull(skull2, smileColors[1])
                skull1.hide()
                skull2.hide()
            }
        }
        clearDrawing()
        previewing = true
        selectedArtist = artist
        populateArtistIcons(artist, false, [24, cols - 82])
    }
    var textFont = "4Max"
    if(onMobile){
        textFont = "Small"
    }
    
    function displayDefaultInfo(){
        // unexplodeSkull(skull1, [-0.5, 0.5])
        // unexplodeSkull(skull2, [1.5, 1])
        if(!onMobile){
            skull1.show()
            skull2.show()
        }
        previewing = false
        iconLayers.forEach((layer) => {
            layer.destroy()
        })
        iconTextLayers.forEach((layer) => {
            layer.destroy()
        })
        iconLayers = []
        iconTextLayers = []
        selectedArtist = undefined
    }
    function getProjectPath(artist, project){
        return '/' + format(artist.name) + "/" + format(project.name)
    }
    var artistTitleLayer = undefined
    function populateArtistIcons(artist, withText, offset){
        iconLayers.forEach((icon) => {
            icon.destroy()
        })
        iconTextLayers.forEach((text) => {
            text.destroy()
        })
        iconTextLayers = []
        iconLayers = []
        currentProjectIndex = 0
        var i = 0
        if(withText){
            populateSocials(artist)
            var font = artist.pfont
            if(onMobile)
                font = textFont
            var artistTitle = makeBlock(textToAscii(artist.name, font), artist.pcolor, 0, 32).centerY(24)
            artistTitleLayer = artistTitle
            artistTitle.setNoShift(true)
            bgLayer.fillColor(rows, cols, [0,0], artist.bgcolor)
            bgLayer.show()
            canvas.push(artistTitle)
        }
        if(artist.projects.length === 0){
            var comingSoon = makeBlock(textToAscii("COMING\n \n SOON", textFont), 'rgb(90, 90, 90)', offset[0] + 20, offset[1] + 14)
            comingSoon.setNoShift(true)
            canvas.push(comingSoon)
            iconLayers.push(comingSoon)
        }
        artist.projects.forEach((project) => {
            var layer = createIcon(project.type)
            if(!withText){
                layer.setTruePosition([offset[0] + (Math.floor(i / 3) * 24), offset[1] + ((i % 3) * 22)])
                layer.setNoShift(true)
                layer.recolor([[boneColor, project.pcolor]])
            }
            else{
                layer.setTruePosition([offset[0] + (i * 16), offset[1]])
                layer.absoluteRenderBounds = [[21, rows -15], [0, cols]]
                layer.recolor([[boneColor, 'rgb(70, 70, 70)']])
            }  
            canvas.push(layer)
            iconLayers.push(layer)
            if(withText){
                if(iconLayers.length === 1)
                    layer.recolor([['rgb(70, 70, 70)', project.pcolor]])
                var name = ""
                var maxLength = Math.floor((26/216)*cols)
                if(project.name.length > maxLength){
                    name = project.name.slice(0, maxLength - 3) + "..."
                }
                else{
                    name = project.name
                }
                var title = makeBlock(textToAscii(name, textFont), boneColor, (offset[0] + (i * 16)) + Math.round(layer.rows / 4), offset[1] + layer.cols - 16, 4)
                title.fullyClickable = true
                title.absoluteRenderBounds = [[21, rows -15], [0, cols]]
                title.setHoverEvent(() => {
                    if(currentProjectIndex !== iconTextLayers.indexOf(title)){
                        iconTextLayers[currentProjectIndex].triggerUnhoverEvent()
                        currentProjectIndex = iconTextLayers.indexOf(title)
                    }
                    currentProjectScroll = 0
                    layer.recolor([['rgb(70, 70, 70)', project.pcolor]])
                })
                title.setUnhoverEvent(() => {
                    currentProjectScroll = 0
                    name = project.name
                    if(name.length > maxLength){
                        name = project.name.slice(currentProjectScroll, maxLength - 3 + currentProjectScroll) + "..."
                        title.clearText()
                        title.fillColor(title.rows, title.cols, [0,0], 'rgb(0, 0, 0, 0)')
                        title.insertAscii(0, 0, textToAscii(name, textFont).split('\n'), '`', boneColor)
                    }
                    layer.recolor([[project.pcolor, 'rgb(70, 70, 70)']])
                })
                title.setClickEvent(() => {
                    navigate('/music' + getProjectPath(artist, project))
                })
                title.setPrerender(() => {
                    if(currentProjectIndex === iconTextLayers.indexOf(title)){
                        if(title.updateNext()){
                            if(project.name.length > maxLength){
                                name = project.name.slice(currentProjectScroll, maxLength - 3 + currentProjectScroll)
                                if(currentProjectScroll + maxLength - 3 < project.name.length){
                                    console.log(currentProjectScroll)
                                    name += "..."
                                    currentProjectScroll += 1
                                } 
                                else{
                                    currentProjectScroll = 0
                                }
                                title.clearText()
                                title.fillColor(title.rows, title.cols, [0,0], 'rgb(0, 0, 0, 0)')
                                title.insertAscii(0, 0, textToAscii(name, textFont).split('\n'), '`', boneColor)
                            }
                        }
                    }
                    // else{
                    //     if(project.name.length > maxLength && currentProjectScroll !== 0){
                    //         currentProjectScroll = 0
                    //         name = project.name.slice(currentProjectScroll, maxLength - 3 + currentProjectScroll) + "..."
                    //         title.clearText()
                    //         title.fillColor(title.rows, title.cols, [0,0], 'rgb(0, 0, 0, 0)')
                    //         title.insertAscii(0, 0, textToAscii(name, textFont).split('\n'), '`', boneColor)
                    //         title.triggerUnhoverEvent()
                    //     }
                    // }
                })
                canvas.push(title)
                iconTextLayers.push(title)
            }
            i++
        })
    }
    function createIcon(type){
        switch(type) {
            case 'LP':
                var layer = makeBlock(ascii.musicNote1, boneColor, 0, 0)
                return layer
            case 'URL':
                var layer = makeBlock(textToAscii(". . .", "4Max"), boneColor, 0, 0)
                return layer
            default:
                return undefined
        }
    }
    function hideSocials(){
        socialLayers.forEach((layer) => {
            layer.destroy()
        })
        socialLayers = []
    }
    var socialLayers = []
    function populateSocials(artist){
        hideSocials()
        socialLayers = []
        var i = 0
        artist.links.forEach((social) => {
            var col = cols - 10
            if(i > 0){
                col = socialLayers[i - 1].truePos[1]
            }
            var color = i % 2 == 0 ? artist.pcolor : boneColor
            var layer = makeBlock(textToAscii(social.name, "Mini"), color, rows - 8, 0)
            layer.setTruePosition([layer.truePos[0], col - layer.cols])
            layer.fullyClickable = true
            layer.setNoShift(true)
            layer.setClickEvent(() => {
                window.open(social.link)
            })
            layer.setHoverEvent(() => {
                layer.recolor([[boneColor, artist.scolor], [artist.pcolor, artist.scolor]])
            })
            layer.setUnhoverEvent(() => {
                layer.recolor([[artist.scolor, color]])
            })
            i++
            socialLayers.push(layer)
            canvas.push(layer)
        })
    }
    function populateArtists(query){
        selectedArtist = undefined
        artistIndex = 0
        artistLayers.forEach((layer) => {
            layer.destroy()
        })
        artistLayers = []
        queriedArtists = []
        var i = 0
        musicjson.artists.forEach((artist) => {
            if(!artist.name.toLowerCase().includes(query.toLowerCase())){
                return
            }
            var color = boneColor
            if(artist.projects.length === 0)
                color = "rgb(140, 140, 140)"
            var artistLayer = makeBlock(textToAscii(artist.name, textFont), color, 24 + i * (10), 10)
            artistLayer.fullyClickable = true
            artistLayer.absoluteRenderBounds = [[20, rows], [0, cols]]
            artistLayer.setClickEvent(() => {
                if(artist.projects.length === 0)
                    return
                if(artist.projects.length === 1 && artist.projects[0].link !== undefined){
                    window.open(artist.projects[0].link)
                    return
                }
                selectedArtist = artist
                collapsing = true
                highlightBorder.hide()
                expandingInfoboxGif.play()
                if(!onMobile){
                    skull1.hide()
                    skull2.hide()
                }
                homelink.hide()
                searchBar.hide()
                if(searchIcon !== undefined){
                    searchIcon.hide()
                }
                clearDrawing()
                drawingCanvas.hide()
                artistLayers.forEach((layer) => {
                    var decayIdxs = []
                    layer.setPrerender(() => {
                        decayIdxs = decay(layer, 15, 'rgb(0, 0, 0, 0)', decayIdxs, () => {
                            layer.hide()
                            layer.setPrerender(() => {})
                        })
                    })
                })
                iconLayers.forEach((layer) => {
                    layer.hide()
                })
                searchCharLayers.forEach((layer) => {
                    layer.destroy()
                })
                searchCharLayers = []
                searchChars = []
                searchIndex = 0
                if(onMobile){
                    backArrow.show()
                    collapsing = false
                    populateArtistIcons(selectedArtist, true, [24,15])
                }
            })
            artistLayer.setHoverEvent(() => {
                if(collapsing || !expandingInfoboxGif.paused || !backArrow.hidden){
                    return
                }
                if(!onMobile){
                    displayArtistPreview(artist)
                }
                artistLayers[artistIndex].recolor([[musicjson.artists[artistIndex].pcolor, boneColor]])
                artistIndex = queriedArtists.indexOf(artist)
                artistLayer.recolor([[boneColor, artist.pcolor]])
                highlightBorder.setTruePosition([artistLayer.truePos[0] - 4, artistLayer.truePos[1] - 4])
                highlightBorder.recolor([[currHighlighted, artist.scolor], [defHighlighted, artist.scolor]])
                currHighlighted = artist.scolor
                if(highlightBorder.hidden){
                    var interval = setInterval(() => {
                        if(highlightBorder.hidden){
                            clearInterval(interval)
                        }
                        highlightBorder.recolor([[defHighlighted, artist.scolor], [artist.scolor, defHighlighted]])
                    }, 500)
                }
                highlightBorder.show()
            })
            artistLayer.setUnhoverEvent(() => {
                if(artistLayer.focused || collapsing){
                    return
                }
                displayDefaultInfo()
                artistLayer.recolor([[artist.pcolor, boneColor]])
                highlightBorder.hide()
            })
            queriedArtists.push(artist)
            canvas.push(artistLayer)
            artistLayers.push(artistLayer)
            if(i === 0){
                artistLayers[0].triggerUnhoverEvent()
                artistLayers[0].triggerHoverEvent()
                selectedArtist = queriedArtists[0]
            }
            i++
        })
    }

    var searchCharLayers = []
    var totalSearchLength = 0
    var wrapIndex = 0
    var totalWrapLength = 0
    function addSearchChar(index, char){
        var text = textToAscii(char, "4Max")
        var length = RenderLayer.getMaxLength(text.split("\n"))
        if(index < searchCharLayers.length){
            var subLength = 0
            for(var i = searchIndex; i < searchCharLayers.length; i++){
                var layer = searchCharLayers[i]
                subLength += layer.cols
            }
            var layer = makeBlock(text, boneColor, 8, 12 + totalSearchLength - totalWrapLength - subLength - length)
            layer.setNoShift(true)
            canvas.push(layer)
            searchCharLayers.splice(index, 0, layer)
            for(var i = index; i < searchCharLayers.length; i++){
                var ilayer = searchCharLayers[i]
                ilayer.setTruePosition([ilayer.truePos[0], ilayer.truePos[1] + length])
            }
        }
        else{
            var layer = makeBlock(text, boneColor, 8, 12 + totalSearchLength - totalWrapLength)
            layer.setNoShift(true)
            canvas.push(layer)
            searchCharLayers.push(layer)
        }
        if(totalSearchLength + length >= searchBar.cols){
            searchCharLayers[wrapIndex].hide()
            var wrapLength = searchCharLayers[wrapIndex].cols
            totalWrapLength += wrapLength
            for(var i = wrapIndex + 1; i < searchCharLayers.length; i++){
                searchCharLayers[i].setTruePosition([searchCharLayers[i].truePos[0], searchCharLayers[i].truePos[1] - wrapLength])
            }
            wrapIndex++
        }
        totalSearchLength += length
    }
    function removeSearchChar(index){
        console.log(searchChars)
        var layer = searchCharLayers[index]
        var length = layer.cols
        totalSearchLength -= length
        var flyEvent = Math.random() >= 0
        layer.destroy()
        if(flyEvent){
            var flyer = makeBlock(textToAscii(searchChars[index], "4Max"), boneColor, 8, layer.truePos[1])
            flyer.setVelocity([-1, 0])
            flyer.setPrerender(() => {
                moveLayer(flyer)
            })
            canvas.push(flyer)
        }
        searchCharLayers.splice(index, 1)
        for(var i = index; i < searchCharLayers.length; i++){
            searchCharLayers[i].setTruePosition([searchCharLayers[i].truePos[0], searchCharLayers[i].truePos[1] - length])
        }
        if(wrapIndex > 0){
            wrapIndex--
            searchCharLayers[wrapIndex].show()
            var wrapLength = searchCharLayers[wrapIndex].cols
            totalWrapLength -= wrapLength
            for(var i = wrapIndex + 1; i < searchCharLayers.length; i++){
                searchCharLayers[i].setTruePosition([searchCharLayers[i].truePos[0], searchCharLayers[i].truePos[1] + wrapLength])
            }
        }
    }
    function updateIndexBar(){
        var subLength = 0
        for(var i = searchIndex; i < searchCharLayers.length; i++){
            var layer = searchCharLayers[i]
            subLength += layer.cols
        }
        indexBar.setTruePosition([indexBar.truePos[0], 12 + totalSearchLength - totalWrapLength - subLength])
    }
    var searchChars = []
    var searchInitiated = false
    var searchIndex = 0
    var indexBar = makeBlock(textToAscii("|", "4Max"), "rgb(255, 255, 255)", 8, 12)
    indexBar.setNoShift(true)
    indexBar.hide()
    var searchBar = new RenderLayer(10, cols - 20, 0)
    searchBar.setNoShift(true)
    searchBar.centerX(cols).setTruePosition([5, searchBar.truePos[1]])
    searchBar.fillColor(searchBar.rows, searchBar.cols, [0,0], "rgb(255, 125, 125)")
    var searchBarText = clone2dArray(searchBar.text)
    var searchBarColors = clone2dArray(searchBar.colors)
    var indexBarInterval = null
    searchBar.setClickEvent(() => {
        if(!searchInitiated){
            searchChars = []
            searchInitiated = true
        }
        if(searchIcon !== undefined)
            searchIcon.hide()
        indexBar.show()
        indexBarInterval = setInterval(() => {
            if(collapsing || searchBar.hidden || !searchBar.focused){
                clearInterval(indexBarInterval)
                indexBar.hide()
                return
            }
            else{
                if(indexBar.hidden){
                    indexBar.show()
                }
                else{
                    indexBar.hide()
                }
            }
        }, 500)
        searchBar.setPrerender(() => {
            if(!searchBar.focused){
                clearInterval(indexBarInterval)
                if(searchChars.length == 0 && !collapsing){
                    searchIcon.show()
                }
                indexBar.hide()
                searchBar.setPrerender(() => {})
            }
        })
    })
    
    function createSkull(gifspeed, color, velocity){
        var skullGif = new RenderGif(gifspeed)
        skullGif.pushSheet(ascii.skullFrames, '`', color, [0,0])
        skullGif.setTruePosition([40, cols - 80])
        skullGif.goTo(Math.round(Math.random() * (skullGif.layers.length - 1)))
        skullGif.setVelocity(velocity)
        skullGif.absoluteRenderBounds = [[21, rows - 11], [cols - 84, cols - 12]]
        skullGif.setPrerender(() => {
            bounceMoveLayer(skullGif, [[20, rows - 10],[cols - 98, cols + 22]])
            if(skullGif.updateNext()){
                skullGif.next()
            }
        })
        skullGif.setNoShift(true)
        canvas.push(skullGif)
        return skullGif
    }

    function unexplodeSkull(skull, velocity){
        var decayIdxs = []
        const skullText = clone2dArray(skull.layers[0].text)
        const skullColors = clone2dArray(skull.layers[0].colors)
        skull.pause()
        skull.show()
        skull.goTo(0)
        skull.setVelocity([0,0])
        skull.setPrerender(() => {})
        skull.setComputedDiff((diffMap) => {
            if(!previewing){
                decayIdxs = forceAntidecay(diffMap, skull, 4, decayIdxs, skullColors, skullText, () => {
                    skull.setComputedDiff(undefined)
                    skull.play()
                    skull.show()
                    skull.setVelocity(velocity)
                    skull.setPrerender(() => {
                        bounceMoveLayer(skull, [[20, rows - 10],[cols - 98, cols + 22]])
                        if(skull.updateNext()){
                            skull.next()
                        }
                    })
                })
            }
            else{
                skull.show()
                skull.hide()
            }
        })
    }
    function explodeSkull(skull, color){
        const explode = new RenderGif(0)
        explode.pushSheet(ascii.explodeSkull, '`', color, [skull.truePos[0], skull.truePos[1] - 2])
        explode.play()
        explode.setFrameBreakpoint(14, () => {
          explode.pause()
          var decayIdxs = []
          explode.setComputedDiff((diffMap) => {
            if(decayIdxs !== null){
              decayIdxs = forceDecay(diffMap, explode, 70, "rgb(0, 0, 0, 0)", decayIdxs, () => {
                decayIdxs = null
                explode.destroy()
                explode.setComputedDiff(() => {})
              })
            }
          })
        })
        explode.setPrerender(() => {
          if(explode.updateNext()){
            explode.next()
          }
        })
        canvas.push(explode)
        skull.hide()
    }
    
    baseLayer.insertAscii(0, 18 + cols - 216, ascii.ribs.split("\n"), '`',  "rgb(70, 70, 70)")
    baseLayer.insertAscii(ascii.ribs.split('\n').length, 18 + cols - 216, ascii.ribs.split("\n"), '`',  "rgb(70, 70, 70)")

    var brainBlock = ascii.brain.split("\n")
    var brainLayer = new RenderLayer(brainBlock.length, RenderLayer.getMaxLength(brainBlock), 0)
    brainLayer.insertAscii(0,0, brainBlock, ' ', 'rgb(60, 50, 50)')
    brainLayer.setTruePosition([rows - 30, -40])
    brainLayer.shiftFactor = 0.4
    //reflect this one

    var currHighlighted = "rgb(255, 255, 255)"
    var defHighlighted = "rgb(255, 255, 255)"
    var highlightBorder = new RenderLayer(12, 120, 10)
    highlightBorder.drawBorder(highlightBorder.rows - 2, highlightBorder.cols, 0, "^", "v", "o", currHighlighted)
    highlightBorder.setTruePosition([20, 5])
    highlightBorder.hide()
    highlightBorder.absoluteRenderBounds = [[20, rows], [0, cols]]

    function clearDrawing(){
        drawingCanvas.fillColor(rows - 32, 73, [0,0], 'rgb(20, 20, 20)')
    }
    var drawingCanvas = new RenderLayer(rows - 32, 73, 0)
    clearDrawing()
    drawingCanvas.setTruePosition([21, cols - 84])
    drawingCanvas.setNoShift(true)
    drawingCanvas.setHoverEvent((row, col) => {
        if(row > 0 && col > 0 && row < rows - 32 && col < 73)
            drawingCanvas.fillColor(2,2, [row - 1, col - 1], 'rgb(0, 220, 20)')
    })
    drawingCanvas.setClickEvent(() => {
        clearDrawing()
    })
    var expandingInfoboxGif = createBorderGif(rows - 30, rows - 30, 75, cols - 20, 7, '+', '-', '*', boneColor, 'rgb(20, 20, 20)', 0)
    expandingInfoboxGif.pause()
    expandingInfoboxGif.setVelocity([0, -7])
    expandingInfoboxGif.setTruePosition([20, cols - 85])
    expandingInfoboxGif.setNoShift(true)
    expandingInfoboxGif.setFrameBreakpoint(expandingInfoboxGif.layers.length - 1, () => {
        clearInterval(indexBarInterval)
        if(!expandingInfoboxGif.reversed){
            expandingInfoboxGif.goTo(expandingInfoboxGif.layers.length - 1)
            expandingInfoboxGif.pause()
            backArrow.show()
            collapsing = false
            populateArtistIcons(selectedArtist, true, [24, 30])
        }
    })
    let showDefaultPage = () => {
        populateArtists('')
        drawingCanvas.show()
        homelink.show()
        var decayIdxs = []
        searchBar.show()
        searchBar.setPrerender(() => {
            decayIdxs = antidecay(searchBar, 80, decayIdxs, searchBarColors, searchBarText, () => {
                searchIcon.show()
                searchBar.setPrerender(() => {})
            })
        })
        expandingInfoboxGif.setVelocity([0, -7])
        skull1.show()
        skull2.show()
    }
    expandingInfoboxGif.setPrerender(() => {
        if(expandingInfoboxGif.updateNext() && !expandingInfoboxGif.paused){
            expandingInfoboxGif.next()
            moveLayer(expandingInfoboxGif)
        }
    })

    var backArrow = makeBlock(textToAscii("<--", textFont), "rgb(255, 125, 125)", 8, 4)
    backArrow.hide()
    backArrow.setNoShift(true)
    backArrow.fullyClickable = true
    backArrow.setHoverEvent(() => {
        backArrow.recolor([["rgb(255, 125, 125)", "rgb(255, 255, 255)"]])
    })
    backArrow.setUnhoverEvent(() => {
        backArrow.recolor([["rgb(255, 255, 255)", "rgb(255, 125, 125)"]])
    })
    backArrow.setClickEvent(() => {
        if(!onMobile){
            expandingInfoboxGif.goTo(0)
            expandingInfoboxGif.setTruePosition([20, cols - 85])
            clearInterval(indexBarInterval)
            showDefaultPage()
        }
        else{
            populateArtists('')
            homelink.show()
        }
        hideSocials()
        bgLayer.hide()
        backArrow.hide()
        iconLayers.forEach((layer) => {
            layer.destroy()
        })
        iconTextLayers.forEach((layer) => {
            layer.destroy()
        })
        iconLayers = []
        iconTextLayers = []
        if(artistTitleLayer !== undefined)
            artistTitleLayer.destroy()
        artistTitleLayer = undefined
    })

    var bgLayer = new RenderLayer(rows, cols, 0)
    bgLayer.setNoShift(true)
    bgLayer.hide()

    var homelink = makeBlock(textToAscii("WWW.ORGANREGISTRY.ORG", "Mini"), linkColor, 1, 0)
    homelink.setTruePosition([homelink.truePos[0], homelink.truePos[1] + cols - homelink.cols - 10])
    if(onMobile)
        homelink.centerX(cols)
    homelink.setNoShift(true)
    homelink.fullyClickable = true
    homelink.setHoverEvent(() => {
        homelink.recolor([[linkColor, smileColors[1]]])
    })
    homelink.setUnhoverEvent(() => {
        homelink.recolor([[smileColors[1], linkColor]])
    })
    homelink.setClickEvent(() => {
        navigate('/')
    })

    var skull1, skull2
    canvas.push(baseLayer)
    canvas.push(bgLayer)
    if(!onMobile){
        canvas.push(brainLayer)
        canvas.push(highlightBorder)
        canvas.push(expandingInfoboxGif)
        canvas.push(drawingCanvas)
        skull1 = createSkull(2, boneColor, [-0.5, 0.5])
        skull2 = createSkull(0, smileColors[1], [1, -1.5])
    }
    populateArtists(searchChars.join())
    if(!onMobile){
        canvas.push(searchBar)
        canvas.push(indexBar)
    }
    canvas.push(backArrow)
    canvas.push(homelink)

    var searchIcon
    loadImageToCanvas(process.env.PUBLIC_URL + '/img/searchicon.png', canvas, (layer)=>{
        searchIcon = layer
        layer.setTruePosition([6, 12])
        layer.setNoShift(true)
        layer.hide()
    })

    if(artist !== undefined){
        if(!onMobile){
            skull1.hide()
            skull2.hide()
        }
        var i = 0
        musicjson.artists.forEach((temp) => {
            if(format(temp.name) === format(artist.name)){
                selectedArtist = artist
                if(!onMobile){
                    skull1.hide()
                    skull2.hide()
                }
                homelink.hide()
                searchBar.hide()
                if(searchIcon !== undefined){
                    searchIcon.hide()
                }
                clearDrawing()
                drawingCanvas.hide()
                artistLayers.forEach((layer) => {
                    layer.hide()
                })
                iconLayers.forEach((layer) => {
                    layer.hide()
                })
                searchCharLayers.forEach((layer) => {
                    layer.destroy()
                })
                searchCharLayers = []
                searchChars = []
                searchIndex = 0
                backArrow.show()
                collapsing = false
                clearInterval(indexBarInterval)
                expandingInfoboxGif.pause()
                expandingInfoboxGif.goTo(expandingInfoboxGif.layers.length - 1)
                expandingInfoboxGif.setTruePosition([expandingInfoboxGif.truePos[0], 23])
                if(onMobile){
                    populateArtistIcons(selectedArtist, true, [24,15])
                }
                else
                    populateArtistIcons(selectedArtist, true, [24, 30])
                highlightBorder.hide()
                return
            }
            i++
        })
    }

    canvas.start()
    if(backArrow.hidden){
        artistLayers[0].triggerHoverEvent()
        selectedArtist = queriedArtists[0]
    }
    function applyKeyBindings(event){
        event.preventDefault()
        var key = event.code
        if(key === "ArrowDown"){
            if(backArrow.hidden){
                if(artistLayers.length === 0)
                    return
                artistLayers[artistIndex].triggerUnhoverEvent()
                if(artistIndex !== queriedArtists.length - 1)
                    shift(0, -10)
                artistIndex = Math.min(queriedArtists.length - 1, artistIndex + 1)
                artistLayers[artistIndex].triggerHoverEvent()
                selectedArtist = queriedArtists[artistIndex]
                skull1.hide()
                skull2.hide()
            }
            else{
                iconTextLayers[Math.min(iconTextLayers.length - 1, currentProjectIndex + 1)].triggerHoverEvent()
            }
        }
        else if(key === "ArrowUp"){
            if(backArrow.hidden){
                if(artistLayers.length === 0)
                    return
                artistLayers[artistIndex].triggerUnhoverEvent()
                if(artistIndex !== 0)
                    shift(0, 10)
                artistIndex = Math.max(0, artistIndex - 1)
                artistLayers[artistIndex].triggerHoverEvent()
                selectedArtist = queriedArtists[artistIndex]
                skull1.hide()
                skull2.hide()
            }
            else{
                iconTextLayers[Math.max(0, currentProjectIndex - 1)].triggerHoverEvent()
            }
        }
        if(searchBar.focused){
            if(key === "Enter"){
                //do smth
                searchBar.focused = false
                return
            }
            if(key === "Backspace"){
                if(searchIndex == 0){
                    return
                }
                removeSearchChar(searchIndex - 1)
                searchChars.pop()
                searchIndex--
                updateIndexBar()
                populateArtists(searchChars.join().replaceAll(",",""))
                return
            }
            if(key === "Tab"){
                searchIndex = searchChars.length
                updateIndexBar()
                return
            }
            if(key === "ArrowLeft"){
                searchIndex = Math.max(0, searchIndex - 1)
                updateIndexBar()
                return
            }
            if(key === "ArrowRight"){
                searchIndex = Math.min(searchChars.length, searchIndex + 1)
                updateIndexBar()
                return
            }
            else if(!key.includes("Digit") && !key.includes("Key") && key !== "Space"){
                return
            }
            key = key.replace("Key", "").replace("Digit", "").replace("Space", " ")
            searchChars.push(key)
            addSearchChar(searchIndex, key)
            searchIndex++
            updateIndexBar()
            populateArtists(searchChars.join().replaceAll(",",""))
        }
        else{
            if(key === "ArrowRight" || key === "Space" || key === "Enter"){
                if(backArrow.hidden){
                    if(!highlightBorder.hidden && artistLayers.length > 0){
                        artistLayers[artistIndex].triggerClickEvent()
                    }
                }
                else{
                    iconTextLayers[currentProjectIndex].triggerClickEvent()
                }
            }
            else if(key === "ArrowLeft" || key === "Backspace"){
                if(!backArrow.hidden){
                    backArrow.triggerClickEvent()
                }
            }
        }
    }
    document.removeEventListener("keydown", applyKeyBindings)
    document.addEventListener("keydown", applyKeyBindings)
    
    return () => {
      canvas.stop()
      clearInterval(indexBarInterval)
      document.removeEventListener("keydown", applyKeyBindings)
    }
  }, [size])

  return (
      <header className="App-header">
        <canvas id="canvas"></canvas>
      </header>
      
  );
}

export default Music;