import logo from '../logo.svg';
import '../Flyer.css'
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import pagejson from './ascii/ascii_portraits.json'
import writingjson from './writing.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import Webcam from 'react-webcam'
import {display} from '../scripts/WebcamManager.js'
import Color from 'color'
import ReactGA from "react-ga4";

let VERSION_NUM="1.0.0"

function Flyer() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send({hitType: "pageview", page: "/live/launch", title: "Launch Flyer"})
  }, [])
  const navigate = useNavigate();

  var fontsize = 12
  var colFactor = 216/1440
  var rowFactor = 300/779

  var onMobile = false
  if(window.innerWidth < window.innerHeight){
    onMobile = true
    fontsize = 9
    colFactor = 288/1440
    rowFactor = 300/779
  }

  var flyerStyle
  if(!onMobile){
      flyerStyle = {
          width:"50%",
        }
   }
  else{
      flyerStyle = {
          width:"90%"
      }
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)

  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 12
            colFactor = 216/1440
            rowFactor = 300/779

            if(window.innerWidth < window.innerHeight){
                fontsize = 9
                colFactor = 288/1440
                rowFactor = 300/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(255, 238, 207)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"
  var phoneColors = ["rgb(140, 140, 140)", smileColors[0], conwaysColors[2]]

  const webcamRef = useRef(null)
  const [usingWebcam, setUsingWebcam] = useState(false)

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col){
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    var canvas = new RenderCanvas(rows, cols, fontsize, 32)
    var stretchFactor = 2.7
    if(onMobile){
        stretchFactor = 1.8
    }
    canvas.gfxCanvas.height = window.innerHeight * stretchFactor
    canvas.offscreenCanvas.height = window.innerHeight * stretchFactor
    canvas.noShift = true

    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], "rgb(20, 50, 120)")

    var backArrow = makeBlock(textToAscii("<--", '4Max'), boneColor, 5, 5)
    if(onMobile)
        backArrow.setTruePosition([3, 5])
    backArrow.fullyClickable = true

    backArrow.setHoverEvent(() => {
      backArrow.recolor([[boneColor, linkColor]])
    })
    backArrow.setUnhoverEvent(() => {
      backArrow.recolor([[linkColor, boneColor]])
    })
    backArrow.setClickEvent(() => {
        navigate('/')
    })

    var border 
    if(!onMobile){
        border = new RenderLayer(318, Math.round(cols / 2) + 1, 0)
        border.drawBorder(border.rows - 2, border.cols, 0, '*', '-', '/', 'rgb(255, 0, 0)', 'rgb(0, 0, 0, 0)')
        border.setTruePosition([3, cols / 4 - ((cols / 288) * 7)])
    }
    else{
        border = new RenderLayer(318, 145, 0)
        border.drawBorder(border.rows - 2, cols - 6, 0, '*', '-', '/', 'rgb(255, 0, 0)', 'rgb(0, 0, 0, 0)')
        border.setTruePosition([8, 3])
    }

    canvas.push(baseLayer)
    canvas.push(border)
    canvas.push(backArrow)

    canvas.start()
    return () => {
      canvas.stop()
    }
  }, [size])

    return (
      <>
        <img src={process.env.PUBLIC_URL + "/img/launch_flyer.png"} style={flyerStyle}></img>
        <header className="App-header">
            <canvas id="canvas"></canvas>
        </header>
      </>
    )
}

export default Flyer;