import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import pagejson from './ascii/ascii_mirage.json'
import writingjson from './writing.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import Webcam from 'react-webcam'
import {display} from '../scripts/WebcamManager.js'
import Color from 'color'
import ReactGA from "react-ga4";

let VERSION_NUM="1.0.0"

function Mirage() {
  useEffect(() => {
    ReactGA.initialize("G-CY8MG8SWH2")
    ReactGA.send({hitType: "pageview", page: "/greenery", title: "Greenery"})
  }, [])

  const navigate = useNavigate();

  var fontsize = 12
  var colFactor = 206/1440
  var rowFactor = 99/779

  if(window.innerWidth < 550){
    fontsize = 7
    colFactor = 370/1440
    rowFactor = 168/779
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)

  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 12
            colFactor = 216/1440
            rowFactor = 99/779

            if(window.innerWidth < 550){
              fontsize = 7
              colFactor = 370/1440
              rowFactor = 168/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(255, 238, 207)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)", "rgb(230, 215, 255)", "rgb(238, 197, 132)", "rgb(176, 123, 172)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"
  var phoneColors = ["rgb(140, 140, 140)", smileColors[0], conwaysColors[2]]

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col){
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    var canvas = new RenderCanvas(rows, cols, fontsize, 32)
    canvas.noShift = true

    const bgColor = "rgb(255, 0, 0)"
    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], bgColor)
    // baseLayer.insertAscii(0, 0, pagejson.desert.split("\n"), '`', 'rgb(230, 218, 176)')
    // baseLayer.fillColor(50, 189, [116, 104], 'rgb(60, 140, 60)')
    // baseLayer.insertAscii(10, -60, pagejson.lilac.split("\n"), '`', 'rgb(230, 215, 255)')
    // baseLayer.insertAscii(30, 0, pagejson.gmist.split('\n'), '`', 'rgb(246, 244, 241)')

    // canvas.noShift = true

    var sandGif1 = new RenderGif(5)
    sandGif1.pushSheet(pagejson.desert, '`', 'rgb(60, 60, 60)', [0,0])
    sandGif1.setTruePosition([rows - 70, -1])
    sandGif1.play()
    sandGif1.reverse()
    sandGif1.setPrerender(() => {
        if(sandGif1.updateNext())
            sandGif1.next()
    })

    var sandGif2 = new RenderGif(10)
    sandGif2.pushSheet(pagejson.desert, '`', 'rgb(0, 0, 0)', [0,0])
    sandGif2.setTruePosition([rows - 50, 0])
    sandGif2.play()
    sandGif2.setPrerender(() => {
        if(sandGif2.updateNext())
            sandGif2.next()
    })

    var sandGif3 = new RenderGif(15)
    sandGif3.pushSheet(pagejson.desert, '`', 'rgb(150, 150, 150)', [0,0])
    sandGif3.setTruePosition([rows - 90, -1])
    sandGif3.play()
    sandGif3.setPrerender(() => {
        if(sandGif3.updateNext())
            sandGif3.next()
    })

    var backArrow = makeBlock(textToAscii("<--", '4Max'), boneColor, 5, 5)
    backArrow.fullyClickable = true
    backArrow.setNoShift(true)
    backArrow.setHoverEvent(() => {
      backArrow.recolor([[boneColor, linkColor]])
    })
    backArrow.setUnhoverEvent(() => {
      backArrow.recolor([[linkColor, boneColor]])
    })
    backArrow.setClickEvent(() => {
        navigate("/")
    })

    var poemLayer1 = new RenderLayer(50, 80, 0)
    poemLayer1.insertTextBlockSkipRows(0, 0, 4, writingjson.hope_springs_not.split('\n'), 'rgb(255, 255, 255)')
    poemLayer1.setTruePosition([rows-34, 2])

    var conwaysLayer1 = new RenderLayer(50, cols, 2)
    var conwaysLayer2 = new RenderLayer(50, cols, 8)

    randomNoise([0, conwaysLayer1.rows - 1], [0, conwaysLayer1.cols - 1], conwaysLayer1, 'rgb(150, 150, 150)', 6)
    randomNoise([0, conwaysLayer2.rows - 1], [0, conwaysLayer2.cols - 1], conwaysLayer2, 'rgb(255, 120, 120)', 2)
   
    conwaysLayer1.setPrerender(() => {
      if(conwaysLayer1.updateNext()){      
        conwaysLayer1.fillColor(50, cols, [0,0], bgColor)
        randomNoise([0, conwaysLayer1.rows - 1], [0, conwaysLayer1.cols - 1], conwaysLayer1, 'rgb(150, 150, 150)', 6)
      }
    })
    conwaysLayer2.setPrerender(() => {
      if(conwaysLayer2.updateNext()){      
        conwaysLayer2.fillColor(50, cols, [0,0], bgColor)
        randomNoise([0, conwaysLayer2.rows - 1], [0, conwaysLayer2.cols - 1], conwaysLayer2, 'rgb(255, 120, 120)', 2)
      }
    })
    // conwaysLayer1.setComputedDiff((diffMap) => {
    //     if(conwaysLayer1.updateNext())
    //       conways(diffMap, conwaysLayer1, 'rgb(150, 150, 150)')
    //   })

    var text = makeBlock(textToAscii("MIRAGE", 'Roman'), 'rgb(255, 255, 255)', 8, -78)

    var poem1 = makeBlock(textToAscii(writingjson.mirage.split('\n')[0], 'Roman'), 'rgb(60, 60, 60)', rows - 12, 0)
    var poem2 = makeBlock(textToAscii(writingjson.mirage.split('\n')[1], 'Roman'), 'rgb(150, 150, 150)', rows - 10, 20)

    canvas.push(baseLayer)
    canvas.push(conwaysLayer1)
    canvas.push(conwaysLayer2)
    canvas.push(text)
    canvas.push(sandGif3)
    canvas.push(sandGif1)
    canvas.push(sandGif2)
    // canvas.push(backArrow)
    canvas.push(poem2)
    canvas.push(poem1)
    
    var textIdx = 0
    var modMax = 6
    function applyKeyBindings(event){
      event.preventDefault()
      var key = event.code
      if(key === "Space"){
        document.body.requestFullscreen()
      }
      if(key === "ArrowRight"){
        if(textIdx < modMax - 1)
          text.setTruePosition([8, text.truePos[1] + ((cols + 78) / modMax)])
        else{
          text.setTruePosition([8, -78])
          text.recolor([['rgb(255, 255, 255)', 'rgb(255, 120, 120)'], ['rgb(255, 120, 120)', 'rgb(255, 0, 0)'], ['rgb(255, 0, 0)', 'rgb(255, 255, 255)']])
        }
        textIdx = (textIdx + 1) % modMax
      }
    }
    document.removeEventListener("keydown", applyKeyBindings)
    document.addEventListener("keydown", applyKeyBindings)

    canvas.start()
    return () => {
      canvas.stop()
    }
  }, [size])

    return (
        <header className="App-header">
            <canvas id="canvas"></canvas>
        </header>
    )
}

export default Mirage;