import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import pagejson from './ascii/ascii_portraits.json'
import writingjson from './writing.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import Webcam from 'react-webcam'
import {display} from '../scripts/WebcamManager.js'
import Color from 'color'
import ReactGA from "react-ga4";

let VERSION_NUM="1.0.0"

function Portraits() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send({hitType: "pageview", page: "/portraits", title: "Portraits"})
  }, [])
  const navigate = useNavigate();

  var fontsize = 12
  var colFactor = 206/1440
  var rowFactor = 99/779

  if(window.innerWidth < 550){
    fontsize = 4
    colFactor = 644/1440
    rowFactor = 294/779
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)

  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 12
            colFactor = 216/1440
            rowFactor = 99/779

            if(window.innerWidth < 550){
                fontsize = 4
                colFactor = 644/1440
                rowFactor = 294/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(255, 238, 207)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"
  var phoneColors = ["rgb(140, 140, 140)", smileColors[0], conwaysColors[2]]

  const webcamRef = useRef(null)
  const [usingWebcam, setUsingWebcam] = useState(false)

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col){
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    const offscreen = new OffscreenCanvas(cols,rows)
    const offCtx = offscreen.getContext('2d')
    offCtx.scale((147)/1280,(40)/720)

    var canvas = new RenderCanvas(rows, cols, fontsize, 32)

    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], "rgb(20, 50, 120)")
    // baseLayer.insertAscii(0, -4, pagejson.rug.split('\n'), '`', 'rgb(20, 50, 120)')

    var frameLayer = makeBlock(pagejson.frame, 'rgb(150, 170, 90)', 0, 0).centerX(cols).centerY(rows)
    frameLayer.setNoShift(true)

    var webcamCallback = (color) => {
        return color.desaturate(0.4)
    }
    var webcamLayer = new RenderLayer(50, 150, 0)
    webcamLayer.centerX(cols).centerY(rows)
    webcamLayer.setPrerender(() => {
      display(webcamRef, offscreen, offCtx, webcamLayer, true, webcamCallback)
    })
    // webcamLayer.hide()
    canvas.noShift = true

    var middlefingerGif = new RenderGif(0)
    middlefingerGif.pushSheet(ascii.middlefinger, '`', "rgb(255, 203, 164)", [0, 0])
    middlefingerGif.setTruePosition([40, 0])
    // only play the gif on at random times between 5 and 15 seconds
    middlefingerGif.goTo(0)
    middlefingerGif.pause()
    middlefingerGif.hide()
    middlefingerGif.setNoShift(true)
    middlefingerGif.setFrameBreakpoint(middlefingerGif.layers.length - 1, () => {
      middlefingerGif.hide()
      middlefingerGif.pause()
      middlefingerGif.goTo(0)
      navigate("/")
    })
    middlefingerGif.setPrerender(() => {
      if(middlefingerGif.updateNext()){
        middlefingerGif.next()
      }
    })

    var backArrow = makeBlock(textToAscii("<--", '4Max'), boneColor, 5, 5)
    backArrow.fullyClickable = true
    backArrow.setNoShift(true)
    backArrow.setHoverEvent(() => {
      backArrow.recolor([[boneColor, linkColor]])
    })
    backArrow.setUnhoverEvent(() => {
      backArrow.recolor([[linkColor, boneColor]])
    })
    backArrow.setClickEvent(() => {
        if(middlefingerGif.paused){
            middlefingerGif.show()
            middlefingerGif.play()
        }
        else{
            navigate('/')
        }
    })

    var poemLayer1 = new RenderLayer(1, 130, 0)
    poemLayer1.insertText(0, 0, writingjson.window_unreality.split('\n')[0], 'rgb(255, 255, 255)')
    poemLayer1.setTruePosition([frameLayer.truePos[0] - 5, 0])
    poemLayer1.setNoShift(true)
    poemLayer1.centerX(cols)
    var poemLayer2 = new RenderLayer(1, 48, 0)
    poemLayer2.insertText(0, 0, writingjson.window_unreality.split('\n')[1], 'rgb(255, 255, 255)')
    poemLayer2.setTruePosition([frameLayer.truePos[0] - 2, poemLayer1.truePos[1] + 24])
    poemLayer2.setNoShift(true)
    var poemLayer3 = new RenderLayer(1, 40, 0)
    poemLayer3.insertText(0, 0, writingjson.window_unreality.split('\n')[2], smileColors[1])
    poemLayer3.setTruePosition([frameLayer.truePos[0] - 2, poemLayer2.truePos[1] + poemLayer2.cols])
    poemLayer3.setNoShift(true)

    canvas.push(baseLayer)
    canvas.push(webcamLayer)
    canvas.push(frameLayer)
    canvas.push(backArrow)
    canvas.push(poemLayer1)
    canvas.push(poemLayer2)
    canvas.push(poemLayer3)
    canvas.push(middlefingerGif)

    canvas.start()
    return () => {
      webcamLayer.setPrerender(() => {})
      canvas.stop()
    }
  }, [size])

    return (
      <>
        <Webcam
              className='camera'
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              disablePictureInPicture={true}
              imageSmoothing={true}
              screenshotQuality={0.8}
              muted={false}
              width={1280}
              height={720}
              mirrored={false}
          />
          <header className="App-header">
              <canvas id="canvas"></canvas>
          </header>
      </>
    )
}

export default Portraits;