import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import writingjson from './writing.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY, clone2dArray} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import Webcam from 'react-webcam'
import {display} from '../scripts/WebcamManager.js'
import ReactGA from "react-ga4";
import { useCookies } from 'react-cookie'
let VERSION_NUM="1.0.4"

function Home() {

  const [cookies, setCookie] = useCookies(['titleShown']);
  useEffect(() => {
    console.log(process.env)
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send({hitType: "pageview", page: "/home", title: "Home"})
  }, [])
  const navigate = useNavigate();

  var fontsize = 12
  var colFactor = 206/1440
  var rowFactor = 99/779

  const NO_MOBILE = "no_mobile"
  const COMING_SOON = "coming_soon"
  var onMobile = false
  if(window.innerWidth < window.innerHeight){
    onMobile = true
    fontsize = 9
    colFactor = 288/1440
    rowFactor = 130/779
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)

  var entered = false
  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 12
            colFactor = 206/1440
            rowFactor = 99/779

            if(window.innerWidth < window.innerHeight){
              onMobile = true
              fontsize = 9
              colFactor = 288/1440
              rowFactor = 130/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(255, 238, 207)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 150, 112)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"
  var phoneColors = ["rgb(140, 140, 140)", smileColors[0], conwaysColors[2]]

  const webcamRef = useRef(null)
  const [usingWebcam, setUsingWebcam] = useState(false)

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col){
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    var canvas = new RenderCanvas(rows, cols, fontsize, 32)

    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], "rgb(75, 75, 75)")

    canvas.noShift = true

    var heartGif = new RenderGif(0)
    heartGif.pushSheet(ascii.realheart, '`', smileColors[1], [0, 0])
    heartGif.setTruePosition([rows - 45, centerX(heartGif, cols) - 8])
    if(onMobile){
      heartGif.centerY(rows)
      heartGif.setTruePosition([heartGif.truePos[0], -50])
    }
    heartGif.setNoShift(true)
    heartGif.pause()
    heartGif.setFrameBreakpoint(heartGif.layers.length - 1, () => {
      heartGif.pause()
    })
    heartGif.setPrerender(() => {
      if(heartGif.updateNext()){
        heartGif.next()
      }
    })

    function getFamilyFromLayer(layer){
      if(layer === linkLayer3)
        return sublinks[0]
      else
        return sublinks[1]
    }
    function showAll(clickedLayer, hideCallback){
      hideCallback()
        clickedLayer.recolor([[linkColor, 'rgb(255, 255, 255)']])
        backArrow.show()
        heartGif.show()
        if(focusedLayer !== undefined)
          focusedLayer.show()
        sublinks.forEach((family) => {
          if(family.includes(clickedLayer)){
            family.forEach((link) => {
              link.show()
            })
          }
        })
        selectedBorder.show()
    }
    function hideAll(clickedLayer, showDelay, showCallback, hideCallback){
      showCallback()
      backArrow.hide()
      heartGif.hide()
      if(focusedLayer !== undefined)
        focusedLayer.hide()
      sublinks.forEach((family) => {
        if(family.includes(clickedLayer)){
          family.forEach((link) => {
            link.hide()
          })
        }
      })
      selectedBorder.hide()
      setTimeout(() => {
        showAll(clickedLayer, hideCallback)
      }, showDelay)
    }
    var focusedLayer = undefined
    var resetPosition = undefined
    function createLink(text, links, row, col){
        var textBlock = textToAscii(text, "4Max")
        var textLayer = makeBlock(textBlock, "rgb(255, 255, 255)", row, col).setNoShift(true)
        if(links.length == 0){
          textLayer.recolor([['rgb(255, 255, 255)', 'rgb(90, 90, 90)']])
        }
        textLayer.fullyClickable = true
        var underlineLayer = new RenderLayer(1, RenderLayer.getMaxLength(textBlock.split("\n")), 0)
        underlineLayer.fillColor(1, RenderLayer.getMaxLength(textBlock.split("\n")), [0, 0], "rgb(0, 0, 255)")
        underlineLayer.setTruePosition([row + 4, col])
        underlineLayer.hide().setNoShift(true)
        textLayer.setClickEvent(() => {
        if(links.length == 1){
            if(links[0].includes("//")){
              window.open(links[0])
            }
            else if(links[0] == COMING_SOON){
              hideAll(textLayer, 5000, () => {
                comingSoonText.show()
                noMobileBorder.show()
                underlineLayer.hide()
                noMobileBorder.setClickEvent(() => {
                  showAll(textLayer, () => {
                    comingSoonText.hide()
                    noMobileBorder.hide()
                  })
                })
              }, () => {
                comingSoonText.hide()
                noMobileBorder.hide()
              })
            }
            else if(links[0] == NO_MOBILE){
              hideAll(textLayer, 5000, () => {
                noMobileText.show()
                noMobileBorder.show()
                underlineLayer.hide()
                noMobileBorder.setClickEvent(() => {
                  showAll(textLayer, () => {
                    noMobileText.hide()
                    noMobileBorder.hide()
                  })
                })
              }, () => {
                noMobileText.hide()
                noMobileBorder.hide()
              })
            }
            else{
              navigate(links[0])
            }
          }
          else if(links.length > 1){
            selectedBorder.fillColor(selectedBorder.rows, selectedBorder.cols, [0,0], 'rgb(0, 0, 0, 0)')
            selectedBorder.clearText()
            selectedBorder.setTruePosition([backArrow.truePos[0] - 4, backArrow.truePos[1] - 4])
            selectedBorder.drawBorder(selectedBorder.rows - 2, textLayer.cols + backArrow.cols + 16, 0, '*', 'o', '/', 'rgb(255, 255, 255)', 'rgb(50, 50, 50)')
            selectedBorder.show()

            resetPosition = textLayer.truePos
            focusedLayer = textLayer
            underlineLayer.hide()
            backArrow.show()
            outermostLinks.forEach((layer) => {
              layer.hide()
            })
            textLayer.show()
            textLayer.recolor([["rgb(0, 0, 255)", "rgb(255, 255, 255)"]])
            textLayer.setTruePosition([rows - 40, cols / 2 - 24])
            if(onMobile)
              textLayer.setTruePosition([(rows / 2) - 20, cols / 2 - 24])
            links.forEach((layer) => {
              layer.show()
            })
          }
        })
        textLayer.setHoverEvent((row, col) => {
          if(textLayer !== focusedLayer){
            textLayer.recolor([["rgb(255, 255, 255)", "rgb(0, 0, 255)"]])
            if(links.length > 0 && !textLayer.hidden)
              underlineLayer.show()
          }
        })
        textLayer.setUnhoverEvent(() => {
          if(textLayer !== focusedLayer){
            textLayer.recolor([["rgb(0, 0, 255)", "rgb(255, 255, 255)"]])
            underlineLayer.hide()
          }
        })
        textLayer.setPrerender(() => {
          if(textLayer.hidden){
            underlineLayer.hide()
          }
        })
        canvas.push(textLayer)
        if(!onMobile)
          canvas.push(underlineLayer)
        return textLayer
    }

    const titleColors = ['rgb(255, 255, 255)', 'rgb(0, 0, 255)', smileColors[0]]
    function getRandomColor(colors){
      return colors[Math.round(Math.random() * (colors.length - 1))]
    }
    var titleLayer 
    if(!onMobile){
      titleLayer = makeBlock(textToAscii("ORGANREGISTRY", "Crazy"), getRandomColor(titleColors), 4, 0).centerX(cols).setNoShift(true)
    }
    else{
      titleLayer = makeBlock(textToAscii("   O   R   G   A   N\n\nR E G I S T R Y", "Small"), linkColor, 4, 0).centerX(cols).setNoShift(true)
    }
    var skullFont = "Georgia11"
    if(onMobile)
      skullFont = "Small"
    var skullMonologue1 = makeBlock(textToAscii("WHAT HAVE \n \nYOU DONE?", skullFont), "rgb(255, 0, 0)", (rows / 2) - 10, (cols / 2) - 60).hide().setNoShift(true).centerX(cols)
    var skullMonologue2 = makeBlock(textToAscii("THE MUSIC OF \n \nTHE SPHERES", skullFont), "rgb(255, 0, 0)", (rows / 2) - 10, (cols / 2) - 70).hide().setNoShift(true).centerX(cols)
    var skullMonologue3 = makeBlock(textToAscii("SNUFFED OUT", skullFont), "rgb(255, 0, 0)", (rows / 2) - 10, (cols / 2) - 70).hide().setNoShift(true).centerX(cols)
    var skullMonologue4 = makeBlock(textToAscii("INTO UNTOLD \nBLACK WHIRRING \nNOTHING", skullFont), "rgb(255, 0, 0)", (rows / 2) - 10, (cols / 2) - 75).hide().setNoShift(true).centerX(cols)
    var skullMonologue5 = makeBlock(textToAscii("AND NOW, \nBY YOUR HAND,", skullFont), "rgb(255, 0, 0)", (rows / 2) - 10, (cols / 2) - 75).hide().setNoShift(true).centerX(cols)
    var skullMonologue6 = makeBlock(textToAscii("SILENCE ABOUNDS...", skullFont), "rgb(0, 0, 255)", (rows / 2) - 10, (cols / 2) - 60).hide().setNoShift(true).centerX(cols)
    var skullMonologue = [skullMonologue1, skullMonologue2, skullMonologue3, skullMonologue4, skullMonologue5, skullMonologue6]

    function showCarousel(layers, delay, callback){
        layers[0].show()
        var i = 0;
        var interval = setInterval(() => {
            if(i == layers.length - 1){
                layers[i].hide()
                clearInterval(interval)
                callback()
            }
            else{
                layers[i].hide()
                layers[i+1].show()
                i++
            }
        }, delay)
    }
    function explodeSkull(skull, color){
        skullCount--
        const explode = new RenderGif(0)
        explode.pushSheet(ascii.explodeSkull, '`', color, [skull.truePos[0], skull.truePos[1] - 2])
        explode.play()
        explode.setFrameBreakpoint(14, () => {
          explode.pause()
          var decayIdxs = []
          explode.setComputedDiff((diffMap) => {
            if(decayIdxs !== null){
              decayIdxs = forceDecay(diffMap, explode, 70, "rgb(0, 0, 0, 0)", decayIdxs, () => {
                decayIdxs = null
                explode.destroy()
                explode.setComputedDiff(() => {})
              })
            }
          })
        })
        explode.setPrerender(() => {
          if(explode.updateNext()){
            explode.next()
          }
        })
        canvas.push(explode)
        skull.destroy()
    }
    function createOrbitingSkull(color, radius, period, start, delay, gifspeed){
        skullCount++
        var skullGif = new RenderGif(gifspeed)
        skullGif.setNoShift(true)
        var skullTheta = 0
        skullGif.pushSheet(ascii.skullFrames, '`', color, start)
        skullGif.goTo(Math.round(Math.random() * (skullGif.layers.length - 1)))
        setTimeout(() => {
            skullGif.setPrerender(() => {
                skullTheta = circularMotion(skullGif, radius, period, skullTheta)
                if(skullGif.updateNext()){
                    skullGif.next()
                }
            })
            skullGif.setClickEvent(() => {
                explodeSkull(skullGif, color)
                if(skullCount == 0){
                    heartGif.hide()
                    linkLayer1.hide()
                    linkLayer2.hide()
                    linkLayer3.hide()
                    phoneGif.hide()
                    middlefingerIcon.hide()
                    sublinks.forEach((family) => {
                      family.forEach((link) => {
                        link.hide()
                      })
                    })
                    selectedBorder.hide()
                    backArrow.hide()
                    showCarousel(skullMonologue, 2000, () => {
                        heartGif.show()
                        phoneGif.show()
                        middlefingerIcon.show()
                        if(focusedLayer !== undefined){
                          selectedBorder.show()
                          focusedLayer.show()
                          backArrow.show()
                          getFamilyFromLayer(focusedLayer).forEach((link) => {
                            link.show()
                          })
                        }
                        else{
                          linkLayer1.show()
                          linkLayer2.show()
                          linkLayer3.show()
                        }
                    })
                }
            })
        }, delay)
        canvas.push(skullGif)
    }
    function initOrbitSkulls(){
      if(!onMobile){
        createOrbitingSkull(boneColor, 0.5, 1, [rows, (cols / 2) - 96], 0, 4)
        createOrbitingSkull(linkColor, 0.5, 1, [rows, (cols / 2) - 96],6000, 4)
        createOrbitingSkull(conwaysColors[0], 0.27, 0.5, [rows, (cols / 2) - 102], 18000, 4)
        // createOrbitingSkull('rgb(125, 0, 200)', 0.27, 0.5, [rows, (cols / 2) - 105], 27000, 4)
        createOrbitingSkull(smileColors[0], 0.27, 0.5, [rows, (cols / 2) - 105], 36000, 3)
        createOrbitingSkull('rgb(20, 20, 20)', 0.27, 0.5, [rows, (cols / 2) - 105], 45000, 3)
      }
      else{
        createOrbitingSkull(boneColor, 0.5, 1, [rows / 2, (cols / 2) - 126], 0, 4)
        createOrbitingSkull(linkColor, 0.5, 1, [rows / 2, (cols / 2) - 126],6000, 4)
        createOrbitingSkull(conwaysColors[0], 0.27, 0.5, [rows / 2, (cols / 2) - 132], 18000, 4)
        // createOrbitingSkull('rgb(125, 0, 200)', 0.27, 0.5, [rows / 2, (cols / 2) - 135], 27000, 4)
        createOrbitingSkull(smileColors[0], 0.27, 0.5, [rows / 2, (cols / 2) - 135], 36000, 3)
        createOrbitingSkull('rgb(20, 20, 20)', 0.27, 0.5, [rows / 2, (cols / 2) - 135], 45000, 3)
      }
    }
    function scatterText(lines, color, rowOffset){
      for(var i = 0; i < lines.length; i++){
        baseLayer.insertText(rowOffset + (i * 2), Math.floor(Math.random() * (cols - lines[i].length - 5)), lines[i], color)
      }
    }
    var phoneColorIdx = 0
    var phoneGif = new RenderGif(0)
    phoneGif.pushSheet(ascii.cellphone, '`', phoneColors[0], [0, 0])
    phoneGif.setTruePosition([20, -400])
    if(onMobile)
      phoneGif.setTruePosition([rows - 40, -400])
    phoneGif.setVelocity([0.3, 1])
    phoneGif.setClickEvent(() => {
      if(phoneGif.computedDiff !== undefined)
        return
      var decayIdxs = []
      //setUsingWebcam(true)
      phoneGif.pause()
      var lastColor = phoneColors[phoneColorIdx]
      phoneColorIdx = (phoneColorIdx + 1) % phoneColors.length
      phoneGif.setComputedDiff((diff) => {
        decayIdxs = forceDecay(diff, phoneGif, 10, phoneColors[phoneColorIdx], decayIdxs, () => {
          phoneGif.setComputedDiff(undefined)
          phoneGif.recolor([[lastColor, phoneColors[phoneColorIdx]]])
          phoneGif.play()
          //webcamLayer.show()
        })
      })
    })
    phoneGif.setPrerender(() => {
      if(phoneGif.updateNext() && !phoneGif.paused){
        phoneGif.next()
        bounceMoveLayer(phoneGif, [[20, 70], [-650, cols + 650]])
      }
    })

    var canCallback = true
    var middleFingerHidden = false
    var middlefingerIcon = makeBlock(ascii.middleFingerIcon, "rgb(255, 203, 164)", -450, 8)
    middlefingerIcon.setTruePosition([middlefingerIcon.truePos[0], cols - middlefingerIcon.cols - 8])
    middlefingerIcon.setVelocity([1.5, 0])
    middlefingerIcon.setNoShift(true)

    const mfingerPrerender = () => {
      if(middlefingerIcon.updateNext()){
        bounceMoveLayer(middlefingerIcon, [[-500, rows + 500], [0, cols]], () => {
          if(middleFingerHidden){
            middlefingerIcon.insertAscii(0, 0, ascii.middleFingerIcon.split("\n"), "`", "rgb(255, 203, 164)")
            middleFingerHidden = false
          }
          if(canCallback){
            if(middlefingerIcon.truePos[1] === 8){
              middlefingerIcon.setTruePosition([middlefingerIcon.truePos[0], cols - middlefingerIcon.cols - 8])
            }
            else{
              middlefingerIcon.setTruePosition([middlefingerIcon.truePos[0], 8])
            }
            canCallback = false
            setTimeout(() => {
              canCallback = true
            }, 2000)
          }
        })
      }
    }
    middlefingerIcon.setPrerender(mfingerPrerender)

    middlefingerIcon.setClickEvent(() => {
      var decayIdxs = []
      middlefingerIcon.setPrerender(() => {
        decayIdxs = decay(middlefingerIcon, 20, 'rgb(0, 0, 0, 0)', decayIdxs, () => {
          middlefingerGif.show()
          middlefingerGif.play()
          middleFingerHidden = true
          middlefingerIcon.setPrerender(mfingerPrerender)
        })
      })
    })
    var middlefingerGif = new RenderGif(2)
    middlefingerGif.pushSheet(ascii.middlefinger, '`', "rgb(255, 203, 164)", [0, 0])
    middlefingerGif.setTruePosition([40, 0])
    if(onMobile)
      middlefingerGif.setTruePosition([40, -30])
    // only play the gif on at random times between 5 and 15 seconds
    middlefingerGif.goTo(0)
    middlefingerGif.pause()
    middlefingerGif.hide()
    middlefingerGif.setFrameBreakpoint(middlefingerGif.layers.length - 1, () => {
      middlefingerGif.hide()
      middlefingerGif.pause()
      middlefingerGif.goTo(0)
    })
    middlefingerGif.setPrerender(() => {
      if(middlefingerGif.updateNext()){
        middlefingerGif.next()
      }
    })

    var reticle = makeBlock(ascii.reticle, 'rgb(50, 50, 50)', -300, 0).centerX(cols)
    reticle.fullyClickable = true
    reticle.setTruePosition([reticle.truePos[0], reticle.truePos[1] - 5])
    reticle.setVelocity([0.75, 0])
    reticle.setPrerender(() => {
      bounceMoveLayer(reticle, [[-400, 400], [-400, 400]])
    })
    reticle.setClickEvent(() => {
      headGif.show()
      qs.play()
      qs.show()
      reticle.destroy()
    })

    const qs = new RenderGif(2)
    qs.pushSheet(ascii.quickscope, '`', 'rgb(0, 255, 0)', [0,0])
    qs.setTruePosition([rows - qs.layers[0].rows, heartGif.truePos[1] - 60])
    qs.pause()
    qs.hide()
    qs.prerender = () => {
      if(qs.updateNext()){
        qs.next()
      }
    }
    qs.setFrameBreakpoint(10, () => {
      // for(var i = 0; i < skulls.length; i++){
      //   explodeSkull(skulls[i], skullColors[i])
      // }
      // skulls = []
      // skullColors = []
      var decayIdxs = []
      heartGif.setComputedDiff((diffMap) => {
        decayIdxs = forceDecay(diffMap, heartGif, 30, "rgb(0, 0, 0, 0)", decayIdxs, () => {
          heartGif.hide()
          heartGif.setComputedDiff(undefined)
        })
      })
    })
    qs.setFrameBreakpoint(qs.layers.length - 1, () => {
      qs.pause()
      qs.goTo(0)
      qs.hide()
      qs.destroy()
    })

    var versionText = new RenderLayer(1, 7, 0)
    versionText.insertText(0, 0, "v"+VERSION_NUM, smileColors[1])
    versionText.setTruePosition([2,1])
    versionText.setNoShift(true)

    var mobileWarning1 = makeBlock(textToAscii("Functionality    is \n\nlimited on mobile", "Small"), 'rgb(255, 255, 255)', rows - 20, 0). centerX(cols)
    var mobileWarning2 = makeBlock(textToAscii("You have been \n\n          warned...", "Small"), smileColors[1], rows - 10, 0). centerX(cols)
    var noMobileText = makeBlock(textToAscii("     NO FUN \n\n   ON MOBILE \n\n\ \n\n         ...\n\n \n\n GET ON YOUR \n\n COMPUTER ;)", "4Max"), 'rgb(255, 255, 255)', 0, 0).centerX(cols).centerY(rows)
    noMobileText.hide()
    var comingSoonText = makeBlock(textToAscii(" MUSIC \n\n \n\nCOMING \n\n \n\nJUNE  8", '4Max'), 'rgb(255, 255, 255)', 0,0).centerY(rows).centerX(cols)
    comingSoonText.hide()

    var noMobileBorder = new RenderLayer(50, cols - 2 , 0)
    noMobileBorder.drawBorder(noMobileBorder.rows - 2, noMobileBorder.cols, 0, 'v', '^', '0', 'rgb(255, 255, 255)', 'rgb(20, 20, 20)')
    noMobileBorder.centerX(cols).centerY(rows)
    noMobileBorder.hide()

    var backArrow = makeBlock(textToAscii("<", '4Max'), linkColor, rows - 40, cols / 2 - 36)
    if(onMobile)
      backArrow.setTruePosition([(rows / 2) - 20, cols / 2 - 36])
    backArrow.hide()
    backArrow.fullyClickable = true
    backArrow.setHoverEvent(() => {
      backArrow.recolor([[linkColor, 'rgb(255, 255, 255)']])
    })
    backArrow.setUnhoverEvent(() => {
      backArrow.recolor([['rgb(255, 255, 255)', linkColor]])
    })
    backArrow.setClickEvent(() => {
      backArrow.hide()
      sublinks.forEach((family) => {
        family.forEach((link) => {
          link.hide()
        })
      })
      outermostLinks.forEach((link) => {
        link.show()
      })
      selectedBorder.hide()
      if(focusedLayer !== undefined)
        focusedLayer.setTruePosition(resetPosition)
      focusedLayer = undefined
    })

    function getRandomProject(){
      var projectArtists = []
      musicjson.artists.forEach((artist) => {
        if(artist.projects.length > 0)
          projectArtists.push(artist)
      })
      var artist = projectArtists[Math.round(Math.random() * (projectArtists.length - 1))]
      var project = artist.projects[Math.round(Math.random() * (artist.projects.length - 1))]
      return format(artist.name) + "/" + format(project.name)
    }

    var selectedBorder = new RenderLayer(12, 80, 0)
    selectedBorder.hide()

    var wwwLayer = makeBlock(ascii.www, getRandomColor(titleColors), 4, titleLayer.truePos[1] - 28) 
    var orgLayer = makeBlock(ascii.org, getRandomColor(titleColors), 12, titleLayer.truePos[1] + titleLayer.cols - 4)
    if(onMobile){
      wwwLayer.hide()
      orgLayer.hide()
    }

    // var altLogo1 = makeBlock(ascii.bmlogo, 'rgb(255, 255, 255)', 40, -1)
    var altLogo1 = new RenderLayer(rows, cols, 0)
    altLogo1.text = clone2dArray(baseLayer.text)
    altLogo1.fillColor(rows, cols, [0,0], 'rgb(20, 20, 20)')
    altLogo1.insertAscii(Math.round(rows / 8), Math.round((cols - 206) / 2) -10, ascii.bmlogo.split('\n'), ' ', 'rgb(255, 255, 255)')
    altLogo1.setClickEvent(() => {
      revealPage()
    })
    var pressKey = makeBlock(textToAscii("Press Any Key To Begin", "Mini"), 'rgb(255, 0, 0)', 3 * (rows / 4), 0).centerX(cols)
    pressKey.hide()
    pressKey.setClickEvent(() => {
      revealPage()
    })
    var pressKeyInterval = setInterval(() => {
      if(pressKey.hidden){
        pressKey.show()
      }
      else{
        pressKey.hide()
      }
    }, 1200)
    function revealPage(){
      setCookie("titleShown", true)
      clearInterval(pressKeyInterval)
      entered = true
      pressKey.destroy()
      var decayIdxs = []
      altLogo1.setPrerender(() => {
        decayIdxs = decay(altLogo1, 1000, 'rgb(0, 0, 0, 0)', decayIdxs, () => {
          altLogo1.destroy()
        })
      })
    }

    var heads = [ascii.noehead, ascii.shawnhead, ascii.zachhead]
    var headGif = new RenderGif(2)
    headGif.pushSheet(heads[Math.floor(Math.random() * 3)], '`', smileColors[1], [0,0])
    headGif.setTruePosition([rows - 30, heartGif.truePos[1] + 10])
    headGif.setPrerender(() => {
      if(headGif.updateNext()){
        headGif.next()
      }
    })
    headGif.hide()
    // 'rgb(100, 110, 160)'
    //'rgb(150, 112, 120)'
    //'rgb(40, 60, 180)'
    scatterText(writingjson.launch_poems[Math.round(Math.random() * 3)].split('\n'), conwaysColors[1], 20)
    scatterText(writingjson.from_the_mouth.split('\n'), conwaysColors[0], 50)
    scatterText(writingjson.rejoices.split('\n'), conwaysColors[2], 60)
    var skullCount = 0
    canvas.push(baseLayer)
    canvas.push(versionText)
    canvas.push(phoneGif)
    canvas.push(middlefingerIcon)
    initOrbitSkulls()
    if(!onMobile)
      canvas.push(headGif)
    canvas.push(heartGif)
    canvas.push(selectedBorder)
    var subLinkM1
    var subLinkM2
    var subLinkM3
    var subLinkF1
    var subLinkF2
    var subLinkF3
    var linkLayer1
    var linkLayer2
    var linkLayer3
    //'music/' + getRandomProject()
    if(!onMobile){
      subLinkM1 = createLink("ARTISTS", ["music"], rows - 26, (cols / 2) - 46).hide()
      subLinkM2 = createLink("LIVE", ["live/launch"], rows - 17, (cols / 2) + 4).hide()
      subLinkM3 = createLink("RANDOM", ['music/' + getRandomProject()], rows - 8, (cols / 2) - 47).hide()
      subLinkF1 = createLink("GREENERY", ["greenery"], rows - 26, (cols / 2) - 52).hide()
      subLinkF2 = createLink("SNAKE", [], rows - 17, (cols / 2)).hide()
      subLinkF3 = createLink("PORTRAITS", ["portraits"], rows - 8, (cols / 2) - 52).hide()
      linkLayer1 = createLink("MUSIC", [subLinkM1, subLinkM2, subLinkM3], rows - 26, (cols / 2) - 42)
      linkLayer2 = createLink("GALLERY", [], rows - 17, (cols / 2) - 8)
      linkLayer3 = createLink("FUN", [subLinkF1, subLinkF2, subLinkF3], rows - 8, (cols / 2) - 29)
    }
    else{
      subLinkM1 = createLink("ARTISTS", ["music"], (rows / 2) - 5, 5).hide()
      subLinkM2 = createLink("LIVE", ["live/launch"], (rows / 2) + 4, 5).hide()
      subLinkM3 = createLink("RANDOM", ['music/' + getRandomProject()], (rows / 2) + 13, 5).hide()
      subLinkF1 = createLink("GREENERY", [NO_MOBILE], (rows / 2) - 5, 5).hide()
      subLinkF2 = createLink("SNAKE", [], (rows / 2) + 4, 5).hide()
      subLinkF3 = createLink("PORTRAITS", [NO_MOBILE], (rows / 2) + 13, 5).hide()
      linkLayer1 = createLink("MUSIC", [subLinkM1, subLinkM2, subLinkM3], (rows / 2) - 5, 5)
      linkLayer2 = createLink("GALLERY", [], (rows / 2) + 4, 5)
      linkLayer3 = createLink("FUN", [subLinkF1, subLinkF2, subLinkF3], (rows / 2) + 13, 5)
    }
    var outermostLinks = [linkLayer1, linkLayer2, linkLayer3]
    var sublinks = [[subLinkF1, subLinkF2, subLinkF3],[subLinkM1, subLinkM2, subLinkM3]]
    canvas.push(backArrow)
    canvas.push(reticle)
    canvas.push(qs)
    if(onMobile){
      canvas.push(mobileWarning1)
      canvas.push(mobileWarning2)
    }
    canvas.push(skullMonologue1)
    canvas.push(skullMonologue2)
    canvas.push(skullMonologue3)
    canvas.push(skullMonologue4)
    canvas.push(skullMonologue5)
    canvas.push(skullMonologue6)
    canvas.push(middlefingerGif)
    canvas.push(titleLayer)
    canvas.push(wwwLayer)
    canvas.push(orgLayer)
    canvas.push(noMobileBorder)
    canvas.push(comingSoonText)
    if(!onMobile){
      if(cookies.titleShown === undefined || !cookies.titleShown){
        canvas.push(altLogo1)
        canvas.push(pressKey)
      }
    }
    else{
      canvas.push(noMobileText)
    }

    canvas.start()
    function applyKeyBindings(event){
      event.preventDefault()
      if(!entered){
        revealPage()
      }
    }
    document.removeEventListener("keydown", applyKeyBindings)
    document.addEventListener("keydown", applyKeyBindings)
    
    return () => {
      canvas.stop()
      document.removeEventListener("keydown", applyKeyBindings)
    }

  }, [size])

    return (
      <>
        {/* <Webcam
              className='camera'
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              disablePictureInPicture={true}
              imageSmoothing={true}
              screenshotQuality={0.8}
              muted={false}
              width={1280}
              height={720}
              mirrored={false}
          /> */}
          <header className="App-header">
              <canvas id="canvas"></canvas>
          </header>
      </>
    )
}

export default Home;