import { isBlankText, isTransparent, RenderLayer, RenderGif } from './Renderer.js'
const TRANSPARENT_COLOR = 'rgb(0, 0, 0, 0)'
// export function columnScan(rows, cols, cells, color){
//     if(cells === undefined){
//       cells = initCells(rows, cols, color)
//       for(var i = 0; i < cells.length; i++){
//         cells[i][0] = color;
//       }
//     }
//     else{
//       for(var i = 0; i < cells[0].length; i++){
//         if(cells[0][i] === color){
//           for(var j = 0; j < cells.length; j++){
//             if(i + 1 == cells[0].length){
//               cells = initCells(rows, cols, color)
//               for(var i = 0; i < cells.length; i++){
//                 cells[i][0] = color;
//               }
//               return cells;
//             }
//             cells[j][i] = 'rgb(0, 0, 0)';
//             const next = (i + 1) % cells[0].length
//             cells[j][next] = color
//           }
//           break
//         }
//       }
//     }
//     return cells;
//   }

export function randomNoise(rowRange, colRange, layer, color, noiseFactor, char){
  try{
    for(var i = rowRange[0]; i <= rowRange[1]; i++){
      for(var j = colRange[0]; j <= colRange[1]; j++){
        const r = Math.floor(Math.random() * noiseFactor);
        if(r == 0){
          layer.colors[i][j] = color
          if(char !== undefined){
            layer.text[i][j] = char
          }
        }
      }
    }
  }
  catch(e){
    console.log(e)
  }
}

export function randomNoiseMasked(rowRange, colRange, layer, color, noiseFactor){
  try{
    for(var i = rowRange[0]; i < rowRange[1]; i++){
      for(var j = colRange[0]; j < colRange[1]; j++){
        const r = Math.floor(Math.random() * noiseFactor);
        if(r == 0 && !isTransparent(layer.colors[i][j])){
          layer.colors[i][j] = color
        }
      }
    }
  }
  catch(e){
    console.log(e)
  }
}

export function decay(layer, batchSize, color, remainingIdx, callback){
  if(remainingIdx === null || remainingIdx === undefined){
    return
  }
  if(layer instanceof RenderGif){
    layer = layer.layers[layer.currentFrame]
  }
  if(remainingIdx.length === 0){
    for(var i = 0; i < layer.colors.length; i++){
      for(var j = 0; j < layer.colors[i].length; j++){
        if(!isTransparent(layer.colors[i][j]) && !isBlankText(layer.text[i][j])){
          remainingIdx.push([i, j])
        }
      }
    }
  }
  shuffleArray(remainingIdx)
  for(var i = 0; i < batchSize; i++){
    if(remainingIdx.length === 0){
      break
    }
    const idx = remainingIdx.pop()
    layer.text[idx[0]][idx[1]] = ""
    layer.colors[idx[0]][idx[1]] = color
  }
  if(remainingIdx.length === 0){
    callback()
    return null
  }
  return remainingIdx
}

export function forceDecay(diffMap, layer, batchSize, color, remainingIdx, callback){
  if(remainingIdx === null || remainingIdx === undefined){
    return
  }
  if(layer instanceof RenderGif){
    layer = layer.layers[layer.currentFrame]
  }
  if(remainingIdx.length === 0){
    for(var i = 0; i < layer.colors.length; i++){
      for(var j = 0; j < layer.colors[i].length; j++){
        if(!isTransparent(layer.colors[i][j]) && !isBlankText(layer.text[i][j])){
          remainingIdx.push([i, j])
        }
      }
    }
  }
  shuffleArray(remainingIdx)
  for(var i = 0; i < batchSize; i++){
    if(remainingIdx.length === 0){
      break
    }
    const idx = remainingIdx.pop()
    layer.text[idx[0]][idx[1]] = ""
    layer.colors[idx[0]][idx[1]] = color
    const key = (layer.offset[0] + idx[0])+","+ (layer.offset[1] + idx[1])
    diffMap.set(key, "`" + color)
  }
  if(remainingIdx.length === 0){
    callback()
    return null
  }
  return remainingIdx
}
  
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
}

export function antidecay(layer, batchSize, remainingIdx, initialColors, initialText, callback){
  if(remainingIdx === null || remainingIdx === undefined){
    return
  }
  if(layer instanceof RenderGif){
    layer = layer.layers[layer.currentFrame]
  }
  if(remainingIdx.length === 0){
    for(var i = 0; i < layer.colors.length; i++){
      for(var j = 0; j < layer.colors[i].length; j++){
        remainingIdx.push([i, j])
        layer.text[i][j] = ""
        layer.colors[i][j] = TRANSPARENT_COLOR
      }
    }
  }
  shuffleArray(remainingIdx)
  for(var i = 0; i < batchSize; i++){
    if(remainingIdx.length === 0){
      break
    }
    const idx = remainingIdx.pop()
    layer.text[idx[0]][idx[1]] = initialText[idx[0]][idx[1]]
    layer.colors[idx[0]][idx[1]] = initialColors[idx[0]][idx[1]]
  }
  if(remainingIdx.length === 0){
    callback()
    return null
  }
  return remainingIdx
}

export function forceAntidecay(diffMap, layer, batchSize, remainingIdx, initialColors, initialText, callback){
  if(remainingIdx === null || remainingIdx === undefined){
    return
  }
  if(layer instanceof RenderGif){
    layer = layer.layers[layer.currentFrame]
  }
  if(remainingIdx.length === 0){
    for(var i = 0; i < layer.colors.length; i++){
      for(var j = 0; j < layer.colors[i].length; j++){
        if(!isTransparent(layer.colors[i][j]) && !isBlankText(layer.text[i][j])){
          remainingIdx.push([i, j])
          // layer.text[i][j] = ""
          // layer.colors[i][j] = TRANSPARENT_COLOR
        }
      }
    }
  }
  shuffleArray(remainingIdx)
  for(var i = 0; i < batchSize; i++){
    if(remainingIdx.length === 0){
      break
    }
    const idx = remainingIdx.pop()
    layer.text[idx[0]][idx[1]] = initialText[idx[0]][idx[1]]
    layer.colors[idx[0]][idx[1]] = initialColors[idx[0]][idx[1]]
    diffMap.set((layer.offset[0] + idx[0]) + "," + (layer.offset[1] + idx[1]), initialText[idx[0]][idx[1]] + '`' +  initialColors[idx[0]][idx[1]])
  }
  if(remainingIdx.length === 0){
    callback() 
    return null
  }
  return remainingIdx
}

export function conways(diffMap, layer, color){
    var nextCells = []
    for (var i = 0; i < layer.rows; i++)
      nextCells[i] = layer.colors[i].slice()
    
    var aliveCells = 0
    for(var i = 0; i < layer.rows; i++){
      for(var j = 0; j < layer.cols; j++){
        var neighbors = 0;
        for(var n = -1; n <= 1; n++){
          for(var m = -1; m <= 1; m++){
            if((n == 0 && m == 0))
              continue
            var nPosX = (i + n) % layer.rows
            var nPosY = (j + m) % layer.cols
            if(nPosX < 0)
              nPosX += layer.rows
            if(nPosY < 0)
              nPosY += layer.cols
            if(layer.colors[nPosX][nPosY] === color)
              neighbors++
          }
        }
        if(layer.colors[i][j] === color){
          if(neighbors == 2 || neighbors == 3){
            //No diff
            nextCells[i][j] = color
            aliveCells++
          }
          else{
            nextCells[i][j] = TRANSPARENT_COLOR
            if(!(diffMap.has(i+","+j) && !isTransparent(diffMap.get(i+","+j).split("`")[1]))){
              diffMap.set(i+","+j, "`" + TRANSPARENT_COLOR)
            }
          }
        }
        else{
          if(neighbors == 3){
            nextCells[i][j] = color
            if(!(diffMap.has(i+","+j) && !isTransparent(diffMap.get(i+","+j).split("`")[1]))){
              diffMap.set(i+","+j, "`" + color)
           }
            aliveCells++
          }
          else {
            //No diff
            nextCells[i][j] = TRANSPARENT_COLOR
          }
        }
      }
    }
    layer.colors = nextCells;
}

export function applyPattern(layer, pattern, color, char1, char2){
  switch(pattern){
    case 'fill':
      layer.fillColor(layer.rows, layer.cols, [0,0], color)
      layer.fillText(layer.rows, layer.cols, [0,0], char1)
      break
    case 'noise':
      if(char1 === '`')
        char1 = " "
      if(char2 === '`')
        char2 = " " 
      randomNoise([0, layer.rows], [0, layer.cols], layer, color, 2, char1)
      randomNoise([0, layer.rows], [0, layer.cols], layer, color, 4, char2)
      break
    default:
      randomNoise([0, layer.rows], [0, layer.cols], layer, color, 2, ' ')
      randomNoise([0, layer.rows], [0, layer.cols], layer, color, 4, '0')
  }
}
