import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import pagejson from './ascii/ascii_greenery.json'
import writingjson from './writing.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import Webcam from 'react-webcam'
import {display} from '../scripts/WebcamManager.js'
import Color from 'color'
import ReactGA from "react-ga4";

let VERSION_NUM="1.0.0"

function Greenery() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send({hitType: "pageview", page: "/greenery", title: "Greenery"})
  }, [])

  const navigate = useNavigate();

  var fontsize = 12
  var colFactor = 206/1440
  var rowFactor = 99/779

  if(window.innerWidth < 550){
    fontsize = 7
    colFactor = 370/1440
    rowFactor = 168/779
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)

  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 12
            colFactor = 216/1440
            rowFactor = 99/779

            if(window.innerWidth < 550){
              fontsize = 7
              colFactor = 370/1440
              rowFactor = 168/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(255, 238, 207)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)", "rgb(230, 215, 255)", "rgb(238, 197, 132)", "rgb(176, 123, 172)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"
  var phoneColors = ["rgb(140, 140, 140)", smileColors[0], conwaysColors[2]]

  const webcamRef = useRef(null)
  const [usingWebcam, setUsingWebcam] = useState(false)

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col){
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    const offscreen = new OffscreenCanvas(cols,rows)
    const offCtx = offscreen.getContext('2d')
    offCtx.scale((147)/1280,(40)/720)

    var canvas = new RenderCanvas(rows, cols, fontsize, 32)
    canvas.noShift = true

    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], "rgb(20, 20, 240)")
    baseLayer.insertAscii(0, 0, pagejson.lily.split("\n"), '`', 'rgb(60, 140, 60)')
    baseLayer.fillColor(50, 189, [116, 104], 'rgb(60, 140, 60)')
    baseLayer.insertAscii(10, -60, pagejson.lilac.split("\n"), '`', 'rgb(230, 215, 255)')
    // baseLayer.insertAscii(30, 0, pagejson.gmist.split('\n'), '`', 'rgb(246, 244, 241)')

    // canvas.noShift = true

    var backArrow = makeBlock(textToAscii("<--", '4Max'), boneColor, 5, 5)
    backArrow.fullyClickable = true
    backArrow.setNoShift(true)
    backArrow.setHoverEvent(() => {
      backArrow.recolor([[boneColor, linkColor]])
    })
    backArrow.setUnhoverEvent(() => {
      backArrow.recolor([[linkColor, boneColor]])
    })
    backArrow.setClickEvent(() => {
        navigate("/")
    })

    var poemLayer1 = new RenderLayer(50, 80, 0)
    poemLayer1.insertTextBlockSkipRows(0, 0, 4, writingjson.hope_springs_not.split('\n'), 'rgb(255, 255, 255)')
    poemLayer1.setTruePosition([rows-34, 2])

    var conwaysLayer1 = new RenderLayer(rows, cols, 1)
    var conwaysLayer2 = new RenderLayer(rows, cols, 2)
    var conwaysLayer3 = new RenderLayer(rows, cols, 4)
    var conwaysLayer4 = new RenderLayer(rows, cols, 3000) 
    var conwaysLayer5 = new RenderLayer(rows, cols, 10)
    var conwaysLayer6 = new RenderLayer(rows, cols, 8)

    // randomNoise([0, conwaysLayer1.rows - 1], [0, conwaysLayer1.cols - 1], conwaysLayer1, conwaysColors[0], 4)
    randomNoise([0, conwaysLayer2.rows - 1], [0, conwaysLayer2.cols - 1], conwaysLayer2, conwaysColors[1], 2)
    randomNoise([0, conwaysLayer3.rows - 1], [0, conwaysLayer3.cols - 1], conwaysLayer3, conwaysColors[2], 2)
    randomNoise([0, conwaysLayer3.rows - 1], [0, conwaysLayer3.cols - 1], conwaysLayer4, conwaysColors[3], 6)
    // randomNoise([0, conwaysLayer3.rows - 1], [0, conwaysLayer3.cols - 1], conwaysLayer5, conwaysColors[4], 1)
    randomNoise([0, conwaysLayer3.rows - 1], [0, conwaysLayer3.cols - 1], conwaysLayer6, conwaysColors[5], 8)

    // conwaysLayer4.insertAscii(10, -60, pagejson.lilac.split("\n"), '`', conwaysColors[3])

    conwaysLayer1.setComputedDiff((diffMap) => {
        if(conwaysLayer1.updateNext())
          conways(diffMap, conwaysLayer1, conwaysColors[0])
      })
      conwaysLayer2.setComputedDiff((diffMap) => {
        if(conwaysLayer2.updateNext())
          conways(diffMap, conwaysLayer2, conwaysColors[1])
      })
    //   setTimeout(() => {
        conwaysLayer3.setComputedDiff((diffMap) => {
            if(conwaysLayer3.updateNext())
            conways(diffMap, conwaysLayer3, conwaysColors[2])
        })
    //   }, 100)
    // setTimeout(() => {
      conwaysLayer4.setComputedDiff((diffMap) => {
        if(conwaysLayer4.updateNext())
          conways(diffMap, conwaysLayer4, conwaysColors[3])
      })
    // }, 100000)
      conwaysLayer5.setComputedDiff((diffMap) => {
        if(conwaysLayer5.updateNext())
          conways(diffMap, conwaysLayer5, conwaysColors[4])
      })
      conwaysLayer6.setComputedDiff((diffMap) => {
        if(conwaysLayer6.updateNext())
          conways(diffMap, conwaysLayer6, conwaysColors[5])
      })

    canvas.push(baseLayer)
    canvas.push(conwaysLayer1)
    canvas.push(conwaysLayer2)
    canvas.push(conwaysLayer3)
    canvas.push(conwaysLayer4)
    canvas.push(conwaysLayer5)
    canvas.push(conwaysLayer6)
    canvas.push(backArrow)
    canvas.push(poemLayer1)

    canvas.start()
    return () => {
      canvas.stop()
    }
  }, [size])

    return (
        <header className="App-header">
            <canvas id="canvas"></canvas>
        </header>
    )
}

export default Greenery;