import { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./release_pages/Home"
import Music from "./release_pages/Music"
import Portraits from "./release_pages/Portraits"
import Greenery from "./release_pages/Greenery"
import Tracklist from "./release_pages/Tracklist"
import Flyer from './release_pages/Flyer'
import Mirage from './release_pages/Mirage'

import { changeCursor, resolveClick, shift, resolveHover } from './scripts/Renderer.js'
import musicjson from './release_pages/music.json'
import OutOfBounds from "./release_pages/OutOfBounds"

const throttle = require('lodash.throttle')
const scrollMultiplier = 3
const scrollWheelFactor = 1.8
const clickDragFactor = 0.3
const touchDragFactor = 0.6
var lastX = 0
var lastY = 0

export default function App() {
  
  useEffect(() => {
    var lastTouchX = -1
    var lastTouchY = -1

    function touchAndDrag(target, whileMove){
      var endMove = function () {
        window.removeEventListener('touchmove', whileMove)
        window.removeEventListener('touchend', endMove)
        lastTouchX = -1
        lastTouchY = -1
      }
      target.addEventListener('touchstart', function(event) {
        window.addEventListener('touchmove', whileMove)
        window.addEventListener('touchend', endMove)
      })
    }
    function clickAndDrag(target, whileMove) {
      var endMove = function () {
          window.removeEventListener('mousemove', whileMove);
          window.removeEventListener('mouseup', endMove);
      };
  
      target.addEventListener('mousedown', function (event) {
          window.addEventListener('mousemove', whileMove);
          window.addEventListener('mouseup', endMove);   
      });
    }
    clickAndDrag(document, (event) => {
      var x = Math.round(event.movementX * clickDragFactor)
      var y = Math.round(event.movementY * clickDragFactor)
      throttleShift(x, y)
    })
    touchAndDrag(document, (event) => {
      var touch = event.changedTouches.item(0)
      if(lastTouchX == -1 && lastTouchY == -1){
        lastTouchX = touch.screenX
        lastTouchY = touch.screenY
      }
      var dx = Math.round((touch.screenX - lastTouchX) * touchDragFactor)
      var dy = Math.round((touch.screenY - lastTouchY) * touchDragFactor)
      throttleShift(dx, dy)
      lastTouchX = touch.screenX
      lastTouchY = touch.screenY
    })
    let throttleClick = throttle((event) =>{
      changeCursor(document)
      resolveClick(event.clientX, event.clientY)
    }, 16)

    let throttleHover = throttle((event) => {
      resolveHover(event.clientX, event.clientY)
      lastX = event.clientX
      lastY = event.clientY
    }, 1)

    let throttleShift = throttle((x, y) => {
      shift(x, y)
    }, 1)

    document.addEventListener("click", (event) => {
      event.preventDefault()
      throttleClick(event)
    })
    document.addEventListener("mousemove", (event) => {
      event.preventDefault()
      throttleHover(event)
    })
    // document.addEventListener("keydown", (event) => {
    //   event.preventDefault()
    //   var x = 0
    //   var y = 0
    //   const key = event.code
    //   if(key === "KeyA" || key === "ArrowLeft"){
    //     x = 1
    //   }
    //   else if(key === "KeyD" || key === "ArrowRight"){
    //     x = -1
    //   }
    //   if(key === "ArrowUp"){
    //     y = 1
    //   }
    //   if(key === "ArrowDown"){
    //     y = -1
    //   }
    //   x *= scrollMultiplier
    //   y *= scrollMultiplier
    //   throttleShift(x, y)
    // })
    document.addEventListener("wheel", (event) => {
      const x = Math.round(event.deltaX)
      const y = Math.round(event.deltaY)
      if(y !== 0){
        const yShift = (y / Math.abs(y)) * scrollWheelFactor
        shift(0, yShift)
        resolveHover(lastX, lastY + yShift)
        lastY = lastY + yShift
      }
    })
    document.addEventListener("dblclick", (event) => {
      event.preventDefault()
    }, {passive: false})
  }, [])

  return (
    <div className="App" id ="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/music" element={<Music />} />
          {
            musicjson.artists.map((artist) => {
              return artist.projects.map((project) => {
                return <Route path={"/music/" + artist.name.toLowerCase().replaceAll(" ", "_") + "/" + project.name.toLowerCase().replaceAll(" ", "_")} element={<Tracklist artist={artist} project={project} />} />
              })
            })
          }
          {
            musicjson.artists.map((artist) => {
              return <Route path={"/music/" + artist.name.toLowerCase().replaceAll(" ", "_")} element={<Music artist={artist} />} />
            })
          }
          <Route path="/portraits" element={<Portraits />}/>
          <Route path="/greenery" element={<Greenery />}/>
          <Route path="/live/launch" element={<Flyer />}/>
          {/* <Route path="/mirage" element={<Mirage />}/> */}
          <Route path="/outofbounds" element={<OutOfBounds />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}