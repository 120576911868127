import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useFetcher, useNavigate } from 'react-router-dom';
import ascii from './ascii/ascii_home.json'
import musicjson from './music.json'
import pagejson from './ascii/ascii_outofbounds.json'
import writingjson from './writing.json'
import { writeGrid } from '../scripts/WordProcessor.js'
import { RenderLayer, RenderCanvas, loadImage, RenderGif, isTransparent, isBlankText, getLighterColorFromRGB, CombinedLayer, textToAscii, makeBlock, makeColorRectangle, centerX, centerY} from '../scripts/Renderer.js'
import { randomNoise, conways, decay, forceDecay } from '../scripts/RenderEffect.js'
import { bounceMoveLayer, circularMotion, moveLayer } from '../scripts/Physics.js'
import figlet, { text } from 'figlet';
import standard from "figlet/importable-fonts/Georgia11";
import { getSound, playSound, startAlbumPlayback, toggleAlbumLooping, format } from '../scripts/SoundManager.js'
import Webcam from 'react-webcam'
import {display} from '../scripts/WebcamManager.js'
import Color from 'color'
import ReactGA from "react-ga4";

let VERSION_NUM="1.0.0"

function OutOfBounds() {
  useEffect(() => {
    ReactGA.initialize("G-CY8MG8SWH2")
    ReactGA.send({hitType: "pageview", page: "/greenery", title: "Greenery"})
  }, [])

  const navigate = useNavigate();

  var fontsize = 12
  var colFactor = 206/1440
  var rowFactor = 99/779

  if(window.innerWidth < 550){
    fontsize = 7
    colFactor = 370/1440
    rowFactor = 168/779
  }
  var cols = Math.ceil(window.innerWidth * colFactor)
  var rows = Math.ceil(window.innerHeight * rowFactor)

  var [grid, setGrid] = useState(writeGrid(cols, rows, 0, [])[0])
  const [size, setSize] = useState([0, 0])

  function useWindowSize() {
      useLayoutEffect(() => {
        function updateSize() {
          try{
            setSize([window.innerWidth, window.innerHeight]);
            fontsize = 12
            colFactor = 216/1440
            rowFactor = 99/779

            if(window.innerWidth < 550){
              fontsize = 7
              colFactor = 370/1440
              rowFactor = 168/779
            }
            cols = Math.ceil(window.innerWidth * colFactor)
            rows = Math.ceil(window.innerHeight * rowFactor)
            setGrid(writeGrid(cols, rows, 0, [])[0])
          }
          catch(e){
            console.log(e)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
  }
  useWindowSize()

  const accentColors = ["rgb(24, 165, 26)"]
  const smileColors = ["rgb(255, 242, 0, 255)", "rgb(255, 0, 56, 255)"]
  const brainColor = "rgb(255, 153, 175)"
  const boneColor = "rgb(255, 238, 207)"
  const conwaysColors = ["rgb(24, 165, 26)", "rgb(0, 102, 58)", "rgb(119, 215, 112)", "rgb(230, 215, 255)", "rgb(238, 197, 132)", "rgb(176, 123, 172)"]
  var primaryTextColor = "rgb(0, 0, 0)"
  var primaryColors = [boneColor, smileColors[1], smileColors[0], conwaysColors[1], linkColor]
  var secondaryTextColor = accentColors[0]
  var linkColor = "rgb(0, 0, 255)"
  var phoneColors = ["rgb(140, 140, 140)", smileColors[0], conwaysColors[2]]

  useEffect(() => {

    figlet.parseFont("Standard", standard);

    var test = ''

    figlet.text(
      "hi my name\nis shawn",
      {
        font: "Standard",
      },
      function (err, data) {
        if (err) {
          console.dir(err);
          return;
        }
        var asciiString = '';
        data.split("\n").forEach((line) => {
          asciiString += line + "\\n"
        })
        test = asciiString
      }
    );

    function makeBlock(jsonName, color, row, col){
      var block = jsonName.split("\n")
      var layer = new RenderLayer(block.length, RenderLayer.getMaxLength(block), 0)
      layer.insertAscii(0,0, block, '`', color)
      layer.setTruePosition([row, col])
      return layer
    }

    var brainMoveToggled = false
    var skullMoveToggled = false
    var wordSpawning = false
    var heartGifDecaying = false
    var phoneShowing = false
    var skulls = []
    var skullColors = []
    function explodeSkull(skull, color){
      const explode = new RenderGif(0)
      explode.pushSheet(ascii.explodeSkull, '`', color, [skull.truePos[0], skull.truePos[1] - 2])
      explode.play()
      explode.setFrameBreakpoint(14, () => {
        explode.pause()
        var decayIdxs = []
        explode.setComputedDiff((diffMap) => {
          if(decayIdxs !== null){
            decayIdxs = forceDecay(diffMap, explode, 70, "rgb(0, 0, 0, 0)", decayIdxs, () => {
              decayIdxs = null
              explode.destroy()
              explode.setComputedDiff(() => {})
            })
          }
        })
      })
      explode.setPrerender(() => {
        if(explode.updateNext()){
          explode.next()
        }
      })
      canvas.push(explode)
      skull.destroy()
    }
    function spawnSkull(color){
        var negX = Math.random() >= 0.5 ? -1 : 1 
        var negY = Math.random() >= 0.5 ? -1 : 1 
        var velX = 6 * Math.random() + 6
        var velY = 6 * Math.random() + 6
        const skull = new RenderGif(0)
        skulls.push(skull)
        skullColors.push(boneColor)
        skull.name = "skull"
        if(negX < 0){
          skull.reverse()
        }
        skull.setVelocity([negX * velX, negY * velY])
        if(skullMoveToggled){
          skull.setVelocity([skull.velocity[0] / 5, skull.velocity[1] / 5])
          skull.setPrerender(() => {
            if(skull.updateNext()){
              skull.next()
              bounceMoveLayer(skull, [[0, rows], [0, cols]])
            }
          })
        }
        skull.pushSheet(ascii.skullFrames, '`', color, [5 + (Math.random() * (rows - 30)), (Math.random() * (cols - 40)) - 15])
        canvas.push(skull)
        skull.setClickEvent(() => {
            bounceMoveLayer(skull, [[0, rows], [0, cols]])
            skull.next()
            skull.next()
        })
        if(skulls.length > 5){
          skulls.shift().destroy()
          skullColors.shift()
        }
    }
    function cycleColor(colors, layer){
      const colorMap = []
      for(var i = 1; i < colors.length; i++){
        colorMap.push([colors[i - 1], colors[i]])
      }
      colorMap.push([colors[colors.length - 1], colors[0]])
      if(layer instanceof RenderGif)
        layer.recolor(colorMap)
      else
        layer.recolor(colorMap)
    }

    var canvas = new RenderCanvas(rows, cols, fontsize, 32, 'rgb(255, 255, 255)')
    canvas.noShift = true

    const titleColors = ['rgb(255, 255, 255)', 'rgb(0, 0, 255)', smileColors[0]]
    function getRandomColor(colors){
      return colors[Math.round(Math.random() * (colors.length - 1))]
    }

    // const bgColor = "rgb(255, 0, 0)"
    var baseLayer = new RenderLayer(rows, cols, 0);
    baseLayer.text = grid
    baseLayer.fillColor(rows, cols, [0,0], 'rgb(200, 200, 200)')
    baseLayer.fillColor(26, cols, [rows - 26, 0], 'rgb(155, 118, 83)')
    // baseLayer.fillColor(rows, cols, [0,0], bgColor)
    // baseLayer.insertAscii(0, 0, pagejson.desert.split("\n"), '`', 'rgb(230, 218, 176)')
    // baseLayer.fillColor(50, 189, [116, 104], 'rgb(60, 140, 60)')
    // baseLayer.insertAscii(10, -60, pagejson.lilac.split("\n"), '`', 'rgb(230, 215, 255)')
    // baseLayer.insertAscii(30, 0, pagejson.gmist.split('\n'), '`', 'rgb(246, 244, 241)')

    var organLayer = makeBlock(textToAscii("ORGANREGISTRY", "Crazy"), 'rgb(0, 155, 0)', 4, 0).centerX(cols)
    organLayer.setTruePosition([organLayer.truePos[0], organLayer.truePos[1] + 6])
    var wwwLayer = makeBlock(ascii.www, 'rgb(0, 155, 0)', 4, organLayer.truePos[1] - 28) 
    var orgLayer = makeBlock(ascii.org, 'rgb(0, 155, 0)', 12, organLayer.truePos[1] + organLayer.cols - 4)

    var trendLayer = makeBlock(textToAscii("TREND", "Doh"), 'rgb(0, 0, 255)', 20, 5).centerX(cols).centerY(rows)
    trendLayer.hide()
    var boundsLayer = makeBlock(textToAscii("OUT OF BOUNDS", "Roman"), 'rgb(255, 0, 0)', rows - 15, 5).centerX(cols)
    boundsLayer.hide()

    var showInterval
    const startShowInterval = () => {
        showInterval = setInterval(() => {
            if(!organLayer.hidden){
                organLayer.hide()
                wwwLayer.hide()
                orgLayer.hide()
                trendLayer.show()
            }
            else if(!trendLayer.hidden){
                trendLayer.hide()
                boundsLayer.show()
            }
            else{
                boundsLayer.hide()
                organLayer.show()
                wwwLayer.show()
                orgLayer.show()
            }
        }, 4000);
    }

    startShowInterval()

    var treeGif = new RenderGif(1)
    treeGif.pushSheet(pagejson.treespin, '`', 'rgb(20, 20, 20)', [0,0])
    treeGif.setTruePosition([rows - 99, 0])
    treeGif.setPrerender(() => {
        if(treeGif.updateNext()){
            treeGif.next()
        }
    })

    function createBounds(row){
        return makeBlock(textToAscii("OUT OF BOUNDS", "Roman"), 'rgb(255, 255, 255)', row, 0).centerX(cols).hide()
    }
    var bounds = []
    var numbounds = 0
    function cycleTextLayers(){
        if(numbounds === bounds.length){
            bounds.forEach((layer) => {
                layer.hide()
            })
            numbounds = 0
            return
        }
        bounds[numbounds].show()
        numbounds++
    }

    canvas.push(baseLayer)
    for(var i = 0; i < 8; i++){
        var layer = createBounds(5 + (i * 12))
        bounds.push(layer)
        canvas.push(layer)
    }
    canvas.push(treeGif)
    canvas.push(organLayer)
    canvas.push(orgLayer)
    canvas.push(wwwLayer)
    canvas.push(trendLayer)
    canvas.push(boundsLayer)
    
    var oldBinding, newBinding = ""
    var currentlyBinding = false
    var keyBindings = undefined
    function initKeyBindings(){
      oldBinding = ""
      newBinding = ""
      keyBindings = new Map()
      keyBindings.set("Backquote", [() => {
        canvas.renderBaseLayer()
      }])
      keyBindings.set("Equal", [() => {
        document.body.requestFullscreen()
      }])
      keyBindings.set("KeyQ", [() => {
        spawnSkull(boneColor)
      }])
      keyBindings.set("KeyZ", [() => {
        canvas.triggerClickByName("skull")
      }])
      keyBindings.set("KeyO", [() => {
        canvas.chromaticAberration.enabled = !canvas.chromaticAberration.enabled
      }])
      keyBindings.set("KeyL", [() => {
        canvas.setChromaticAberrationPhase((canvas.chromaticAberration.phase + 1) % 3)
      }])
      keyBindings.set("KeyA", [() => {
        skulls.forEach((skull) => {
          const oldColor = skullColors[skulls.indexOf(skull)]
          cycleColor(primaryColors, skull)
          skullColors[skulls.indexOf(skull)] = primaryColors[(primaryColors.indexOf(oldColor) + 1) % primaryColors.length]
          skull.next()
        })
      }])
      keyBindings.set("KeyW", [() => {
        if(skulls.length > 0){
          const color = skullColors[0]
          explodeSkull(skulls.shift(), color)
          skullColors.shift()
        }
      }])
      keyBindings.set("KeyS", [() => {
        if(!skullMoveToggled){
          skullMoveToggled = true
          for(var i = 0; i < skulls.length; i++){
            const skull = skulls[i]
            skull.setVelocity([skull.velocity[0] / 5, skull.velocity[1] / 5])
            skull.setPrerender(() => {
              if(skull.updateNext()){
                skull.next()
                bounceMoveLayer(skull, [[0, rows], [0, cols]])
              }
            })
          }
        }
        else{
          skullMoveToggled = false
          for(var i = 0; i < skulls.length; i++){
            const skull = skulls[i]
            skull.setPrerender(() => {})
            skull.setVelocity([skull.velocity[0] * 5, skull.velocity[1] * 5])
          }
        }
      }])
      keyBindings.set("KeyE", [() => {
          if(showInterval === undefined){
              startShowInterval()
              wwwLayer.show()
              orgLayer.show()
              organLayer.show()
          }
      }])
      keyBindings.set("KeyD", [() => {
          if(showInterval !== undefined){
              clearInterval(showInterval)
              showInterval = undefined
              wwwLayer.hide()
              orgLayer.hide()
              organLayer.hide()
              trendLayer.hide()
              boundsLayer.hide()
          }
      }])
      keyBindings.set("KeyC", [() => {
          cycleTextLayers()
      }])
    //   keyBindings.set("KeyE", [() => {
    //     cycleColor(brainColors, brainLayer)
    //   }])
    //   keyBindings.set("KeyD", [() => {
    //     brainLayer.insertAscii(0,0, brainBlock, ' ', brainColor)
    //     brainLayer.show()
    //   }])
    //   keyBindings.set("KeyC", [() => {
    //     if(!brainMoveToggled){
    //       brainLayer.setPrerender(() => {
    //         if(brainLayer.updateNext()){
    //           bounceMoveLayer(brainLayer, [[0, rows], [0, cols]])
    //           brainMoveToggled = true
    //         }
    //       })
    //     }
    //     else{
    //       brainLayer.setPrerender(() => {})
    //       brainMoveToggled = false
    //     }
    //   }])
    //   keyBindings.set("KeyR", [() => {
    //     qs.show()
    //     qs.goTo(0)
    //     qs.play()
    //   }])
    //   keyBindings.set("KeyT", [() => {
    //     if(!heartGifDecaying){
    //       heartGifDecaying = true
    //       ribsLayer.insertAscii(0,0, ribsBlock, ' ', boneColor)
    //       ribsLayer.show()
    //       var decayIdxs2 = []
    //       var initText2 = clone2dArray(ribsLayer.text)
    //       var initColors2 = clone2dArray(ribsLayer.colors)
    //       ribsLayer.setComputedDiff((diffMap) => {
    //         decayIdxs2 = forceAntidecay(diffMap, ribsLayer, 600, decayIdxs2, initColors2, initText2, () => {
    //           ribsLayer.setComputedDiff(undefined)
    //           heartGif.show()
    //           heartGif.layers = []
    //           heartGif.pushSheet(ascii.realheart, '`', smileColors[1], [0, 0])
    //           heartGif.setTruePosition([5, 20])
    //           var decayIdxs = []
    //           var initText = clone2dArray(heartGif.layers[0].text)
    //           var initColors = clone2dArray(heartGif.layers[0].colors)
    //           heartGif.setComputedDiff((diffMap) => {
    //             decayIdxs = forceAntidecay(diffMap, heartGif, 120, decayIdxs, initColors, initText, () => {
    //               heartGif.setComputedDiff(undefined)
    //               heartGifDecaying = false
    //             })
    //           })
    //         })
    //       })
    //     }
    //   }])
    //   keyBindings.set("KeyG", [() => {
    //     heartGif.play()
    //     heartGif.goTo(0)
    //   }])
    //   keyBindings.set("KeyY", [() => {
    //     spawnE()
    //   }])
    //   keyBindings.set("KeyH", [() => {
    //     despawnE()
    //   }])
    //   keyBindings.set("KeyN", [() => {
    //     if (wordSpawning){
    //       wordSpawning = false
    //       clearInterval(glitchy)
    //       letterEList.forEach((layer) => {
    //         layer.hide()
    //       })
    //     }
    //     else{
    //       wordSpawning = true
    //       spawnE()
    //       glitchE()
    //     }
    //   }])
    //   keyBindings.set("KeyU", [() => {
    //     if(!phoneShowing){
    //       phoneShowing = true
    //       phoneGif.show()
    //       phoneGif.goTo(0)
    //       phoneGif.play()
    //     }
    //     else{
    //       phoneShowing = false
    //       phoneGif.hide()
    //       phoneGif.pause()
    //       uziGif.hide()
    //       uziGif.pause()
    //       akGif.hide()
    //       akGif.pause()
    //     }
    //   }])
    //   keyBindings.set("KeyI", [() => {
    //     middlefingerGif.goTo(0)
    //     middlefingerGif.play()
    //     middlefingerGif.show()
    //   }])
      keyBindings.set("ArrowUp", [() => {
        canvas.setChromaticAberrationIntensity(Math.min(10000, canvas.chromaticAberration.intensity * 5))
      }])
      keyBindings.set("ArrowDown", [() => {
        canvas.setChromaticAberrationIntensity(Math.max(5, canvas.chromaticAberration.intensity / 5))
      }])
      keyBindings.set("Tab", [() => {
        oldBinding = ""
        newBinding = ""
        currentlyBinding = true
      }])
      keyBindings.set("Backspace", [() => {
        initKeyBindings()
      }])
      keyBindings.set("Enter", [() => {
        currentlyBinding = false
        if(oldBinding === "" || newBinding === ""){
          return
        }
        if(!keyBindings.has(oldBinding)){
          return
        }
        if(!keyBindings.has(newBinding)){
          keyBindings.set(newBinding, keyBindings.get(oldBinding))
          return
        }
        keyBindings.get(oldBinding).forEach((callback) => {
          if(keyBindings.get(newBinding).includes(callback)){
            return
          }
          keyBindings.get(newBinding).push(callback)
        })
      }])
    }
  
    initKeyBindings()
    function applyKeyBindings(event){
        event.preventDefault()
        const key = event.code
        if(key !== "Tab" && key !== "Enter"){
          if(currentlyBinding){
            if(oldBinding == "")
              oldBinding = key
            else{
              newBinding = key
            }
            return
          }
        }
        if(keyBindings.has(key)){
          keyBindings.get(key).forEach((callback) => {
            callback()
          })
        }
    }
    document.removeEventListener("keydown", applyKeyBindings)
    document.addEventListener("keydown", applyKeyBindings)

    canvas.start()
    return () => {
      canvas.stop()
      clearInterval(showInterval)
      document.removeEventListener("keydown", applyKeyBindings)
    }
  }, [size])

    return (
        <header className="App-header">
            <canvas id="canvas"></canvas>
        </header>
    )
}

export default OutOfBounds;