const wordsJson = require('../words.json')

const nounMap = new Map()
const prefixMap = new Map()
const suffixMap = new Map()
const words = [prefixMap, nounMap, suffixMap]

//[0] = min, [1] = max
const nounBounds = [-1,-1]
const prefixBounds = [-1,-1]
const suffixBounds = [-1,-1]
const wordBounds = [nounBounds, prefixBounds, suffixBounds]
var wordMaps = []

function initWordMaps(inserts){
  wordMaps = inserts
  for(var i = 0; i < words.length; i++){
    for(var j = 0; j < wordsJson.words[i].length; j++){
      const wordLength = wordsJson.words[i][j].length;
      if(wordBounds[i][0] == -1 || wordLength < wordBounds[i][0])
        wordBounds[i][0] = wordLength
      if(wordBounds[i][1] == -1 || wordLength > wordBounds[i][1])
        wordBounds[i][1] = wordLength
      if(!words[i].has(wordLength))
        words[i].set(wordLength, new Array())
      words[i].get(wordLength).push(wordsJson.words[i][j])
    }
  }
}
function writeLine(length, margin){
  var line = ""
  //TODO: Need algorithmic way of getting these values
  const prefixN = Math.floor(Math.random() * 3);
  const suffixN = Math.floor(Math.random() * 2);
  const nounN = length - (prefixN + suffixN)
  const typeN = [prefixN, nounN, suffixN]
  var tIndex = 0;
  while(line.length < length - margin){
    if(typeN[tIndex] == 0 && tIndex < 2){
      tIndex++;
    }
    typeN[tIndex]--
    var word = getRandomWord(length + margin - line.length, 
      wordBounds[tIndex][0], wordBounds[tIndex][1], words[tIndex])
    if(word == ""){
      const remChars = length + margin - line.length;
      for(var i = 0; i < remChars; i++){
        line += line.charAt(line.length - 1);
      }
      break
    }
    line += word
  }
  line = line.toUpperCase()
  for(var i = 0; i < line.length; i++){
    const randCond = Math.floor(Math.random() * 4);
    if(randCond != 0)
      continue;
    switch(line.charAt(i)){
      case 'I':
        line = replaceChar(line, i, '1')
        break;
      case 'E':
        line = replaceChar(line, i, '3')
        break;
      case 'S':
        line = replaceChar(line, i, '$')
        break;
    }
  }
  return line;
}
function replaceChar(str, index, rep){
  return str.substring(0, index) + rep + str.substring(index + rep.length)
}
function getRandomWord(maxLength, minWordSize, maxWordSize, map){
  if(maxLength < minWordSize)
    return ""
  maxLength = Math.min(maxLength, maxWordSize)
  const length = Math.floor(Math.random() * (maxLength - minWordSize + 1)) + minWordSize
  const index = Math.floor(Math.random() * map.get(length).length)
  return map.get(length)[index]
}

export function writeGrid(length, height, margin, inserts){
  initWordMaps(inserts)
  var grid = []
  for(var i = 0; i < height; i++){
    grid[i] = writeLine(length, margin).split('')
  }
  if(inserts.length > 0){
    var lastInd = 0;
    var linkPos = []
    for(var i = 0; i < wordMaps.length; i++){
      var insertInd = Math.floor(Math.random() * (height / wordMaps.length) + lastInd + 1)
      var colInd = Math.floor(Math.random() * (length - wordMaps[i].length))
      linkPos.push([insertInd, colInd, colInd + wordMaps[i].length])
      lastInd = insertInd;
      const wordChars = wordMaps[i].split('')
      for(var j = 0; j < wordChars.length; j++){
        grid[insertInd][j + colInd] = wordChars[j]
      }
    }
    var wMapIndex = 0
    var lastFlash = false

    for(var i = 0; i < height; i++){
      for(var j = 0; j < grid[i].length; j++){
        const isFlashing = ('' + grid[i][j]).charCodeAt(0) >= 97
        const cName = isFlashing ? 'linked' : ''
        const cHref = isFlashing ? '/' + wordMaps[wMapIndex] : '' 
        if(isFlashing)
          lastFlash = true
        if(lastFlash && !isFlashing){
          lastFlash = false
          wMapIndex++;
        }
        grid[i][j] = [('' + grid[i][j]).toUpperCase(), cName, cHref]
      }
    }
  }
  return [grid, linkPos]
}