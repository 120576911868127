import { Howl, Howler } from 'howler'
import musicjson from '../release_pages/music.json'

Howler.autoUnlock = false
export const soundMap = new Map()
var looping = false
var trackIndex = 0

export function format(name){
    return name.toLowerCase().replaceAll(" ", "_").replaceAll(".", "").replaceAll(",", "")
}

export function loadProject(artist, proj){
    var artistFormatted = format(artist.name)
    if(artist.projects.length === 0){
        return
    }
    if(artist.projects[0].link !== undefined){
        return
    }
    artist.projects.forEach((project) => {
        if(format(project.name) !== format(proj.name)){
            return
        }
        var projectFormatted = format(project.name)
        var howlArr = []
        soundMap.set('mus/' + artistFormatted + "/" + projectFormatted, howlArr)
        project.tracks.forEach((track) => {
            var trackFormatted = format(track)
            if(trackFormatted.includes("{}"))
                return
            howlArr.push(new Howl({
                html5: true,
                src: [process.env.REACT_APP_CDN_URL + "/artists/" + artistFormatted + "/" + projectFormatted + "/" + trackFormatted + ".ogg"]
            }))
        })
    })
}
export function getSound(name){
    return soundMap.get(name)
}

export function playSound(name){
    getSound(name).play()
} 

export function toggleAlbumLooping(){
    if(looping){
        looping = false
    }
    else
        looping = true
}
export function startAlbumPlayback(name, index, analyticsCallback){
    trackIndex = index
    soundMap.get(name)[index].play()
    analyticsCallback()
}

export function onEnd(name, index, callback){
    soundMap.get(name)[index].on('end', () => {
        callback()
    })
}

export function getTrackDuration(name, index){
    return getSound(name)[index].duration()
}

export function getTrackCurrentPosition(name){
    return getSound(name)[trackIndex].seek()
}

export function seekTrack(name, seek){
    getSound(name)[trackIndex].seek(seek)
}

export function pauseAlbumPlayback(name){
    getSound(name)[trackIndex].pause()
}

export function stopAlbumPlayback(name){
    getSound(name)[trackIndex].stop()
}

export function resumeAlbumPlayback(name){
    getSound(name)[trackIndex].play()
}