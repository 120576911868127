import Color from 'color'

export function getAsciiCharacter(brightness){
    // const asciiChars = 'MNNASomtwa*i=lln-,.___';
    // const asciiChars = '0896354271';
    // const asciiChars = '■◼◾*▬◥◤◣◢sex';
    // const asciiChars = '◾◖●◗';
    // const asciiChars = '◾●◼■+*';
    // const asciiChars = '◼●■■◾◾◾';
    // const asciiChars = '■';
    // const asciiChars = ' .,:;!i1tfLCG08@';
    // const asciiChars = '@%#*+=-:. ';
    const asciiChars = ' .,:;i1tfLCG08@';
    // .,:!*#$@npm start
    // const asciiChars = '▁▂▃▄▅▆▇███▊▋▌▍▎▏▟▙▜▛▞'
    // const asciiChars = '▁▂▃▄▅▆▇███▊▋▌▍▎▏▟▙▜▛▞▚▙▘▗▖▕▔▓▒░'

    // Scale the brightness to the range of asciiChars array indexes
    const scaledBrightness = Math.floor((brightness / 255) * (asciiChars.length - 1));
    if(scaledBrightness == 0)
      return ""
    // Get the corresponding ASCII character
    const asciiChar = asciiChars.charAt(scaledBrightness);
    
    return asciiChar;
}
function createRows(arr, length){
    const row = [];
    for (let i = 0; i < arr.length; i += length) {
        const chunk = arr.slice(i, i + length);
        row.push(chunk);
    }
    return row;
}
export function display(webcamRef, canvas, ctx, layer, withColor, colorCallback){
    const src = webcamRef.current.getScreenshot()
    // const track = webcamRef?.current?.video?.srcObject?.getVideoTracks()[0];
    // const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    // console.log(track.getCapabilities())
    // track?.applyConstraints({
    //   advanced: [{ brightness: "-50"}],
    // });
    var image = new Image();
    image.onload = function() {
        // const offCtx = canvas.getCanvasContext()//canvasCurr.getContext('2d');
        // Draw the current video frame on the canvas
        //offCtx.drawImage(image, 0, 0);
        ctx.drawImage(image, 0, 0)
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data
        // Create an array to store the ASCII characters
        const asciiArray = [];  
        const colorArray = []
        // Iterate over the pixel data and convert it to ASCII characters
        for (let i = 0; i < data.length; i += 4) {
        // Get the color channel values (red, green, blue)
        const red = Math.min(data[i], 255);
        const green = Math.min(data[i + 1], 255);
        const blue = Math.min(data[i + 2], 255);

        const color = "rgb(" + red + ", " + green + ", " + blue + ")"
        // const newColor = Color(color).saturate(1).lighten(0.2).rgb().string()
        var newColor = colorCallback(Color(color)).rgb().string()
      
        const brightness = (red + green + blue) / 3;
        // if(brightness < 10){
        //   colorArray.push("rgb(0, 0, 0, 0)")
        // }
        // else{
          colorArray.push(newColor)
        // }
    
        // Convert the average brightness to an ASCII character
        const asciiChar = getAsciiCharacter(brightness);
    
        // Store the ASCII character in the array
        asciiArray.push(asciiChar);
        //   var row = i % cols
        //   var col = i / cols
        //   canvas.drawText(canvas.getCanvasContext(), asciiChar, 'rgb(255, 0, 0)', col, row)
        }
        const text = createRows(asciiArray, imageData.width)
        const colors = createRows(colorArray, imageData.width)
        layer.text = text
        if(withColor)
            layer.colors = colors
    };
    image.src = src
}